import { Dispatch } from 'redux';
import { Cookies } from 'react-cookie';

import {
  loginFailureAction,
  loginStartedAction,
  loginSuccessAction,
  fetchUsersAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  inviteUserAction
} from '../../actions/userActions';
import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';

import { parseUser, parseUsers } from '../responseUtil/userResponseUtil';

import { axiosClient, COOKIE_USER, headers } from '../../constants/constants';
import {
  loginErrorTitle,
  loginErrorContent,
  fetchUsersErrorTitle,
  fetchUsersErrorContent,
  createUserErrorTitle,
  createUserErrorContent,
  updateUserErrorTitle,
  updateUserErrorContent,
  deleteUserErrorTitle,
  deleteUserErrorContent,
  inviteUserErrorTitle,
  inviteUserErrorContent,
  changePasswordUserErrorTitle,
  changePasswordUserErrorContent
} from '../../constants/errorMessages';
import {
  PATH_LOGIN,
  PATH_ALL_USERS,
  PATH_GET_USER,
  PATH_CREATE_USER,
  PATH_UPDATE_USER,
  PATH_DELETE_USER,
  PATH_USER_INVITE,
  PATH_USER_UPDATE_PASSWORD
} from '../../constants/network';
import {
  LOGIN,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  INVITE_USER,
  CHANGE_PASSWORD_USER
} from '../../constants/actionNames/user';

import { User } from '../../@types/Common.d';

export const getLogin = (email: string, password: string, cookies: Cookies) => async (
  dispatch: Dispatch
) => {
  dispatch(loginStartedAction(LOGIN));
  const res = await axiosClient
    .get(PATH_LOGIN, {
      params: { user: email, password },
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    const user = parseUser(res.data);
    cookies.set(COOKIE_USER, user);
    return dispatch(loginSuccessAction(LOGIN, user));
  }

  cookies.remove(COOKIE_USER);
  dispatch(
    requestFailure(LOGIN, {
      title: loginErrorTitle,
      content: loginErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
  return dispatch(loginFailureAction(res.response?.status ?? res.request?.status ?? 404));
};

export const sendUserInvite = (user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(INVITE_USER));

  const res = await axiosClient
    .put(
      PATH_USER_INVITE(user.id),
      {},
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(INVITE_USER));
    return dispatch(inviteUserAction('eyy'));
  }

  return dispatch(
    requestFailure(INVITE_USER, {
      title: inviteUserErrorTitle,
      content: inviteUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const sendChangePasswordUser = (user: User, password: string) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(CHANGE_PASSWORD_USER));

  const res = await axiosClient
    .put(
      PATH_USER_UPDATE_PASSWORD(user.id),
      { password },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CHANGE_PASSWORD_USER));
    return dispatch(inviteUserAction('eyy'));
  }

  return dispatch(
    requestFailure(CHANGE_PASSWORD_USER, {
      title: changePasswordUserErrorTitle,
      content: changePasswordUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getAllUsers = () => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_USERS));

  const res = await axiosClient
    .get(PATH_ALL_USERS, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_USERS));
    return dispatch(fetchUsersAction(parseUsers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_USERS, {
      title: fetchUsersErrorTitle,
      content: fetchUsersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getUser = (userId: number) =>
  axiosClient
    .get(PATH_GET_USER(userId), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .then(
      response => response,
      error => error
    )
    .catch(error => error);

export const createUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(CREATE_USER));

  const { id, email, prename, lastname, salutation, internal, admin } = user;

  const res = await axiosClient
    .post(
      PATH_CREATE_USER,
      {
        id,
        email,
        forename: prename,
        surname: lastname,
        salutation,
        internal,
        admin,
        password: '4d52f4f0c165092e618ec0659e004d6d'
      },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_USER));
    return dispatch(createUserAction(parseUser(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_USER, {
      title: createUserErrorTitle,
      content: createUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(UPDATE_USER));

  const { id, email, prename, lastname, salutation, internal, admin } = user;

  const res = await axiosClient
    .put(
      PATH_UPDATE_USER,
      { id, email, forename: prename, surname: lastname, salutation, internal, admin },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_USER));
    return dispatch(updateUserAction(parseUser(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_USER, {
      title: updateUserErrorTitle,
      content: updateUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(DELETE_USER));

  const res = await axiosClient
    .delete(PATH_DELETE_USER(user.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_USER));
    return dispatch(deleteUserAction(user));
  }

  return dispatch(
    requestFailure(DELETE_USER, {
      title: deleteUserErrorTitle,
      content: deleteUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
