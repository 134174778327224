import * as React from 'react';

import { Modal, Button, Form, Col, FormControl } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import md5 from 'md5';

import {
  BUTTON_ABORT,
  BUTTON_APPLY,
  CHANGE_PASSOWORD_MODAL_TITLE,
  CHANGE_PASSOWORD_MODAL_NEW_PASS_LABEL,
  CHANGE_PASSOWORD_MODAL_NEW_PASS_PLACEHOLDER,
  CHANGE_PASSOWORD_MODAL_NEW_PASS_INVALID
} from '../../constants/labels';
import { hideChangePasswordModal } from '../../actions/uiActions';
import { sendChangePasswordUser } from '../../util/api/userApi';

import { ChangePasswordModalState, ChangePasswordModalProps } from '../../@types/Modal.d';
import { GlobalState, User } from '../../@types/Common.d';
import { ChangePasswordModalAction } from '../../@types/Actions/UI.d';
import { ChangePasswordUserAction } from '../../@types/Actions/User.d';

class ChangePasswordModal extends React.Component<
  ChangePasswordModalProps,
  ChangePasswordModalState
> {
  constructor(props: ChangePasswordModalProps) {
    super(props);

    this.state = {
      newPassword: '',
      validated: false
    };

    this.onChangePassword = this.onChangePassword.bind(this);
    this.resetModal = this.resetModal.bind(this);

    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ newPassword: event.currentTarget.value ?? '' });
  }

  onHide(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { hideModal } = this.props;

    this.resetModal();
    hideModal('hide');
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { hideModal, user, userChangePassword } = this.props;
        const { newPassword } = this.state;

        userChangePassword(user, md5(newPassword));
        hideModal('hide');
      }
    });
  }

  resetModal() {
    this.setState({
      newPassword: '',
      validated: false
    });
  }

  render() {
    const { show } = this.props;
    const { newPassword, validated } = this.state;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>{CHANGE_PASSOWORD_MODAL_TITLE}</Modal.Header>
        <Modal.Body>
          <Form
            id="change-password-form"
            className="change-password-form"
            onSubmit={this.onSubmit}
            noValidate
            validated={validated}
          >
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>{CHANGE_PASSOWORD_MODAL_NEW_PASS_LABEL}</Form.Label>
                  <FormControl
                    as="input"
                    autoComplete="new-password"
                    type="password"
                    value={newPassword}
                    onChange={this.onChangePassword}
                    placeholder={CHANGE_PASSOWORD_MODAL_NEW_PASS_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {CHANGE_PASSOWORD_MODAL_NEW_PASS_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" form="change-password-form">
            {BUTTON_APPLY}
          </Button>
          <Button variant="danger" onClick={this.onHide}>
            {BUTTON_ABORT}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  show: state.ui.modals.changePassword.show,
  user: state.entities.users.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ChangePasswordModalAction | ChangePasswordUserAction>
) => ({
  hideModal: (payload: string) => dispatch(hideChangePasswordModal(payload)),
  userChangePassword: (user: User, newPassword: string) =>
    dispatch(sendChangePasswordUser(user, newPassword))
});

const ChangePasswordModalContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
);

export default ChangePasswordModalContainer;
