import {
  FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS,
  UPDATE_CLIENT_ADDITIONAL_OPTION
} from '../../constants/actionNames/clients/clientActions';

import { AdditionalOption } from '../../@types/Common.d';
import {
  FetchAllClientAdditionalOptionsAction,
  UpdateClientAdditionalOptionAction
} from '../../@types/Actions/Client/ClientAdditionalOptions.d';

export const fetchAllClientAdditionalOptions = (
  additionalOptions: Array<AdditionalOption>
): FetchAllClientAdditionalOptionsAction => ({
  type: FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS,
  additionalOptions
});

export const updateClientAdditionalOption = (
  additionalOption: AdditionalOption
): UpdateClientAdditionalOptionAction => ({
  type: UPDATE_CLIENT_ADDITIONAL_OPTION,
  additionalOption
});
