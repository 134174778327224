import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import LocalitiesList from './LocalitiesList';

import { BUTTON_CLOSE, LOCALITIES_MODAL_TITLE } from '../../../constants/labels';

import { LocalitiesModalProps } from '../../../@types/Modal.d';

const LocalitiesModal: React.FC<LocalitiesModalProps> = (props: LocalitiesModalProps) => {
  const { show, hideModal, area } = props;

  const onHide = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    hideModal(false, undefined);
  };

  return (
    <Modal show={show} onHide={() => hideModal('')} centered>
      <Modal.Header>{LOCALITIES_MODAL_TITLE(area)}</Modal.Header>
      <Modal.Body>
        <LocalitiesList localities={area.localities} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{BUTTON_CLOSE}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(LocalitiesModal);
