import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { useRouteMatch, NavLink } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROUTE_NEW } from '../../constants/constants';

import { AddListItemProps } from '../../@types/App.d';

const AddListItem: React.FC<AddListItemProps> = (props: AddListItemProps) => {
  const { clickAddItem, itemText } = props;

  const onClickAddItem = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    clickAddItem();
  };

  const { path } = useRouteMatch();

  return (
    <ListGroup.Item
      className="custom-add-button"
      onClick={onClickAddItem}
      as={NavLink}
      to={`${path}${ROUTE_NEW}`}
      isActive={() => false}
    >
      <Row>
        <Col xs={1}>
          <FontAwesomeIcon icon={faPlus} />
        </Col>
        <Col>{itemText}</Col>
      </Row>
    </ListGroup.Item>
  );
};

export default AddListItem;
