import '../../styles/Dashboard.scss';

import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DashboardButtonArea from './DashboardButtonArea';

import { DashboardProps, DashboardState } from '../../@types/Dashboad.d';
import { GlobalState } from '../../@types/Common.d';

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { availableFunctionality } = this.props;
    return (
      <div className="h-100 w-100">
        <DashboardButtonArea availableFunctionality={availableFunctionality} />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  availableFunctionality: state.ui.basic.availableFunctionality
});
const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, any>) => ({});

const DashboardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
export default DashboardContainer;
