// eslint-disable-next-line import/no-cycle
import { Moment } from 'moment';
// eslint-disable-next-line import/no-cycle
import { SALUTATION_MALE, SALUTATION_FEMALE } from '../constants/constants';

// eslint-disable-next-line import/no-cycle
import { UserAction } from './Actions/User.d';
// eslint-disable-next-line import/no-cycle
import { ClientAction } from './Actions/Client/Client.d';
// eslint-disable-next-line import/no-cycle
import { ProductAction } from './Actions/Product.d';
// eslint-disable-next-line import/no-cycle
import { ClientLocationsAction } from './Actions/Client/ClientLocation.d';
// eslint-disable-next-line import/no-cycle
import { UIAction } from './Actions/UI.d';

export interface GlobalState {
  entities: Entities;
  ui: UI;
}

export interface Entities {
  user: User | null;
  users: DictState<User>;
  clients: DictState<Client>;
  products: DictState<Product>;
  layers: DictState<Layer>;
}

export interface UI {
  basic: BasicUI;
  modals: Modals;
}

export interface BasicUI {
  loggedIn: boolean;
  loading: boolean;
  runningRequests: string[];
  availableFunctionality: AvailableFunctionality[];
}

export interface Modals {
  confirmation: ConfirmationModal;
  requestError: RequestErrorModal;
  clientLocation: ClientLocationModal;
  changePassword: ChangePasswordModal;
}

export interface RequestErrorModal {
  show: boolean;
  title?: string;
  content?: string;
}

export interface ConfirmationModal {
  show: boolean;
  title?: string;
  content?: string;
  confirmationCallback?: Function;
}

export interface ClientLocationModal {
  show: boolean;
  clientLocation?: ClientLocation;
}

export interface ChangePasswordModal {
  show: boolean;
}

export interface User extends DictObject {
  email: string;
  prename: string;
  lastname: string;
  internal: boolean;
  admin: boolean;
  salutation: Salutation;
}

export interface Client extends DictObject {
  name: string;
  emailContactPerson?: string;
  internalClient?: InternalClient;
  generateOffer?: boolean;
  transmissionType?: TransmissionType;
  clientLocations: DictState<ClientLocation>;
  users: DictState<User>;
  products: DictState<Product>;
  layers: DictState<Layer>;
  offerOrder: DictState<Offer> | DictState<Order>;
  modules: DictState<Module>;
  weekparts: Weekpart[];
  additionalOptions: DictState<AdditionalOption>;
  distributionAppointments: DictState<DistributionAppointment>;
  showPrice: boolean;
  planningRestriction?: PlanningRestriction;
  distributionDateType?: DistributionDateType;
  offerSheetTemplate?: OfferSheetTemplate;

  billingType: BillingType;
  billCity?: string;
  billHousenumber?: string;
  billName?: string;
  billPostcode?: string;
  billStreet?: string;
}

export interface DictState<T extends DictObject> {
  // TODO generalisieren
  selectedItem?: T;
  byId: Dict<T>;
  allIds: number[];
}

export interface DictObject {
  id: number;
  sortProperty: string;
}

export interface Dict<T extends DictObject> {
  [key: number]: T;
}

export interface Matcher {
  [key: string]: string;
}

export interface AvailableFunctionality {
  title: string;
  description: string;
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export interface ClientLocation extends DictObject {
  addressName: string;
  city: string;
  colorSelectedFill: string;
  distributionArea?: any; // TODO
  email?: string;
  housenumber: string;
  lat: string;
  lon: string;
  name: string;
  number: string;
  phone?: string;
  postcode: string;
  street: string;
  openingHours: OpeningHours;
  planable: boolean;
  poi: POI;
  billingDefault: boolean;
}

export interface POI {
  active?: string;
  inactive?: stirng;
  id?: number;
  name?: string;
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface AddressResult {
  coordinates: Coordiantes;
  name: string;
  selected: boolean;
  street: string;
  housenumber: string;
  city: string;
  postcode: string;
}

export interface Product extends DictObject {
  description: string;
  name: string;
  printDocRequired: boolean;
}

export interface Layer extends DictObject {
  countryCode: string;
  description: string;
  id: number;
  state: number;
  title: string;
  type: string;
}

export interface ListItem {
  id: number;
  title: string;
  subtitle?: string;
}

export interface LocalitySendFormat {
  id: number;
  localityKey: string;
  name: string;
  selected: boolean;
  circulation: number;
}

export interface AreaSendFormat {
  areaKey: string;
  name: stirng;
  localities: LocalitySendFormat[];
}

export interface DistributionTemplate {
  id: number;
  name: string;
  locations: DistributionTemplateLocation[];
}

export interface DistributionTemplateLocation {
  locationId?: number;
  name: string;
  areas: AreaSendFormat[];
}

export interface HistoryTemplate extends DictObject {
  id: number;
  actionName?: string;
  distributionDay: string;
  locations: DistributionTemplateLocation[];
  weekpart: string;

  copiesToSubsidiary?: number;
  city?: string;
  company?: string;
  email?: string;
  prename?: string;
  housenumber?: string;
  phone?: string;
  postcode?: string;
  salutation?: Salutation;
  street?: string;
  lastname?: string;
  product?: Product;
}

export interface Offer extends HistoryTemplate {}

export interface Order extends HistoryTemplate {}

export interface AdditionalOption extends DictObject {
  enabled: boolean;
  type: AdditionalOptionType;
  mode: AdditionalOptionMode;
  price: number;
}

export interface AdditionalOptionKaufDa extends AdditionalOption {
  defaultRange?: number;
  defaultDuration?: number;
  defaultHomepage?: string;
  presetSelectionRanges: number[];
  presetSelectionDurations: KaufDaDurationPrice[];
  presetSelectionHomepages: string[];
  presetValuesRanges: number[];
  presetValuesHomepages: string[];
}

export interface AdditionalOptionSocialMedia extends AdditionalOption {
  platform: SocialMedia;
  defaultRange?: number;
  defaultDuration?: number;
  defaultBudget?: number;
  defaultTargetGroups: SocialMediaTargetGroup[];
  presetSelectionRanges: number[];
  presetSelectionDurations: number[];
  presetSelectionBudgets: number[];
  presetSelectionTargetGroups: SocialMediaTargetGroup[];
  presetValuesRanges: number[];
  presetValuesDurations: number[];
  presetValuesBudgets: number[];
  presetValuesTargetGroups: SocialMediaTargetGroup[];
}

export interface KaufDaDurationPrice {
  id: number;
  duration: number;
  price: number;
}

export interface SocialMediaTargetGroup {
  id: number;
  name: string;
}

export interface DistributionAppointment extends DictObject {
  id: number;
  date: Moment;
  type: DateType;
  name: string;
}

export interface OfferSheetTemplate {
  id: number;
  name: string;
}

export interface ErrorMessage {
  title: string;
  content: string;
}

export interface ConfirmationMessage {
  title: string;
  content: string;
  confirmationCallback: Function;
}

export interface OpeningHours {
  [key: string]: OpeningHour;
}

export interface OpeningHour {
  id?: number;
  morningFrom?: string;
  morningTo?: string;
  noonFrom?: string;
  noonTo?: string;
  continuouslyOpen: boolean;
}

export interface OpeningHoursSend {
  id?: number;
  morningFrom?: string;
  morningTo?: string;
  noonFrom?: string;
  noonTo?: string;
  continuouslyOpen: boolean;
  day: string;
}

export interface Module extends DictObject {
  title: string;
  enabled: boolean;
}

export enum SocialMedia {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK'
}

export type AdditionalOptionType = 'SOCIALMEDIA' | 'KAUFDA';
export type Salutation = SALUTATION_MALE | SALUTATION_FEMALE;
export type TransmissionType = 'OFFER' | 'ORDER';
export type LoginCredentials = { email: string; password: string };
export type PlanningRestriction = 'NONE' | 'TEMPLATE';
export type DistributionDateType = 'FREE' | 'TEMPLATE';
export type DateType = 'DAY' | 'WEEK';
export type InternalClient = 'AGENTUR_FREY' | 'FREYPLUS_MEDIA';
export type Weekpart = 'MIDWEEK' | 'WEEKEND' | 'BEST';
export type AdditionalOptionMode = 'PRESET' | 'DEFAULT';
export type BillingType = 'CLIENT' | 'LOCATION' | 'PER_LOCATION';

export type Action = UserAction | ClientAction | ProductAction | ClientLocationsAction | UIAction;
