import { ACTION_STARTED, ACTION_SUCCESS, ACTION_FAILURE } from '../constants';

export const LOGIN = `LOGIN`;
export const LOGIN_STARTED = `LOGIN_${ACTION_STARTED}`;
export const LOGIN_SUCCESS = `LOGIN_${ACTION_SUCCESS}`;
export const LOGIN_FAILURE = `LOGIN_${ACTION_FAILURE}`;
export const LOGOUT = `LOGOUT`;
export const LOGOUT_STARTED = `LOGOUT_${ACTION_STARTED}`;
export const LOGOUT_SUCCESS = `LOGOUT_${ACTION_SUCCESS}`;
export const LOGOUT_FAILURE = `LOGOUT_${ACTION_FAILURE}`;

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SELECT_USER = 'SELECT_USER';
export const INVITE_USER = 'INVITE_USER';
export const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER';
