import * as React from 'react';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import AdditionalOptionItemKaufDaDefault from './KaufDa/AdditionalOptionItemKaufDaDefault';
import AdditionalOptionItemSocialMediaDefault from './SocialMedia/AdditionalOptionItemSocialMediaDefault';
import AdditionalOptionItemKaufDaPreset from './KaufDa/AdditionalOptionItemKaufDaPreset';
import AdditionalOptionItemSocialMediaPreset from './SocialMedia/AdditionalOptionItemSocialMediaPreset';
import AdditionalOptionItem from './AdditionalOptionItem';

import { updateClientAdditionalOption } from '../../../../util/api/clientsApi';

import {
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE,
  CLIENT_ADDITIONAL_OPTION_FACEBOOK_TITLE,
  CLIENT_ADDITIONAL_OPTION_INSTAGRAM_TITLE,
  CLIENT_ADDITIONAL_OPTION_TIKTOK_TITLE
} from '../../../../constants/labels';
import { DUMMY_TARGET_GROUP } from '../../../../constants/constants';

import {
  AdditionalOptionsListProps,
  AdditionalOptionsListState
} from '../../../../@types/Clients.d';
import {
  AdditionalOption,
  AdditionalOptionKaufDa,
  AdditionalOptionSocialMedia,
  Client,
  GlobalState,
  SocialMedia,
  SocialMediaTargetGroup
} from '../../../../@types/Common.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ClientAdditionalOptionsAction } from '../../../../@types/Actions/Client/ClientAdditionalOptions.d';

class AdditionalOptionList extends React.Component<
  AdditionalOptionsListProps,
  AdditionalOptionsListState
> {
  constructor(props: AdditionalOptionsListProps) {
    super(props);

    const { additionalOptions } = this.props;

    this.state = {
      kaufDaItem: additionalOptions.find(
        option => option.type === 'KAUFDA'
      ) as AdditionalOptionKaufDa,
      facebookItem: additionalOptions.find(
        option =>
          option.type === 'SOCIALMEDIA' &&
          (option as AdditionalOptionSocialMedia).platform === SocialMedia.FACEBOOK
      ) as AdditionalOptionSocialMedia,
      instagramItem: additionalOptions.find(
        option =>
          option.type === 'SOCIALMEDIA' &&
          (option as AdditionalOptionSocialMedia).platform === SocialMedia.INSTAGRAM
      ) as AdditionalOptionSocialMedia,
      tiktokItem: additionalOptions.find(
        option =>
          option.type === 'SOCIALMEDIA' &&
          (option as AdditionalOptionSocialMedia).platform === SocialMedia.TIKTOK
      ) as AdditionalOptionSocialMedia,
      avaiableTargetGroups: [] as SocialMediaTargetGroup[],
      kaufDaMaxHeight: 28 + 280,
      facebookMaxHeigth: 28 + 280,
      instagramMaxHeight: 28 + 280,
      tiktokMaxHeight: 28 + 280
    };

    this.setAdditionalOption = this.setAdditionalOption.bind(this);
    this.setAdditionalOptionsEnabled = this.setAdditionalOptionsEnabled.bind(this);
    this.getAvailableTargetGroups = this.getAvailableTargetGroups.bind(this);
    this.getKaufDaMaxHeight = this.getKaufDaMaxHeight.bind(this);
    this.getSocialMediaMaxHeight = this.getSocialMediaMaxHeight.bind(this);
    this.getSocialMediaDefaultMaxHeight = this.getSocialMediaDefaultMaxHeight.bind(this);
    this.getSocialMediaPresetMaxHeight = this.getSocialMediaPresetMaxHeight.bind(this);

    this.submitAddiitonalOption = this.submitAddiitonalOption.bind(this);
  }

  componentDidMount() {
    this.getAvailableTargetGroups();
  }

  componentDidUpdate(prevProps: AdditionalOptionsListProps) {
    const { additionalOptions } = this.props;

    if (JSON.stringify(additionalOptions) !== JSON.stringify(prevProps.additionalOptions))
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        kaufDaItem: additionalOptions.find(
          option => option.type === 'KAUFDA'
        ) as AdditionalOptionKaufDa,
        facebookItem: additionalOptions.find(
          option =>
            option.type === 'SOCIALMEDIA' &&
            (option as AdditionalOptionSocialMedia).platform === SocialMedia.FACEBOOK
        ) as AdditionalOptionSocialMedia,
        instagramItem: additionalOptions.find(
          option =>
            option.type === 'SOCIALMEDIA' &&
            (option as AdditionalOptionSocialMedia).platform === SocialMedia.INSTAGRAM
        ) as AdditionalOptionSocialMedia,
        tiktokItem: additionalOptions.find(
          option =>
            option.type === 'SOCIALMEDIA' &&
            (option as AdditionalOptionSocialMedia).platform === SocialMedia.TIKTOK
        ) as AdditionalOptionSocialMedia
      });
  }

  getAvailableTargetGroups() {
    this.setState({ avaiableTargetGroups: DUMMY_TARGET_GROUP });
  }

  getKaufDaMaxHeight() {
    let maxHeight = 28 + 280;
    const { kaufDaItem } = this.state;

    if (kaufDaItem) {
      const { presetValuesRanges, presetValuesHomepages, mode } = kaufDaItem;

      maxHeight =
        mode === 'PRESET'
          ? (presetValuesRanges.length + presetValuesHomepages.length) * 28 + 280
          : 28 + 280;
    }

    this.setState({
      kaufDaMaxHeight: maxHeight
    });
  }

  getSocialMediaMaxHeight(socialMediaOption: AdditionalOptionSocialMedia) {
    const { platform, mode } = socialMediaOption;

    if (platform === SocialMedia.FACEBOOK)
      this.setState({
        facebookMaxHeigth:
          mode === 'PRESET'
            ? this.getSocialMediaPresetMaxHeight(socialMediaOption)
            : this.getSocialMediaDefaultMaxHeight()
      });
    if (platform === SocialMedia.INSTAGRAM)
      this.setState({
        instagramMaxHeight:
          mode === 'PRESET'
            ? this.getSocialMediaPresetMaxHeight(socialMediaOption)
            : this.getSocialMediaDefaultMaxHeight()
      });
    if (platform === SocialMedia.TIKTOK)
      this.setState({
        tiktokMaxHeight:
          mode === 'PRESET'
            ? this.getSocialMediaPresetMaxHeight(socialMediaOption)
            : this.getSocialMediaDefaultMaxHeight()
      });
  }

  getSocialMediaPresetMaxHeight(socialMediaItem: AdditionalOptionSocialMedia) {
    const { avaiableTargetGroups } = this.state;
    const {
      presetValuesDurations,
      presetValuesRanges,
      presetValuesBudgets,
      presetSelectionTargetGroups
    } = socialMediaItem;

    return (
      (presetValuesDurations.length +
        avaiableTargetGroups.length +
        presetValuesRanges.length +
        presetValuesBudgets.length +
        presetSelectionTargetGroups.length) *
        28 +
      250
    );
  }

  getSocialMediaDefaultMaxHeight() {
    const { avaiableTargetGroups } = this.state;

    return avaiableTargetGroups.length * 28 + 280;
  }

  setAdditionalOption(additionalOption: AdditionalOption) {
    if (additionalOption.type === 'KAUFDA') {
      this.setState(
        { kaufDaItem: additionalOption as AdditionalOptionKaufDa },
        this.getKaufDaMaxHeight
      );
    } else {
      const cAdditionalOption = additionalOption as AdditionalOptionSocialMedia;

      if (cAdditionalOption.platform === SocialMedia.FACEBOOK)
        this.setState({ facebookItem: cAdditionalOption }, () =>
          this.getSocialMediaMaxHeight(cAdditionalOption)
        );
      if (cAdditionalOption.platform === SocialMedia.INSTAGRAM)
        this.setState({ instagramItem: cAdditionalOption }, () =>
          this.getSocialMediaMaxHeight(cAdditionalOption)
        );
      if (cAdditionalOption.platform === SocialMedia.TIKTOK)
        this.setState({ tiktokItem: cAdditionalOption }, () =>
          this.getSocialMediaMaxHeight(cAdditionalOption)
        );
    }
  }

  setAdditionalOptionsEnabled(additionalOption: AdditionalOption) {
    const { client, updateAdditionalOption } = this.props;

    if (!client) return;

    this.setAdditionalOption(additionalOption);
    updateAdditionalOption(client, additionalOption);
  }

  submitAddiitonalOption(additionalOption: AdditionalOption) {
    const { client, updateAdditionalOption } = this.props;

    if (!client) return;

    if ((additionalOption as AdditionalOptionKaufDa).presetSelectionDurations) {
      const durationPrices = (additionalOption as AdditionalOptionKaufDa).presetSelectionDurations.filter(
        (durationPrice, index, self) =>
          durationPrice.duration &&
          durationPrice.price &&
          index === self.findIndex(dp => dp.duration === durationPrice.duration)
      );

      (additionalOption as AdditionalOptionKaufDa).presetSelectionDurations = durationPrices;
    }

    updateAdditionalOption(client, additionalOption);
  }

  render() {
    const {
      avaiableTargetGroups,
      kaufDaItem,
      facebookItem,
      instagramItem,
      tiktokItem,
      kaufDaMaxHeight,
      facebookMaxHeigth,
      instagramMaxHeight,
      tiktokMaxHeight
    } = this.state;

    return (
      <div className="client-additional-options-list">
        {kaufDaItem && (
          <AdditionalOptionItem
            additionalOption={kaufDaItem}
            label={CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE}
            maxHeight={kaufDaMaxHeight}
            changeAdditionalOption={this.setAdditionalOption}
            changeAdditionalOptionEnabled={this.setAdditionalOptionsEnabled}
            submitAdditionalOption={this.submitAddiitonalOption}
            defaultValueComponent={
              <AdditionalOptionItemKaufDaDefault
                additionalOption={kaufDaItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
            presetComponent={
              <AdditionalOptionItemKaufDaPreset
                additionalOption={kaufDaItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
          />
        )}
        {facebookItem && (
          <AdditionalOptionItem
            additionalOption={facebookItem}
            label={CLIENT_ADDITIONAL_OPTION_FACEBOOK_TITLE}
            maxHeight={facebookMaxHeigth}
            changeAdditionalOption={this.setAdditionalOption}
            changeAdditionalOptionEnabled={this.setAdditionalOptionsEnabled}
            submitAdditionalOption={this.submitAddiitonalOption}
            defaultValueComponent={
              <AdditionalOptionItemSocialMediaDefault
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={facebookItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
            presetComponent={
              <AdditionalOptionItemSocialMediaPreset
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={facebookItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
          />
        )}
        {instagramItem && (
          <AdditionalOptionItem
            additionalOption={instagramItem}
            label={CLIENT_ADDITIONAL_OPTION_INSTAGRAM_TITLE}
            maxHeight={instagramMaxHeight}
            changeAdditionalOption={this.setAdditionalOption}
            changeAdditionalOptionEnabled={this.setAdditionalOptionsEnabled}
            submitAdditionalOption={this.submitAddiitonalOption}
            defaultValueComponent={
              <AdditionalOptionItemSocialMediaDefault
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={instagramItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
            presetComponent={
              <AdditionalOptionItemSocialMediaPreset
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={instagramItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
          />
        )}
        {tiktokItem && (
          <AdditionalOptionItem
            additionalOption={tiktokItem}
            label={CLIENT_ADDITIONAL_OPTION_TIKTOK_TITLE}
            maxHeight={tiktokMaxHeight}
            changeAdditionalOption={this.setAdditionalOption}
            changeAdditionalOptionEnabled={this.setAdditionalOptionsEnabled}
            submitAdditionalOption={this.submitAddiitonalOption}
            defaultValueComponent={
              <AdditionalOptionItemSocialMediaDefault
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={tiktokItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
            presetComponent={
              <AdditionalOptionItemSocialMediaPreset
                avaiableTargetGroups={avaiableTargetGroups}
                additionalOption={tiktokItem}
                changeAdditionalOption={this.setAdditionalOption}
              />
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ClientAdditionalOptionsAction>
) => ({
  updateAdditionalOption: (client: Client, additionalOption: AdditionalOption) =>
    dispatch(updateClientAdditionalOption(client, additionalOption))
});

const AdditionalOptionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalOptionList);

export default AdditionalOptionListContainer;
