import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import { SearchableListItemProps } from '../../../@types/App.d';

const SearchableListItem: React.FC<SearchableListItemProps> = (props: SearchableListItemProps) => {
  const { itemClick, item, selected } = props;

  const onClickItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (itemClick) itemClick(item.id);

    event.preventDefault();
  };

  return (
    <ListGroup.Item
      className={`custom-list-item  ${selected ? 'selected' : ''}`}
      onClick={onClickItem}
    >
      <Row className="no-gutters">
        <Col>{item.title}</Col>
      </Row>
      {item.subtitle && (
        <Row className="no-gutters text-eliptize-container">
          <Col className="text-eliptize">
            <small>{item.subtitle}</small>
          </Col>
        </Row>
      )}
    </ListGroup.Item>
  );
};

export default SearchableListItem;
