import * as React from 'react';

import ProductsDetailsContainer from './ProductsDetails/ProductsDetails';

import { PRODUCTS_NEW } from '../../constants/labels';

import { ProductsNewProps } from '../../@types/Products.d';

const ProductsNew: React.FC<ProductsNewProps> = () => {
  return (
    <div>
      <div className="title">{PRODUCTS_NEW}</div>
      <div className="tab-container">
        <ProductsDetailsContainer />
      </div>
    </div>
  );
};

export default ProductsNew;
