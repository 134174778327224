import { getDict, getIndicies, insertSorted } from '../../util/utils';

import initialState from '../../constants/initialState';
import {
  FETCH_LAYERS,
  CREATE_LAYER,
  UPDATE_LAYER,
  DELETE_LAYER,
  SELECT_LAYER
} from '../../constants/actionNames/layer';

import {
  FetchLayersAction,
  CreateLayerAction,
  UpdateLayerAction,
  DeleteLayerAction,
  SelectLayer,
  LayerAction
} from '../../@types/Actions/Layer.d';
import { Layer, DictState } from '../../@types/Common.d';

export default function layersReducer(state = initialState.entities.layers, action: LayerAction) {
  const { type } = action;

  if (type === FETCH_LAYERS) {
    const castedAction = action as FetchLayersAction;

    const byId = getDict<Layer>(castedAction.layers);
    const allIds = getIndicies<Layer>(byId);

    return { ...state, ...{ allIds, byId, selectedItem: byId[allIds[0]] } };
  }
  if (type === CREATE_LAYER) {
    const castedAction = action as CreateLayerAction;
    const { allIds } = state;

    const byId = {
      ...state.byId,
      ...{ [castedAction.layer.id]: castedAction.layer }
    };

    return {
      ...state,
      ...{
        allIds: insertSorted<Layer>(byId, [...allIds], castedAction.layer),
        byId,
        selectedItem: byId[castedAction.layer.id]
      }
    } as DictState<Layer>;
  }
  if (type === UPDATE_LAYER) {
    const castedAction = action as UpdateLayerAction;

    const byId = {
      ...state.byId,
      ...{ [castedAction.layer.id]: castedAction.layer }
    };
    const allIds = getIndicies<Layer>(byId);

    return {
      ...state,
      ...{ allIds, byId }
    } as DictState<Layer>;
  }
  if (type === DELETE_LAYER) {
    const castedAction = action as DeleteLayerAction;
    const { byId } = state;

    const selectedIndex = state.allIds.indexOf(castedAction.layer.id);

    if (selectedIndex < 0) return state;

    const allIds = [
      ...state.allIds.slice(0, selectedIndex),
      ...state.allIds.slice(selectedIndex + 1)
    ];

    return {
      ...state,
      ...{ allIds, selectedItem: byId[allIds[selectedIndex]] }
    } as DictState<Layer>;
  }
  if (type === SELECT_LAYER) {
    const castedAction = action as SelectLayer;
    const { byId } = state;

    return { ...state, ...{ selectedItem: byId[castedAction.layer?.id] } } as DictState<Layer>;
  }

  return state;
}
