import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import ClientDetailsOfferOrderDistributionListItem from './ClientDetailsOfferOrderDistributionListItem';

import { CLIENT_OFFER_ORDER_DISTRIBUTION_EMPTY_LIST } from '../../../../../constants/labels';

import { ClientDetailOfferOrderDistributionListProps } from '../../../../../@types/Clients.d';

const ClientDetailsOfferOrderDistributionList: React.FC<ClientDetailOfferOrderDistributionListProps> = (
  props: ClientDetailOfferOrderDistributionListProps
) => {
  const { locations, showLocalitiesModal } = props;

  return (
    <ListGroup className="distribution-list">
      {locations.length > 0 ? (
        locations.map(location => (
          <ClientDetailsOfferOrderDistributionListItem
            key={location.locationId}
            location={location}
            showLocalitiesModal={showLocalitiesModal}
          />
        ))
      ) : (
        <ListGroup.Item className="distribution-list-item">
          {CLIENT_OFFER_ORDER_DISTRIBUTION_EMPTY_LIST}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ClientDetailsOfferOrderDistributionList;
