import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchClientProductsAction,
  addClientProductAction,
  deleteClientProductAction
} from '../../actions/client/clientProductActions';

import { parseProducts } from '../responseUtil/productResponseUtil';

import {
  FETCH_CLIENT_PRODUCTS,
  ADD_CLIENT_PRODUCT,
  DELETE_CLIENT_PRODUCT
} from '../../constants/actionNames/clients/clientActions';
import { axiosClient, headers } from '../../constants/constants';
import {
  PATH_GET_CLIENT_PRODUCTS,
  PATH_ADD_CLIENT_PRODUCT,
  PATH_DELETE_CLIENT_PRODUCT
} from '../../constants/network';
import {
  fetchClientProductsErrorTitle,
  fetchClientProductsErrorContent,
  addClientProductErrorTitle,
  addClientProductErrorContent,
  deleteClientProductErrorTitle,
  deleteClientProductErrorContent
} from '../../constants/errorMessages';

import { Product, Client } from '../../@types/Common.d';

export const getClientProducts = (client: Client) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_CLIENT_PRODUCTS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_PRODUCTS(client.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_PRODUCTS));
    return dispatch(fetchClientProductsAction(parseProducts(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_PRODUCTS, {
      title: fetchClientProductsErrorTitle,
      content: fetchClientProductsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const addClientProduct = (client: Client, product: Product) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(ADD_CLIENT_PRODUCT));

  const res = await axiosClient
    .put(PATH_ADD_CLIENT_PRODUCT(client.id, product.id), {}, { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(ADD_CLIENT_PRODUCT));
    return dispatch(addClientProductAction(product));
  }

  return dispatch(
    requestFailure(ADD_CLIENT_PRODUCT, {
      title: addClientProductErrorTitle,
      content: addClientProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientProduct = (client: Client, product: Product) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(DELETE_CLIENT_PRODUCT));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT_PRODUCT(client.id, product.id), { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_PRODUCT));
    return dispatch(deleteClientProductAction(product));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_PRODUCT, {
      title: deleteClientProductErrorTitle,
      content: deleteClientProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
