import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import { fetchLayersAction } from '../../actions/layerActions';

import { parseLayers } from '../responseUtil/layerResponseUtil';

import { axiosClient, headers } from '../../constants/constants';
import { PATH_ALL_LAYERS, PATH_GET_LAYER } from '../../constants/network';
import { fetchLayersErrorTitle, fetchLayersErrorContent } from '../../constants/errorMessages';
import { FETCH_LAYERS } from '../../constants/actionNames/layer';

export const getAllLayers = () => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_LAYERS));

  const res = await axiosClient
    .get(PATH_ALL_LAYERS, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_LAYERS));
    return dispatch(fetchLayersAction(parseLayers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_LAYERS, {
      title: fetchLayersErrorTitle,
      content: fetchLayersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getLayer = (layerId: number) =>
  axiosClient
    .get(PATH_GET_LAYER(layerId), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .then(
      response => response,
      error => error
    )
    .catch(error => error);
