import axios from 'axios';
import { Theme } from 'react-select';
import config from '../config';

// eslint-disable-next-line import/no-cycle
import {
  TRANSMISSION_TYPE_OFFER_LABEL,
  TRANSMISSION_TYPE_ORDER_LABEL,
  CLIENTS_DETAILS_WEEKPART_WEEKEND_LABEL,
  CLIENTS_DETAILS_WEEKPART_MIDWEEK_LABEL,
  CLIENTS_DETAILS_WEEKPART_BEST_LABEL,
  PLANNING_RESTRICTION_NONE_LABEL,
  PLANNING_RESTRICTION_TEMPLATE_LABEL,
  INTERNAL_CLIENT_AGENCY_LABEL,
  INTERNAL_CLIENT_MEDIA_LABEL,
  CLIENTS_DETAILS_BILLING_TYPE_CLIENT,
  CLIENTS_DETAILS_BILLING_TYPE_LOCATION,
  CLIENTS_DETAILS_BILLING_TYPE_PER_LOCATION,
  DISTRIBUTION_DAY_TYPE_FREE_LABEL,
  DISTRIBUTION_DAY_TYPE_TEMPLATE_LABEL,
  DATE_TYPE_DAY_LABEL,
  DATE_TYPE_WEEK_LABEL
} from './labels';
// eslint-disable-next-line import/no-cycle
import { Matcher, Module, Product, SocialMediaTargetGroup } from '../@types/Common.d';

export const ACTION_STARTED = 'STARTED';
export const ACTION_SUCCESS = 'SUCCESS';
export const ACTION_FAILURE = 'FAILURE';

export const WEEKPART_MIDWEEK = 'MIDWEEK';
export const WEEKPART_WEEKEND = 'WEEKEND';
export const WEEKPART_BEST = 'BEST';
export const WEEKPART_ARRAY = [WEEKPART_MIDWEEK, WEEKPART_WEEKEND, WEEKPART_BEST];

export const COOKIE_USER = 'USER';

export const SALUTATION_MALE = 'Herr';
export const SALUTATION_FEMALE = 'Frau';

export const TRANSMISSIONTYPE_OFFER = 'OFFER';
export const TRANSMISSIONTYPE_ORDER = 'ORDER';

export const BILLING_TYPE_CLIENT = 'CLIENT';
export const BILLING_TYPE_LOCATION = 'LOCATION';
export const BILLING_TYPE_PER_LOCATION = 'PER_LOCATION';

export const PLANNING_RESTRICTION_NONE = 'NONE';
export const PLANNING_RESTRICTION_TEMPLATE = 'TEMPLATE';

export const DISTRIBUTION_DAY_TYPE_FREE = 'FREE';
export const DISTRIBUTION_DAY_TYPE_TEMPLATE = 'TEMPLATE';

export const DATE_TYPE_DAY = 'DAY';
export const DATE_TYPE_WEEK = 'WEEK';

export const INTERNAL_CLIENT_AGENCY = 'AGENTUR_FREY';
export const INTERNAL_CLIENT_MEDIA = 'FREYPLUS_MEDIA';

export const ROUTE_DASHBOARD = 'dashboard/';
export const ROUTE_PRODUCTS = 'products/';
export const ROUTE_LOGIN = 'login/';
export const ROUTE_CLIENTLOCATIONS = 'clientlocations/';
export const ROUTE_CLIENTS = 'clients/';
export const ROUTE_USERS = 'users/';

export const ROUTE_NEW = 'new';

export const ROUTE_LANDING = ROUTE_DASHBOARD;

export const CLIENTS_DETAILS_TAB_ID_CLIENT = 'client';
export const CLIENTS_DETAILS_TAB_ID_CLIENT_LOCATIONS = 'client_locations';
export const CLIENTS_DETAILS_TAB_ID_LAYERS = 'layers';
export const CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_TEMPLATES = 'distribution_areas';
export const CLIENTS_DETAILS_TAB_ID_USERS = 'users';
export const CLIENTS_DETAILS_TAB_ID_PRODUCTS = 'products';
export const CLIENTS_DETAILS_TAB_ID_OFFER_ORDER = 'offer_order';
export const CLIENTS_DETAILS_TAB_ID_ADDITIONAL_OPTIONS = 'additional_options';
export const CLIENTS_DETAILS_TAB_ID_MODULES = 'modules';
export const CLIENTS_DETAILS_TAB_ID_DISTRIBUTION_ACTIONS = 'distribution_actions';

export const CLIENTS_OFFER_ORDER_TAB_ID_DATA = 'data';
export const CLIENTS_OFFER_ORDER_TAB_ID_DISTRIBUTION = 'distribution';

export const CLIENT_LOCATIONS_TAB_ID_ADDRESS = 'address';
export const CLIENT_LOCATIONS_TAB_ID_COORD = 'coord';
export const CLIENT_LOCATIONS_TAB_ID_DATA = 'data';
export const CLIENT_LOCATIONS_TAB_ID_OPENING_HOURS = 'opening_hours';

export const PRODUCTS_DETAILS_TAB_ID_PRODUCT = 'product';
export const PRODUCTS_DETAILS_TAB_ID_PRODUCT_PRICING = 'product_pricing';

export const USERS_DETAILS_TAB_ID_USER = 'client';

export const LAYER_GERMANY_SHORT = 'DE';
export const LAYER_GERMANY_LONG = 'Deutschland';
export const LAYER_SWITZERLAND_SHORT = 'CH';
export const LAYER_SWITZERLAND_LONG = 'Schweiz';
export const LAYER_FRANCE_SHORT = 'FR';
export const LAYER_FRANCE_LONG = 'Frankreich';
export const LAYER_AUSTRIA_SHORT = 'AU';
export const LAYER_AUSTRIA_LONG = 'Österreich';

export const EXPORT_CLIENT_LOCATIONS_HEADER_ADDRESS_NAME = 'Adressname';
export const EXPORT_CLIENT_LOCATIONS_HEADER_NAME = 'Name';
export const EXPORT_CLIENT_LOCATIONS_HEADER_NUMBER = 'Betriebsnummer';
export const EXPORT_CLIENT_LOCATIONS_HEADER_POSTCODE = 'Postleitzahl';
export const EXPORT_CLIENT_LOCATIONS_HEADER_CITY = 'Stadt';
export const EXPORT_CLIENT_LOCATIONS_HEADER_STREET = 'Straße';
export const EXPORT_CLIENT_LOCATIONS_HEADER_HOUSENUMBER = 'Hausnummer';
export const EXPORT_CLIENT_LOCATIONS_HEADER_LAT = 'Latitude';
export const EXPORT_CLIENT_LOCATIONS_HEADER_LON = 'Longitude';
export const EXPORT_CLIENT_LOCATIONS_HEADER_COLOR = 'Filialfarbe';
export const EXPORT_CLIENT_LOCATIONS_HEADER_PLANABLE = 'Planbar';
export const EXPORT_CLIENT_LOCATIONS_HEADER_POI_ICON = 'Filialicon';

export const EXPORT_CLIENT_LOCATIONS_HEADER_ARRAY = [
  EXPORT_CLIENT_LOCATIONS_HEADER_ADDRESS_NAME,
  EXPORT_CLIENT_LOCATIONS_HEADER_NAME,
  EXPORT_CLIENT_LOCATIONS_HEADER_NUMBER,
  EXPORT_CLIENT_LOCATIONS_HEADER_POSTCODE,
  EXPORT_CLIENT_LOCATIONS_HEADER_CITY,
  EXPORT_CLIENT_LOCATIONS_HEADER_STREET,
  EXPORT_CLIENT_LOCATIONS_HEADER_HOUSENUMBER,
  EXPORT_CLIENT_LOCATIONS_HEADER_LAT,
  EXPORT_CLIENT_LOCATIONS_HEADER_LON,
  EXPORT_CLIENT_LOCATIONS_HEADER_COLOR,
  EXPORT_CLIENT_LOCATIONS_HEADER_PLANABLE,
  EXPORT_CLIENT_LOCATIONS_HEADER_POI_ICON
];
export const DATA_FORMAT_CSV = '.csv';

export const COUNTRY_CODE_MATCHER: Matcher = {};
COUNTRY_CODE_MATCHER[`${LAYER_GERMANY_SHORT}`] = `${LAYER_GERMANY_LONG}`;
COUNTRY_CODE_MATCHER[`${LAYER_SWITZERLAND_SHORT}`] = `${LAYER_SWITZERLAND_LONG}`;
COUNTRY_CODE_MATCHER[`${LAYER_FRANCE_SHORT}`] = `${LAYER_FRANCE_LONG}`;
COUNTRY_CODE_MATCHER[`${LAYER_AUSTRIA_SHORT}`] = `${LAYER_AUSTRIA_LONG}`;

export const WEEKPART_MATCHER: Matcher = {};
WEEKPART_MATCHER[`${WEEKPART_WEEKEND}`] = CLIENTS_DETAILS_WEEKPART_WEEKEND_LABEL;
WEEKPART_MATCHER[`${WEEKPART_MIDWEEK}`] = CLIENTS_DETAILS_WEEKPART_MIDWEEK_LABEL;
WEEKPART_MATCHER[`${WEEKPART_BEST}`] = CLIENTS_DETAILS_WEEKPART_BEST_LABEL;

export const TRANSMISSION_TYPE_ARRAY = [
  { value: TRANSMISSIONTYPE_OFFER, label: TRANSMISSION_TYPE_OFFER_LABEL },
  { value: TRANSMISSIONTYPE_ORDER, label: TRANSMISSION_TYPE_ORDER_LABEL }
];

export const PLANNING_RESTRICTION_ARRAY = [
  { value: PLANNING_RESTRICTION_NONE, label: PLANNING_RESTRICTION_NONE_LABEL },
  { value: PLANNING_RESTRICTION_TEMPLATE, label: PLANNING_RESTRICTION_TEMPLATE_LABEL }
];

export const DISTRIBUTION_DAY_TYPE_ARRAY = [
  { value: DISTRIBUTION_DAY_TYPE_FREE, label: DISTRIBUTION_DAY_TYPE_FREE_LABEL },
  { value: DISTRIBUTION_DAY_TYPE_TEMPLATE, label: DISTRIBUTION_DAY_TYPE_TEMPLATE_LABEL }
];

export const DATE_TYPE_ARRAY = [
  { value: DATE_TYPE_DAY, label: DATE_TYPE_DAY_LABEL },
  { value: DATE_TYPE_WEEK, label: DATE_TYPE_WEEK_LABEL }
];

export const INTERNAL_CLIENT_ARRAY = [
  { value: INTERNAL_CLIENT_AGENCY, label: INTERNAL_CLIENT_AGENCY_LABEL },
  { value: INTERNAL_CLIENT_MEDIA, label: INTERNAL_CLIENT_MEDIA_LABEL }
];

export const WEEKPART_SELECTION_ARRAY = [
  { value: WEEKPART_MIDWEEK, label: CLIENTS_DETAILS_WEEKPART_MIDWEEK_LABEL },
  { value: WEEKPART_WEEKEND, label: CLIENTS_DETAILS_WEEKPART_WEEKEND_LABEL },
  { value: WEEKPART_BEST, label: CLIENTS_DETAILS_WEEKPART_BEST_LABEL }
];

export const SALUTATION_ARRAY = [
  { value: SALUTATION_MALE, label: SALUTATION_MALE },
  { value: SALUTATION_FEMALE, label: SALUTATION_FEMALE }
];

export const BILLING_TYPE_ARRAY = [
  { value: BILLING_TYPE_CLIENT, label: CLIENTS_DETAILS_BILLING_TYPE_CLIENT },
  { value: BILLING_TYPE_LOCATION, label: CLIENTS_DETAILS_BILLING_TYPE_LOCATION },
  { value: BILLING_TYPE_PER_LOCATION, label: CLIENTS_DETAILS_BILLING_TYPE_PER_LOCATION }
];

export const DEFAULT_PRODUCT_DISTRIBUTION = 'Verteilung';
export const DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION = 'Druck & Verteilung';
export const DEFAULT_PRODUCTS = [
  {
    name: DEFAULT_PRODUCT_DISTRIBUTION,
    printDocRequired: false,
    id: -1
  } as Product,
  {
    name: DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION,
    printDocRequired: true,
    id: -2
  } as Product
];

export const COPIES_TO_SUBSIDIARY_ARRAY = [0, 100, 250];

export const selectPickerTheme = (theme: Theme) => {
  return {
    borderRadius: theme.borderRadius,
    colors: {
      ...theme.colors,
      // danger: '#DE350B',
      // dangerLight: '#FFBDAD',
      neutral0: 'rgb(252, 252, 252)', // bg
      neutral5: '#e9ecef', // bg diabled
      neutral10: 'rgb(211, 211, 211)', // border disabled
      // neutral20: 'rgb(0, 0, 0)', // border
      neutral30: 'rgb(63, 175, 212)', // hover
      // neutral40: 'rgb(83, 164, 198)', // icon hover
      neutral50: 'rgb(116, 116, 116)', // hint color
      neutral60: 'rgb(116, 116, 116)', // icon not focused
      neutral70: 'rgb(116, 116, 116)',
      neutral80: 'rgb(91, 91, 91)', // drop icon press & text
      neutral90: 'rgb(116, 116, 116)',
      primary: 'rgb(63, 175, 212)', // border focus,
      primary25: 'rgb(63, 175, 212)', // item hover
      primary50: 'rgb(83, 164, 198)', // item press
      // primary75: 'pink',
      neutral40: 'rgb(255, 255, 255)' // item press
    },
    spacing: theme.spacing
  } as Theme;
};

export const headers = {
  'X-API-Key-Token': process.env.REACT_APP_FRA_API_KEY,
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};

export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json'
});

export const COUNTRY_CODE_DE = 'DE';
export const COUNTRY_CODE_FR = 'FR';
export const COUNTRY_CODE_CH = 'CH';

export const DUMMY_TARGET_GROUP = [
  { id: 1, name: 'Männlich' },
  { id: 2, name: 'Weiblich' },
  { id: 3, name: 'Kinder' },
  { id: 4, name: 'Jugendliche' },
  { id: 5, name: 'Erwachsene U30' },
  { id: 6, name: 'Erwachsene U50' },
  { id: 7, name: 'Erwachsene Ü50' },
  { id: 8, name: 'Autos' },
  { id: 9, name: 'Heimwerker' },
  { id: 10, name: 'Mode' }
] as SocialMediaTargetGroup[];

export const DUMMY_OFFER_SHEET_TEMPLATES = [
  { id: 1, name: 'Jürgen Autos' },
  { id: 2, name: 'Fu Werkstätten' },
  { id: 3, name: 'Dieter Bauhaus' }
];

export enum Weekday {
  MONDAY = 'Montag',
  TUESDAY = 'Dienstag',
  WEDNESDAY = 'Mittwoch',
  THURSDAY = 'Donnerstag',
  FRIDAY = 'Freitag',
  SATURDAY = 'Samstag',
  SUNDAY = 'Sonntag'
}

export const HOURS = [
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22'
];

export const MINUTES = ['00', '15', '30', '45'];

export const DUMMY_MODULES = [
  { id: 1, title: 'Verteilung', enabled: true },
  { id: 2, title: 'Layoutdesign', enabled: true },
  { id: 3, title: 'Dialogmarketing', enabled: false },
  { id: 4, title: 'Plakatwerbung', enabled: false }
] as Module[];
