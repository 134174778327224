import 'react-dates/lib/css/_datepicker.css';
import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import moment, { Moment } from 'moment';

import ClientDetailsOfferOrderDetailsForm from './ClientDetailsOfferOrderDetailsForm';

import {
  requestStarted,
  requestSuccess,
  requestFailure,
  showConfirmationModal
} from '../../../../../actions/uiActions';

import { BUTTON_APPLY } from '../../../../../constants/labels';
import {
  WEEKPART_MIDWEEK,
  WEEKPART_WEEKEND,
  TRANSMISSIONTYPE_OFFER
} from '../../../../../constants/constants';
import { FETCH_CLIENT_OFFER_ORDER } from '../../../../../constants/actionNames/clients/clientOfferOrderActions';
import {
  fetchClientOfferOrderErrorTitle,
  fetchAllClientOfferOrderErrorContent
} from '../../../../../constants/errorMessages';

import { getOfferOrderItemData } from '../../../../../util/api/clientsApi';
import {
  extractOfferTemplate,
  extractOrderTemplate
} from '../../../../../util/responseUtil/clientResponseUtil';

import {
  ConfirmationMessage,
  GlobalState,
  ErrorMessage,
  Product,
  Salutation
} from '../../../../../@types/Common.d';
import { UIAction } from '../../../../../@types/Actions/UI.d';
import { ClientOfferOrderAction } from '../../../../../@types/Actions/Client/ClientOfferOrder.d';
import {
  ClientOfferOrderDetailsProps,
  ClientOfferOrderDetailsState
} from '../../../../../@types/Clients.d';

class ClientDetailsOfferOrderDetails extends React.Component<
  ClientOfferOrderDetailsProps,
  ClientOfferOrderDetailsState
> {
  constructor(props: ClientOfferOrderDetailsProps) {
    super(props);

    const { offerOrder } = this.props;

    this.state = {
      validated: false,

      actionName: offerOrder?.actionName ?? '',
      distributionDay: moment(offerOrder?.distributionDay, 'DD.MM.YYYY') ?? null,
      weekpart: offerOrder?.weekpart ?? WEEKPART_WEEKEND,
      datePickerFocused: false,
      copiesToSubsidiary: offerOrder?.copiesToSubsidiary ?? 0,
      city: offerOrder?.city ?? '',
      company: offerOrder?.company ?? '',
      email: offerOrder?.email ?? '',
      prename: offerOrder?.prename ?? '',
      housenumber: offerOrder?.housenumber ?? '',
      phone: offerOrder?.phone ?? '',
      postcode: offerOrder?.postcode ?? '',
      salutation: offerOrder?.salutation ?? '',
      street: offerOrder?.street ?? '',
      lastname: offerOrder?.lastname ?? '',
      product: offerOrder?.product ?? ({} as Product)
    };

    this.onChangeActionName = this.onChangeActionName.bind(this);
    this.onChangeDistributionDay = this.onChangeDistributionDay.bind(this);
    this.onChangeWeekpart = this.onChangeWeekpart.bind(this);
    this.onChangeCopiesToSubsidiary = this.onChangeCopiesToSubsidiary.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePrename = this.onChangePrename.bind(this);
    this.onChangeHousenumber = this.onChangeHousenumber.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeProduct = this.onChangeProduct.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onDatePickerFocusChange = this.onDatePickerFocusChange.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);

    this.resetForm = this.resetForm.bind(this);
    this.fetchOfferOrder = this.fetchOfferOrder.bind(this);
  }

  componentDidMount() {
    const { offerOrder } = this.props;

    if (offerOrder) this.fetchOfferOrder(offerOrder.id);
  }

  componentDidUpdate(prevProps: ClientOfferOrderDetailsProps) {
    const { offerOrder, client } = this.props;

    if (prevProps.client?.id !== client?.id || prevProps.offerOrder?.id !== offerOrder?.id) {
      this.resetForm();

      if (offerOrder) this.fetchOfferOrder(offerOrder.id);
    }
  }

  onChangeActionName(actionName: string) {
    this.setState({ actionName });
  }

  onChangeDistributionDay(distributionDay: Moment) {
    this.setState({ distributionDay });
  }

  onChangeWeekpart(weekpart: string) {
    this.setState({ weekpart });
  }

  onChangeCopiesToSubsidiary(copiesToSubsidiary: number) {
    this.setState({ copiesToSubsidiary });
  }

  onChangeCity(city: string) {
    this.setState({ city });
  }

  onChangeCompany(company: string) {
    this.setState({ company });
  }

  onChangeEmail(email: string) {
    this.setState({ email });
  }

  onChangePrename(prename: string) {
    this.setState({ prename });
  }

  onChangeHousenumber(housenumber: string) {
    this.setState({ housenumber });
  }

  onChangePhone(phone: string) {
    this.setState({ phone });
  }

  onChangePostcode(postcode: string) {
    this.setState({ postcode });
  }

  onChangeProduct(product: Product) {
    this.setState({ product });
  }

  onChangeSalutation(salutation: Salutation) {
    this.setState({ salutation });
  }

  onChangeStreet(street: string) {
    this.setState({ street });
  }

  onChangeLastname(lastname: string) {
    this.setState({ lastname });
  }

  onDatePickerFocusChange(focused: boolean) {
    this.setState({ datePickerFocused: focused });
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        // TODO update
      }
    });
  }

  isDayBlocked(day: Moment) {
    // TODO weekpart best
    const { offerOrder } = this.props;
    if (offerOrder)
      return (
        day.isBefore(moment().add(9, 'd')) ||
        (day.isoWeekday() !== 3 && offerOrder.weekpart === WEEKPART_MIDWEEK) ||
        (day.isoWeekday() !== 6 && offerOrder.weekpart === WEEKPART_WEEKEND)
      );
    return true;
  }

  resetForm() {
    this.setState({
      validated: false,

      actionName: '',
      distributionDay: null,
      weekpart: WEEKPART_WEEKEND,
      datePickerFocused: false,
      copiesToSubsidiary: 0,
      city: '',
      company: '',
      email: '',
      prename: '',
      housenumber: '',
      phone: '',
      postcode: '',
      salutation: '',
      street: '',
      lastname: ''
    });
  }

  async fetchOfferOrder(offerOrderId: number) {
    const { reqStarted, reqSuccess, reqFailure, client } = this.props;
    if (!offerOrderId || !client) return;

    const isOffer = client?.transmissionType === TRANSMISSIONTYPE_OFFER;

    reqStarted(FETCH_CLIENT_OFFER_ORDER);
    const res = await getOfferOrderItemData(client, isOffer, offerOrderId);

    if (res.status < 300) {
      let offerOrder;

      if (isOffer) offerOrder = extractOfferTemplate(res.data);
      else offerOrder = extractOrderTemplate(res.data);

      this.setState(
        {
          validated: false,
          actionName: offerOrder?.actionName ?? '',
          distributionDay: moment(offerOrder?.distributionDay, 'DD.MM.YYYY') ?? null,
          weekpart: offerOrder?.weekpart ?? WEEKPART_WEEKEND,
          datePickerFocused: false,
          copiesToSubsidiary: offerOrder?.copiesToSubsidiary ?? 0,
          city: offerOrder?.city ?? '',
          company: offerOrder?.company ?? '',
          email: offerOrder?.email ?? '',
          prename: offerOrder?.prename ?? '',
          housenumber: offerOrder?.housenumber ?? '',
          phone: offerOrder?.phone ?? '',
          postcode: offerOrder?.postcode ?? '',
          salutation: offerOrder?.salutation ?? '',
          street: offerOrder?.street ?? '',
          lastname: offerOrder?.lastname ?? '',
          product: offerOrder?.product ?? ({} as Product)
        },
        () => reqSuccess(FETCH_CLIENT_OFFER_ORDER)
      );
      return;
    }

    reqFailure(FETCH_CLIENT_OFFER_ORDER, {
      title: fetchClientOfferOrderErrorTitle,
      content: fetchAllClientOfferOrderErrorContent(
        res.response?.status ?? res.request?.status ?? 404,
        isOffer
      )
    });
  }

  render() {
    const { client } = this.props;
    const {
      validated,
      actionName,
      weekpart,
      copiesToSubsidiary,
      city,
      company,
      email,
      prename,
      housenumber,
      phone,
      postcode,
      salutation,
      street,
      lastname,
      distributionDay,
      datePickerFocused,
      product
    } = this.state;

    const enableUpdate = false;

    return (
      <Form
        id="offer-order-detail-form"
        className="offer-order-detail-form"
        onSubmit={this.onSubmit}
        noValidate
        validated={validated}
      >
        <ClientDetailsOfferOrderDetailsForm
          client={client}
          actionName={actionName}
          weekpart={weekpart}
          copiesToSubsidiary={copiesToSubsidiary}
          city={city}
          company={company}
          email={email}
          prename={prename}
          housenumber={housenumber}
          phone={phone}
          postcode={postcode}
          product={product}
          salutation={salutation}
          street={street}
          lastname={lastname}
          datePickerFocused={datePickerFocused}
          distributionDay={distributionDay}
          changeActionName={this.onChangeActionName}
          changeWeekpart={this.onChangeWeekpart}
          changeCopiesToSubsidiary={this.onChangeCopiesToSubsidiary}
          onDatePickerFocusChange={this.onDatePickerFocusChange}
          changeDistributionDay={this.onChangeDistributionDay}
          changeProduct={this.onChangeProduct}
          changeCity={this.onChangeCity}
          changeCompany={this.onChangeCompany}
          changeEmail={this.onChangeEmail}
          changePrename={this.onChangePrename}
          changeHousenumber={this.onChangeHousenumber}
          changePhone={this.onChangePhone}
          changePostcode={this.onChangePostcode}
          changeSalutation={this.onChangeSalutation}
          changeStreet={this.onChangeStreet}
          changeLastname={this.onChangeLastname}
          isDayBlocked={this.isDayBlocked}
        />
        <Row className="no-gutters">
          <Col sm={12} xl={4}>
            <Row className="no-gutters">
              {enableUpdate && (
                <Col sm={4} className="p-1">
                  <Button type="submit" variant="success" block>
                    {BUTTON_APPLY}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem,
  offerOrder: state.entities.clients.selectedItem?.offerOrder.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction | ClientOfferOrderAction>
) => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  offerOrderUpdate: () => dispatch(() => {}),
  offerOrderDelete: () => dispatch(() => {}),
  showConfirmation: (confirmationMessage: ConfirmationMessage) =>
    dispatch(showConfirmationModal(confirmationMessage))
});

const ClientDetailsOfferOrderDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsOfferOrderDetails)
);
export default ClientDetailsOfferOrderDetailsContainer;
