import {
  FETCH_CLIENT_USERS,
  ADD_CLIENT_USER,
  DELETE_CLIENT_USER
} from '../../constants/actionNames/clients/clientActions';

import { User } from '../../@types/Common.d';
import {
  FetchClientUsersAction,
  AddClientUserAction,
  DeleteClientUserAction
} from '../../@types/Actions/Client/ClientUser.d';

export const fetchClientUsersAction = (users: User[]): FetchClientUsersAction => ({
  type: FETCH_CLIENT_USERS,
  users
});

export const addClientUserAction = (user: User): AddClientUserAction => ({
  type: ADD_CLIENT_USER,
  user
});

export const deleteClientUserAction = (user: User): DeleteClientUserAction => ({
  type: DELETE_CLIENT_USER,
  user
});
