import * as React from 'react';

import { Form, Col, FormControl } from 'react-bootstrap';

import {
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_RANGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_DURATION,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_HOMEPAGE
} from '../../../../../constants/labels';

import { AdditionalOptionsListItemKaufDaDefaultProps } from '../../../../../@types/Clients.d';

const AdditionalOptionItemKaufDaDefault: React.FC<AdditionalOptionsListItemKaufDaDefaultProps> = (
  props: AdditionalOptionsListItemKaufDaDefaultProps
) => {
  const { additionalOption, changeAdditionalOption } = props;

  const { defaultDuration, defaultHomepage, defaultRange } = additionalOption;

  const onChangeRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({ ...additionalOption, ...{ defaultRange: event.currentTarget.value } });
  };

  const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultDuration: event.currentTarget.value }
    });
  };

  const onChangeHomepage = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultHomepage: event.currentTarget.value }
    });
  };

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="number"
              value={defaultRange}
              onChange={onChangeRange}
              placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_RANGE}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="number"
              value={defaultDuration}
              onChange={onChangeDuration}
              placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_DURATION}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="text"
              value={defaultHomepage}
              onChange={onChangeHomepage}
              placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_HOMEPAGE}
            />
          </Form.Group>
        </Col>
        <Col />
      </Form.Row>
    </>
  );
};

export default AdditionalOptionItemKaufDaDefault;
