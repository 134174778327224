// eslint-disable-next-line import/no-cycle
import { AreaSendFormat } from '../@types/Common.d';

export const APP_TITLE = 'FPP Administration';

export const TOOLTIP_WARN_DATA_INCOMPLETE = (lat?: string, lon?: string) =>
  `${lat && lon ? 'Unvollständige Angaben' : 'Filiale nicht verortet'}`;

export const LOGIN_TITLE = 'Login';

export const BUTTON_SUBMIT = 'Abschicken';
export const BUTTON_ABORT = 'Abbrechen';
export const BUTTON_CLOSE = 'Schließen';
export const BUTTON_APPLY = 'Übernehmen';
export const BUTTON_CREATE = 'Erstellen';
export const BUTTON_DELETE = 'Löschen';
export const BUTTON_OK = 'OK';
export const BUTTON_ADD = 'Hinzufügen';
export const BUTTON_LOCATE = 'Verorten';
export const BUTTON_IMPORT = 'Importieren';
export const BUTTON_EXPORT_CSV = 'Export .csv';
export const BUTTON_DOWNLOAD_MODEL = 'Vorlage';
export const BUTTON_DELETE_SELECTION = 'Auswahl löschen';
export const BUTTON_INVITE = 'Einladen';
export const BUTTON_SET_PASS = 'Passwort setzen';

export const TRANSMISSION_TYPE_ORDER_LABEL = 'Bestellung';
export const TRANSMISSION_TYPE_OFFER_LABEL = 'Angebot';

export const PLANNING_RESTRICTION_NONE_LABEL = 'Keine';
export const PLANNING_RESTRICTION_TEMPLATE_LABEL = 'Vorlage';

export const DISTRIBUTION_DAY_TYPE_FREE_LABEL = 'Frei';
export const DISTRIBUTION_DAY_TYPE_TEMPLATE_LABEL = 'Aktionen';

export const DATE_TYPE_DAY_LABEL = 'Tag';
export const DATE_TYPE_WEEK_LABEL = 'Woche';

export const INTERNAL_CLIENT_AGENCY_LABEL = 'Agentur Frey';
export const INTERNAL_CLIENT_MEDIA_LABEL = 'Freyplus Media';

export const LOGIN_BUTTON_LABEL = 'Einloggen';
export const LOGOUT_BUTTON_LABEL = 'Ausloggen';

export const LOGIN_EMAIL_LABEL = 'Email';
export const LOGIN_EMAIL_PLACEHOLDER = LOGIN_EMAIL_LABEL;
export const LOGIN_EMAIL_INVALID = 'Bitte geben Sie eine gültige Email an.';

export const LOGIN_PASSWORD_LABEL = 'Passwort';
export const LOGIN_PASSWORD_PLACEHOLDER = LOGIN_PASSWORD_LABEL;
export const LOGIN_PASSWORD_INVALID = 'Das Feld "Passwort" muss ausgefüllt sein';

export const DASHBOARD_TITLE = 'Dashboard';
export const DASHBOARD_GOTO_TEXT = (name: string) => `Zu ${name}`;

export const LIST_SEARCH_HINT_USERS = 'Benutzername filtern';
export const LIST_SEARCH_HINT_LAYERS = 'Gebiete filtern';
export const LIST_SEARCH_HINT_CLIENTS = 'Mandanten filtern';
export const LIST_SEARCH_HINT_CLIENT_LOCATIONS = 'Filialnamen filtern';
export const LIST_SEARCH_HINT_DISTRIBUTION_ACTIONS = 'Aktionsnamen filtern';
export const LIST_SEARCH_HINT_PRODUCTS = 'Produkte filtern';
export const LIST_SEARCH_HINT_OFFER_ORDER = (offer: boolean) =>
  offer ? `${TRANSMISSION_TYPE_OFFER_LABEL}e` : `${TRANSMISSION_TYPE_ORDER_LABEL}en filtern`;

export const CLIENTS_TITLE = 'Kunden';
export const CLIENTS_DESCRIPTION = 'Verwaltung von Kunden';
export const CLIENTS_EMPTY_LIST = 'Keine Kunden vorhanden';
export const CLIENTS_ADD_LIST_ITEM = 'Neuen Kunden hinzufügen';
export const CLIENTS_ADD_DISTRIBUTION_ACTION = 'Neue Aktion hinzufügen';

export const CLIENTS_NEW = 'Neuen Kunden anlegen';

export const CLIENTS_DETAILS_TAB_CLIENT = 'Kunde';
export const CLIENTS_DETAILS_TAB_CLIENT_LOCATIONS = 'Filialen';
export const CLIENTS_DETAILS_TAB_USERS = 'Benutzer';
export const CLIENTS_DETAILS_TAB_PRODUCTS = 'Produkte';
export const CLIENTS_DETAILS_TAB_LAYERS = 'Gebiete';
export const CLIENTS_DETAILS_TAB_DISTRIBUTION_TEMPLATES = 'Verteilvorlagen';
export const CLIENTS_DETAILS_TAB_OFFER_ORDER = (offer: boolean) =>
  offer ? `${TRANSMISSION_TYPE_OFFER_LABEL}e` : `${TRANSMISSION_TYPE_ORDER_LABEL}en`;
export const CLIENTS_DETAILS_TAB_ADDITIONAL_OPTIONS = 'Zusätzliche Optionen';
export const CLIENTS_DETAILS_TAB_MODULE = 'Module';
export const CLIENTS_DETAILS_TAB_DISTRIBUTION_ACTIONS = 'Aktionen';

export const CLIENTS_OFFER_ORDER_TAB_DATA = (offer: boolean) =>
  `${offer ? TRANSMISSION_TYPE_OFFER_LABEL : TRANSMISSION_TYPE_ORDER_LABEL}sdetails`;
export const CLIENTS_OFFER_ORDER_TAB_DISTRIBUTION = 'Verteilungsdetails';

export const INVALID_FIELD = (fieldName: string) => `Das Feld "${fieldName}" muss befüllt werden`;

export const CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_LABEL = 'Aktionsname';
export const CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_LABEL = 'Verteiltag';
export const CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_LABEL = 'Verteiltag';
export const CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_COPIES_LABEL = 'Marktbelege';
export const CLIENTS_OFFER_ORDER_DETAILS_COPIES_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_COPIES_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_COPIES_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_COPIES_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_CITY_LABEL = 'Stadt';
export const CLIENTS_OFFER_ORDER_DETAILS_CITY_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_CITY_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_CITY_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_CITY_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_COMPANY_LABEL = 'Firma';
export const CLIENTS_OFFER_ORDER_DETAILS_COMPANY_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_COMPANY_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_COMPANY_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_COMPANY_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_EMAIL_LABEL = 'Email-Adresse';
export const CLIENTS_OFFER_ORDER_DETAILS_EMAIL_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_EMAIL_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_EMAIL_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_EMAIL_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_PRENAME_LABEL = 'Vorname';
export const CLIENTS_OFFER_ORDER_DETAILS_PRENAME_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_PRENAME_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_PRENAME_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_PRENAME_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_LABEL = 'Hausnummer';
export const CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_PHONE_LABEL = 'Telefon';
export const CLIENTS_OFFER_ORDER_DETAILS_PHONE_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_PHONE_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_PHONE_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_PHONE_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_LABEL = 'Postleitzahl';
export const CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_LABEL = 'Anrede';
export const CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_STREET_LABEL = 'Straße';
export const CLIENTS_OFFER_ORDER_DETAILS_STREET_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_STREET_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_STREET_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_STREET_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_LABEL = 'Nachname';
export const CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_LABEL
);
export const CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_LABEL = 'Produkt';
export const CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_PLACEHOLDER = CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_LABEL;
export const CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_INVALID = INVALID_FIELD(
  CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_LABEL
);

export const CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_OFFER_ORDER_DATA = 'Auftragsdaten';
export const CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_USER_DATA = 'Benutzerdaten';
export const CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_ADDRESS_DATA = 'Anschrift';

export const CLIENTS_OFFER_ORDER_DISTRIBUTION_CIRCULATION_HEADER = 'Gesamtauflage';
export const CLIENTS_OFFER_ORDER_DISTRIBUTION_DISTRIBUTION_HEADER = 'Gewählte Gebiete';

export const CLIENT_LOCATIONS_TAB_ADDRESS = 'Adresse';
export const CLIENT_LOCATIONS_TAB_COORD = 'Koordinaten';
export const CLIENT_LOCATIONS_TAB_DATA = 'Stammdaten';
export const CLIENT_LOCATIONS_TAB_OPENING_HOURS = 'Öffnungszeiten';

export const CLIENTS_DETAILS_FORM_CLIENT_SECTION = 'Kundendaten';
export const CLIENTS_DETAILS_FORM_BILLINIG_SECTION = 'Rechnungsadresse';
export const CLIENTS_DETAILS_FORM_POI_ICONS = 'Filialmarker';
export const CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_LABEL =
  'Email-Adresse Kundenbetreuer/Verkäufer';
export const CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_PLACEHOLDER = CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_LABEL;
export const CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_INVALID =
  'Bitte geben Sie eine gültige Email an.';
export const CLIENTS_DETAILS_NAME_LABEL = 'Name';
export const CLIENTS_DETAILS_NAME_PLACEHOLDER = CLIENTS_DETAILS_NAME_LABEL;
export const CLIENTS_DETAILS_NAME_INVALID = INVALID_FIELD(CLIENTS_DETAILS_NAME_LABEL);
export const CLIENTS_DETAILS_TRANSMISSION_TYPE_LABEL = 'Übertragungstyp';
export const CLIENTS_DETAILS_TRANSMISSION_TYPE_PLACEHOLDER = CLIENTS_DETAILS_TRANSMISSION_TYPE_LABEL;
export const CLIENTS_DETAILS_TRANSMISSION_TYPE_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_TRANSMISSION_TYPE_LABEL
);
export const CLIENTS_DETAILS_PLANNING_RESTRICTION_LABEL = 'Planungseinschränkung';
export const CLIENTS_DETAILS_PLANNING_RESTRICTION_PLACEHOLDER = CLIENTS_DETAILS_PLANNING_RESTRICTION_LABEL;
export const CLIENTS_DETAILS_PLANNING_RESTRICTION_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_PLANNING_RESTRICTION_LABEL
);
export const CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_LABEL = 'Aktionszeitpunkt';
export const CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_PLACEHOLDER = CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_LABEL;
export const CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_LABEL
);
export const CLIENTS_DETAILS_INTERNAL_CLIENT_LABEL = 'Interner Mandant';
export const CLIENTS_DETAILS_INTERNAL_CLIENT_PLACEHOLDER = CLIENTS_DETAILS_INTERNAL_CLIENT_LABEL;
export const CLIENTS_DETAILS_INTERNAL_CLIENT_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_INTERNAL_CLIENT_LABEL
);
export const CLIENTS_DETAILS_BILLING_TYPE_LABEL = 'Rechungsziel';
export const CLIENTS_DETAILS_BILLING_TYPE_PLACEHOLDER = CLIENTS_DETAILS_BILLING_TYPE_LABEL;
export const CLIENTS_DETAILS_BILLING_TYPE_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_BILLING_TYPE_LABEL
);
export const CLIENTS_DETAILS_BILLING_TYPE_CLIENT = 'Rechnungsadresse';
export const CLIENTS_DETAILS_BILLING_TYPE_LOCATION = 'Gewählte Filiale';
export const CLIENTS_DETAILS_BILLING_TYPE_PER_LOCATION = 'Pro Filiale';
export const CLIENTS_DETAILS_WEEKPART_TITLE = 'Erscheinungstage';
export const CLIENTS_DETAILS_WEEKPART_PLACEHOLDER = CLIENTS_DETAILS_WEEKPART_TITLE;
export const CLIENTS_DETAILS_WEEKPART_INVALID = INVALID_FIELD(CLIENTS_DETAILS_WEEKPART_TITLE);
export const CLIENTS_DETAILS_WEEKPART_MIDWEEK_LABEL = 'Wochenmitte';
export const CLIENTS_DETAILS_WEEKPART_WEEKEND_LABEL = 'Wochenende';
export const CLIENTS_DETAILS_WEEKPART_BEST_LABEL = 'Optimal';
export const CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_TITLE = 'Angebotsvorlage';
export const CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_PLACEHOLDER = CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_TITLE;
export const CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_TITLE
);

export const CLIENTS_DETAILS_BILL_CITY_LABEL = 'Stadt';
export const CLIENTS_DETAILS_BILL_CITY_PLACEHOLDER = CLIENTS_DETAILS_BILL_CITY_LABEL;
export const CLIENTS_DETAILS_BILL_CITY_INVALID = INVALID_FIELD(CLIENTS_DETAILS_BILL_CITY_LABEL);
export const CLIENTS_DETAILS_BILL_HOUSENUMBER_LABEL = 'Hausnummer';
export const CLIENTS_DETAILS_BILL_HOUSENUMBER_PLACEHOLDER = CLIENTS_DETAILS_BILL_HOUSENUMBER_LABEL;
export const CLIENTS_DETAILS_BILL_HOUSENUMBER_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_BILL_HOUSENUMBER_LABEL
);
export const CLIENTS_DETAILS_BILL_NAME_LABEL = 'Name';
export const CLIENTS_DETAILS_BILL_NAME_PLACEHOLDER = CLIENTS_DETAILS_BILL_NAME_LABEL;
export const CLIENTS_DETAILS_BILL_NAME_INVALID = INVALID_FIELD(CLIENTS_DETAILS_BILL_NAME_LABEL);
export const CLIENTS_DETAILS_BILL_POSTCODE_LABEL = 'Postleitzahl';
export const CLIENTS_DETAILS_BILL_POSTCODE_PLACEHOLDER = CLIENTS_DETAILS_BILL_POSTCODE_LABEL;
export const CLIENTS_DETAILS_BILL_POSTCODE_INVALID = INVALID_FIELD(
  CLIENTS_DETAILS_BILL_POSTCODE_LABEL
);
export const CLIENTS_DETAILS_BILL_STREET_LABEL = 'Straße';
export const CLIENTS_DETAILS_BILL_STREET_PLACEHOLDER = CLIENTS_DETAILS_BILL_STREET_LABEL;
export const CLIENTS_DETAILS_BILL_STREET_INVALID = INVALID_FIELD(CLIENTS_DETAILS_BILL_STREET_LABEL);

export const CLIENTS_DETAILS_FREY_MEDIA_LABEL = 'Frey Media';
export const CLIENTS_DETAILS_SHOW_PRICE_LABEL = 'Preis anzeigen';
export const CLIENTS_DETAILS_GENERATE_OFFER_LABEL = 'Angebot erstellen';

export const CLIENT_LOCATIONS_TABLE_HEADER_POI_ICON = 'Filialmarker';
export const CLIENT_LOCATIONS_TABLE_HEADER_NAME = 'Name';
export const CLIENT_LOCATIONS_TABLE_HEADER_ADDRESS = 'Adresse';
export const CLIENT_LOCATIONS_TABLE_HEADER_NUMMER = 'BBNr';
export const CLIENT_LOCATIONS_TABLE_HEADER_SELECTED_FILL = 'Filialfarbe';
export const CLIENT_LOCATIONS_TABLE_HEADER_BILLING_DEFAULT = 'Rechnungsfiliale';
export const CLIENT_LOCATIONS_TABLE_HEADER_PLANABLE = 'Planbar';
export const CLIENT_LOCATIONS_TABLE_HEADER_ACTIONS = 'Aktionen';

export const CLIENT_LOCATIONS_MODAL_TITLE = (edit: boolean) =>
  `Filiale ${edit ? 'erstellen' : 'bearbeiten'}`;
export const CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_TITLE = 'Firmenname';
export const CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_TITLE;
export const CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_TITLE
);
export const CLIENT_LOCATIONS_MODAL_EMAIL_TITLE = 'Email';
export const CLIENT_LOCATIONS_MODAL_EMAIL_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_EMAIL_TITLE;
export const CLIENT_LOCATIONS_MODAL_EMAIL_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_EMAIL_TITLE
);
export const CLIENT_LOCATIONS_MODAL_PHONE_TITLE = 'Telefon';
export const CLIENT_LOCATIONS_MODAL_PHONE_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_PHONE_TITLE;
export const CLIENT_LOCATIONS_MODAL_PHONE_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_PHONE_TITLE
);
export const CLIENT_LOCATIONS_MODAL_CITY_TITLE = 'Stadt';
export const CLIENT_LOCATIONS_MODAL_CITY_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_CITY_TITLE;
export const CLIENT_LOCATIONS_MODAL_CITY_INVALID = INVALID_FIELD(CLIENT_LOCATIONS_MODAL_CITY_TITLE);
export const CLIENT_LOCATIONS_MODAL_HOUSENUMBER_TITLE = 'Hausnummer';
export const CLIENT_LOCATIONS_MODAL_HOUSENUMBER_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_HOUSENUMBER_TITLE;
export const CLIENT_LOCATIONS_MODAL_HOUSENUMBER_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_HOUSENUMBER_TITLE
);
export const CLIENT_LOCATIONS_MODAL_NAME_TITLE = 'Name';
export const CLIENT_LOCATIONS_MODAL_NAME_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_NAME_TITLE;
export const CLIENT_LOCATIONS_MODAL_NAME_INVALID = INVALID_FIELD(CLIENT_LOCATIONS_MODAL_NAME_TITLE);
export const CLIENT_LOCATIONS_MODAL_PLANABLE_TITLE = 'Planbar';
export const CLIENT_LOCATIONS_MODAL_PLANABLE_LABEL =
  'Es kann eine Verteilung für diese Filiale erstellt werden.';
export const CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_TITLE = 'Rechnungsfiliale';
export const CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_LABEL =
  'Die Rechung soll standardmäßig an diese Filiale versendet werden.';
export const CLIENT_LOCATIONS_MODAL_NUMBER_TITLE = 'Betriebsnummer';
export const CLIENT_LOCATIONS_MODAL_NUMBER_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_NUMBER_TITLE;
export const CLIENT_LOCATIONS_MODAL_NUMBER_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_NUMBER_TITLE
);
export const CLIENT_LOCATIONS_MODAL_POSTCODE_TITLE = 'Postleitzahl';
export const CLIENT_LOCATIONS_MODAL_POSTCODE_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_POSTCODE_TITLE;
export const CLIENT_LOCATIONS_MODAL_POSTCODE_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_POSTCODE_TITLE
);
export const CLIENT_LOCATIONS_MODAL_STREET_TITLE = 'Straße';
export const CLIENT_LOCATIONS_MODAL_STREET_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_STREET_TITLE;
export const CLIENT_LOCATIONS_MODAL_STREET_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_STREET_TITLE
);
export const CLIENT_LOCATIONS_MODAL_COORD_LON_TITLE = 'Längengrad/Longitude';
export const CLIENT_LOCATIONS_MODAL_COORD_LON_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_COORD_LON_TITLE;
export const CLIENT_LOCATIONS_MODAL_COORD_LON_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_COORD_LON_TITLE
);
export const CLIENT_LOCATIONS_MODAL_COORD_LAT_TITLE = 'Breitengrad/Latitude';
export const CLIENT_LOCATIONS_MODAL_COORD_LAT_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_COORD_LAT_TITLE;
export const CLIENT_LOCATIONS_MODAL_COORD_LAT_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_COORD_LAT_TITLE
);
export const CLIENT_LOCATIONS_MODAL_COLOR = 'Filialfarbe';
export const CLIENT_LOCATIONS_MODAL_POI_ICON_TITLE = 'Filialmarker';
export const CLIENT_LOCATIONS_MODAL_POI_ICON_PLACEHOLDER = CLIENT_LOCATIONS_MODAL_POI_ICON_TITLE;
export const CLIENT_LOCATIONS_MODAL_POI_ICON_INVALID = INVALID_FIELD(
  CLIENT_LOCATIONS_MODAL_POI_ICON_TITLE
);
export const CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_TITLE = 'Bitte Adresse wählen';
export const CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_EMPTY = 'Keine Adressen gefunden';
export const CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION =
  'Es muss eine Adresse ausgewählt werden.';
export const CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED =
  'Die angegebene Adresse muss verortet werden.';

export const CLIENT_OFFER_ORDER_EMPTY_LIST = (offer: boolean) =>
  `Keine ${
    offer ? `${TRANSMISSION_TYPE_OFFER_LABEL}e` : `${TRANSMISSION_TYPE_ORDER_LABEL}en`
  } vorhanden`;

export const CLIENT_LOCATIONS_MODAL_TAB_DATA = 'Stammdaten';
export const CLIENT_LOCATIONS_MODAL_TAB_OPENING_HOURS = 'Öffnungszeiten';
export const CLIENT_LOCATIONS_MODAL_MORNING = 'Morgen';
export const CLIENT_LOCATIONS_MODAL_NOON = 'Nachmittag';
export const CLIENT_LOCATIONS_MODAL_FROM = 'Von';
export const CLIENT_LOCATIONS_MODAL_TO = 'Bis';
export const CLIENT_LOCATIONS_MODAL_SPLIT_LABEL = 'Durchgehend geöffnet';
export const CLIENT_LOCATIONS_MODAL_OPENING_HOUR_INVALID = 'Bitte befüllen';

export const CLIENT_OFFER_ORDER_DISTRIBUTION_EMPTY_LIST = 'Keine Filalen vorhanden.';
export const CLIENT_OFFER_ORDER_AREA_EMPTY_LIST = 'Keine Gebiete vorhanden.';

export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE = 'Kauf Da';
export const CLIENT_ADDITIONAL_OPTION_FACEBOOK_TITLE = 'Facebook';
export const CLIENT_ADDITIONAL_OPTION_INSTAGRAM_TITLE = 'Instagram';
export const CLIENT_ADDITIONAL_OPTION_TIKTOK_TITLE = 'TikTok';

export const CLIENT_ADDITIONAL_OPTION_PRESET_LABEL = 'Vorlagen';

export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE = 'Reichweite';
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION = 'Dauer & Preise';
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE = 'Homepage';
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_PRICE = 'Preis';
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_RANGE = CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE;
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_DURATION = CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION;
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_HOMEPAGE = CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE;
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_PRICE = CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_PRICE;
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_RANGE = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE
);
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_DURATION = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION
);
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_PRICE = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE
);
export const CLIENT_ADDITIONAL_OPTION_KAUF_DA_ADD_PRICE_DURATION =
  'Neues Dauer/Preis-Paar hinzufügen';

export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE = 'Reichweite';
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION = 'Dauer';
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET = 'Budget';
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP = 'Zielgruppen';
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_RANGE = CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE;
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_DURATION = CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION;
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_BUDGET = CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET;
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_TARGET_GROUP = CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP;
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_RANGE = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE
);
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_DURATION = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION
);
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_BUDGET = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET
);
export const CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_TARGET_GROUP = INVALID_FIELD(
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP
);

export const CHANGE_PASSOWORD_MODAL_TITLE = 'Passwort ändern';
export const CHANGE_PASSOWORD_MODAL_CONTENT = 'Passwort ändern';
export const CHANGE_PASSOWORD_MODAL_NEW_PASS_LABEL = 'Neues Passwort';
export const CHANGE_PASSOWORD_MODAL_NEW_PASS_PLACEHOLDER = CHANGE_PASSOWORD_MODAL_NEW_PASS_LABEL;
export const CHANGE_PASSOWORD_MODAL_NEW_PASS_INVALID = 'Es muss ein Passwort vergeben werden.';

export const CLIENT_USERS_ASSIGNED_USERS = 'Zuständige Benutzer';
export const CLIENT_USERS_NOT_ASSIGNED_USERS = 'Verfügbare Benutzer';

export const CLIENT_LAYERS_ASSIGNED_LAYERS = 'Zuständige Gebiete';
export const CLIENT_LAYERS_NOT_ASSIGNED_LAYERS = 'Verfügbare Gebiete';

export const CLIENT_PRODUCTS_ASSIGNED_USERS = 'Freigegebene Produkte';
export const CLIENT_PRODUCTS_NOT_ASSIGNED_USERS = 'Verfügbare Produkte';

export const CLIENT_DISTRIBUTION_ACTIONS_EMTPY_LIST = 'Keine Aktionen vorhanden.';

export const CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_LABEL = 'Aktionsname';
export const CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_PLACEHOLDER = CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_LABEL;
export const CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_INVALID = INVALID_FIELD(
  CLIENTS_DISTRIBUTION_ACTIONS_ACTION_NAME_LABEL
);
export const CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_LABEL = 'Kalenderwoche';
export const CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_PLACEHOLDER = CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_LABEL;
export const CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_INVALID = INVALID_FIELD(
  CLIENTS_DISTRIBUTION_ACTIONS_CALENDAR_WEEK_LABEL
);
export const CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_LABEL = 'Verteiltag';
export const CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_PLACEHOLDER = CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_LABEL;
export const CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_INVALID = INVALID_FIELD(
  CLIENTS_DISTRIBUTION_ACTIONS_DISTRIBUTION_DATE_LABEL
);
export const CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_LABEL = 'Datumsgenauigkeit';
export const CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_PLACEHOLDER = CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_LABEL;
export const CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_INVALID = INVALID_FIELD(
  CLIENTS_DISTRIBUTION_ACTIONS_DATE_TYPE_LABEL
);

export const CLIENTS_MODULES_EMPTY = 'Keine Module verfügbar';

export const PRODUCTS_TITLE = 'Produkte';
export const PRODUCTS_DESCRIPTION = 'Verwaltung von Produkten';
export const PRODUCTS_EMPTY_LIST = 'Keine Produkte vorhanden';
export const PRODUCTS_ADD_LIST_ITEM = 'Neuen Produkte hinzufügen';

export const PRODUCTS_DETAILS_TAB_PRODUCT = 'Produkt';
export const PRODUCTS_DETAILS_TAB_PRODUCT_PRICING = 'Produktpreise';

export const PRODUCTS_NEW = 'Neues Produkt anlegen';

export const PRODUCT_DETAILS_NAME_LABEL = 'Produktname';
export const PRODUCT_DETAILS_NAME_PLACEHOLDER = PRODUCT_DETAILS_NAME_LABEL;
export const PRODUCT_DETAILS_NAME_INIVALID = INVALID_FIELD(PRODUCT_DETAILS_NAME_LABEL);
export const PRODUCT_DETAILS_DESCRIPTION_LABEL = 'Beschreibung';
export const PRODUCT_DETAILS_DESCRIPTION_PLACEHOLDER = PRODUCT_DETAILS_DESCRIPTION_LABEL;
export const PRODUCT_DETAILS_PRINT_DOC_REQUIRED_LABEL = 'Druchvorlage notwendig';

export const USERS_TITLE = 'Benutzer';
export const USERS_DESCRIPTION = 'Verwaltung von Benutzern';
export const USERS_EMPTY_LIST = 'Keine Benutzer vorhanden';
export const USERS_ADD_LIST_ITEM = 'Neuen Benutzer hinzufügen';

export const USERS_DETAILS_TAB_USER = 'Benutzer';

export const USERS_NEW = 'Neuen Benutzer anlegen';

export const USERS_DETAILS_FORM_USER_SECTION = 'Benutzerdaten';

export const USERS_DETAILS_EMAIL_LABEL = 'Email';
export const USERS_DETAILS_EMAIL_PLACEHOLDER = USERS_DETAILS_EMAIL_LABEL;
export const USERS_DETAILS_EMAIL_INVALID = INVALID_FIELD(USERS_DETAILS_EMAIL_LABEL);
export const USERS_DETAILS_PRENAME_LABEL = 'Vorname';
export const USERS_DETAILS_PRENAME_PLACEHOLDER = USERS_DETAILS_PRENAME_LABEL;
export const USERS_DETAILS_PRENAME_INVALID = INVALID_FIELD(USERS_DETAILS_PRENAME_LABEL);
export const USERS_DETAILS_LASTNAME_LABEL = 'Nachname';
export const USERS_DETAILS_LASTNAME_PLACEHOLDER = USERS_DETAILS_LASTNAME_LABEL;
export const USERS_DETAILS_LASTNAME_INVALID = INVALID_FIELD(USERS_DETAILS_LASTNAME_LABEL);
export const USERS_DETAILS_SALUTATION_LABEL = 'Anrede';
export const USERS_DETAILS_SALUTATION_PLACEHOLDER = USERS_DETAILS_SALUTATION_LABEL;
export const USERS_DETAILS_SALUTATION_INVALID = INVALID_FIELD(USERS_DETAILS_SALUTATION_LABEL);
export const USERS_DETAILS_ADMIN_LABEL = 'Administrator';
export const USERS_DETAILS_INTERNAL_LABEL = 'Intern';

export const DELETE_CONFIRMATION_MODAL_TITLE = 'Eintrag löschen?';
export const DELETE_CONFIRMATION_MODAL_CONTENT = 'Wollen Sie diesen Eintrag wirklich löschen?';
export const DELETE_MULTIPLE_CONFIRMATION_MODAL_TITLE = 'Mehrere Einträge löschen?';
export const DELETE_MULTIPLE_CONFIRMATION_MODAL_CONTENT = (number: number) =>
  `Wollen Sie diese ${number} Einträge wirklich löschen?`;

export const LOCALITIES_MODAL_TITLE = (area: AreaSendFormat) =>
  `Gewählte Ortsteile von ${area.areaKey}, ${area.name}`;

export const LOCALITIES_EMPTY_LIST = 'Keine Ortsteile gefunden.';

export const CURRENCY = 'Euro';
export const CURRENCY_SHORTER = 'EUR';
export const CURRENCY_SHORT = '€';

export const WEEKS = 'Woche(n)';
export const WEEKS_SHORT = 'Wo.';

export const CALENDAR_WEEK = 'Kalenderwoche';
export const CALENDAR_WEEK_SHORT = 'KW';
