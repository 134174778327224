import * as React from 'react';

import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { RGBColor } from 'react-color';
import { forEach, map } from 'lodash';
import { withRouter } from 'react-router-dom';

import ClientLocationModalForm from './ClientLocationModalForm';

import { hideClientLocationModal } from '../../../actions/uiActions';

import { rgbObjectTorgb } from '../../../util/utils';
import {
  getClientLocationCoordinates,
  updateClientLocation,
  createClientLocation,
  getPOIs,
  getClientLocationAddress
} from '../../../util/api/clientLocationApi';

import {
  BUTTON_APPLY,
  BUTTON_ABORT,
  CLIENT_LOCATIONS_MODAL_TITLE,
  CLIENT_LOCATIONS_TAB_DATA,
  CLIENT_LOCATIONS_TAB_OPENING_HOURS
} from '../../../constants/labels';
import {
  CLIENT_LOCATIONS_TAB_ID_DATA,
  CLIENT_LOCATIONS_TAB_ID_OPENING_HOURS
} from '../../../constants/constants';

import {
  GlobalState,
  Coordinates,
  AddressResult,
  Client,
  ClientLocation,
  POI,
  OpeningHoursSend
} from '../../../@types/Common.d';
import { ClientLocationModalProps, ClientLocationModalState } from '../../../@types/Modal.d';
import { ClientLocationsAction } from '../../../@types/Actions/Client/ClientLocation.d';
import { ClientLocationModalAction } from '../../../@types/Actions/UI.d';
import ClientLocationModalOpeningHours from './ClientLocationModalOpeningHours';

class ClientLocationModal extends React.Component<
  ClientLocationModalProps,
  ClientLocationModalState
> {
  clientLocationOpeningHoursRef = React.createRef<ClientLocationModalOpeningHours>();

  constructor(props: ClientLocationModalProps) {
    super(props);

    const { clientLocation } = this.props;

    this.state = {
      validated: false,
      loading: false,
      showPicker: false,
      addressResult: undefined,
      addressName: clientLocation?.addressName ?? '',
      city: clientLocation?.city ?? '',
      colorSelectedFill: clientLocation?.colorSelectedFill ?? 'rgba(0, 0, 150, 0.2)',
      housenumber: clientLocation?.housenumber ?? '',
      lat: clientLocation?.lat,
      lon: clientLocation?.lon,
      name: clientLocation?.name ?? '',
      number: clientLocation?.number,
      postcode: clientLocation?.postcode ?? '',
      street: clientLocation?.street ?? '',
      planable: true,
      availablePOIs: [],
      poi: clientLocation?.poi ?? ({} as POI),
      billingDefault: false
    };

    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.resetModal = this.resetModal.bind(this);

    this.getAddressCoordinates = this.getAddressCoordinates.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.selectAddress = this.selectAddress.bind(this);

    this.changeAddressName = this.changeAddressName.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeColorSelectedFill = this.changeColorSelectedFill.bind(this);
    this.changeHousenumber = this.changeHousenumber.bind(this);
    this.changePOIIcon = this.changePOIIcon.bind(this);
    this.changeLat = this.changeLat.bind(this);
    this.changeLon = this.changeLon.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeNumber = this.changeNumber.bind(this);
    this.changePostcode = this.changePostcode.bind(this);
    this.changeStreet = this.changeStreet.bind(this);
    this.changePlanable = this.changePlanable.bind(this);
    this.changeBillingDefault = this.changeBillingDefault.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePhone = this.changePhone.bind(this);
  }

  async componentDidMount() {
    const availablePOIs = (await getPOIs()) as POI[];

    if (Number.isNaN(+availablePOIs)) {
      this.setState({ availablePOIs });
    }
  }

  componentDidUpdate(prevState: ClientLocationModalProps) {
    const { clientLocation } = this.props;

    if (clientLocation !== prevState.clientLocation) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        validated: false,
        showPicker: false,
        addressResult: undefined,
        id: clientLocation?.id,
        addressName: clientLocation?.addressName ?? '',
        city: clientLocation?.city ?? '',
        colorSelectedFill: clientLocation?.colorSelectedFill ?? 'rgba(0, 0, 150, 0.2)',
        email: clientLocation?.email,
        housenumber: clientLocation?.housenumber ?? '',
        lat: clientLocation?.lat,
        lon: clientLocation?.lon,
        name: clientLocation?.name ?? '',
        number: clientLocation?.number,
        postcode: clientLocation?.postcode ?? '',
        street: clientLocation?.street ?? '',
        planable: clientLocation?.planable ?? true,
        poi: clientLocation?.poi ?? ({} as POI),
        phone: clientLocation?.phone,
        billingDefault: clientLocation?.billingDefault ?? false
      });
    }
  }

  onHide(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();

    const { hideModal } = this.props;

    this.resetModal();
    hideModal();
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const {
          clientLocation,
          client,
          clientLocationCreate,
          clientLocationUpdate,
          hideModal
        } = this.props;
        const {
          id,
          addressName,
          city,
          colorSelectedFill,
          email,
          housenumber,
          poi,
          lat,
          lon,
          name,
          number,
          phone,
          postcode,
          street,
          planable,
          billingDefault
        } = this.state;

        if (!client) return;

        let openingHours = {};

        const { current } = this.clientLocationOpeningHoursRef;

        if (current !== null) openingHours = current.getOpeningHours();

        if (billingDefault) {
          const { clientLocations } = client;

          clientLocations.allIds
            .map(pId => clientLocations.byId[pId])
            .filter((location: ClientLocation) => location.billingDefault)
            .forEach((location: ClientLocation) =>
              clientLocationUpdate(client, { ...location, ...{ billingDefault: false } })
            );
        }

        const clientLocationNew = {
          id,
          addressName,
          city,
          colorSelectedFill,
          email,
          housenumber,
          poi,
          lat,
          lon,
          name,
          number,
          openingHours,
          phone,
          postcode,
          street,
          planable,
          billingDefault
        } as ClientLocation;

        if (clientLocation) {
          clientLocationUpdate(client, clientLocationNew);
        } else {
          clientLocationCreate(client, clientLocationNew);
        }

        this.resetModal();
        hideModal();
      }
    });
  }

  async getAddressCoordinates() {
    const { postcode, city, street, housenumber, lat, lon } = this.state;

    this.setState({ loading: true, addressResult: undefined });
    let result;

    if (postcode && city && street && housenumber) {
      result = await getClientLocationCoordinates(postcode, city, street, housenumber);
    } else if (lat && lon) {
      result = await getClientLocationAddress(lat, lon);
    }

    let addressResult = [] as AddressResult[];

    if (result?.status === 200) {
      addressResult = map(result.data.features, (address: any) => {
        const { geometry, properties } = address;
        const { components } = properties;

        const coordinates = {
          lat: geometry.coordinates[1],
          lon: geometry.coordinates[0]
        } as Coordinates;

        let rCity;

        if (components.city) rCity = components.city;
        else if (components.town) rCity = components.town;
        else if (components.village) rCity = components.village;
        else if (components.hamlet) rCity = components.hamlet;
        else if (components.suburb) rCity = components.suburb;

        return {
          coordinates,
          name: properties.formatted,
          selected: false,
          street: components.road ?? '',
          housenumber: components.house_number ?? '',
          city: rCity ?? '',
          postcode: components.postcode ?? ''
        } as AddressResult;
      });
    }

    this.setState({
      loading: false,
      addressResult
    });
  }

  changeAddressName(addressName: string) {
    this.setState({ addressName });
  }

  changeCity(city: string) {
    this.setState({ city });
  }

  changeColorSelectedFill(color: RGBColor) {
    this.setState({ colorSelectedFill: rgbObjectTorgb(color, 0.2) });
  }

  changeHousenumber(housenumber: string) {
    this.setState({ housenumber });
  }

  changePOIIcon(poi: POI) {
    this.setState({ poi });
  }

  changeLat(lat: string) {
    this.setState({ lat });
  }

  changeLon(lon: string) {
    this.setState({ lon });
  }

  changeName(name: string) {
    this.setState({ name });
  }

  changeNumber(number: string) {
    this.setState({ number });
  }

  changePostcode(postcode: string) {
    this.setState({ postcode });
  }

  changePhone(phone: string) {
    this.setState({ phone });
  }

  changeEmail(email: string) {
    this.setState({ email });
  }

  changeStreet(street: string) {
    this.setState({ street });
  }

  changePlanable(planable: boolean) {
    this.setState({ planable });
  }

  changeBillingDefault(billingDefault: boolean) {
    this.setState({ billingDefault });
  }

  resetModal() {
    this.setState({
      validated: false,
      showPicker: false,
      addressResult: undefined,
      id: undefined,
      addressName: '',
      city: '',
      colorSelectedFill: '',
      email: undefined,
      housenumber: '',
      poi: {} as POI,
      lat: undefined,
      lon: undefined,
      name: '',
      number: undefined,
      postcode: '',
      street: '',
      phone: undefined,
      planable: true
    });
  }

  selectAddress(address: AddressResult) {
    const { addressResult, city, postcode, street, housenumber } = this.state;

    forEach(addressResult, rAddress => {
      if (address.name === rAddress.name) rAddress.selected = true;
      else rAddress.selected = false;
    });

    const { coordinates } = address;

    this.setState({
      addressResult,
      lat: coordinates.lat,
      lon: coordinates.lon,
      city: city || address.city,
      postcode: postcode || address.postcode,
      street: street || address.street,
      housenumber: housenumber || address.housenumber
    });
  }

  showColorPicker() {
    const { showPicker } = this.state;

    this.setState({ showPicker: !showPicker });
  }

  render() {
    const { clientLocation, show, hideModal, client } = this.props;
    const {
      validated,
      loading,
      showPicker,
      addressName,
      city,
      colorSelectedFill,
      email,
      housenumber,
      lat,
      lon,
      name,
      number,
      postcode,
      street,
      phone,
      planable,
      billingDefault,
      poi,
      addressResult,
      availablePOIs
    } = this.state;

    return (
      <Modal show={show} centered size="lg" onHide={() => hideModal('')}>
        <Modal.Header closeButton>
          <Modal.Title>{CLIENT_LOCATIONS_MODAL_TITLE(clientLocation === undefined)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="client-location-form"
            className="client-location-form"
            onSubmit={this.onSubmit}
            noValidate
            validated={validated}
          >
            <Tabs defaultActiveKey={CLIENT_LOCATIONS_TAB_ID_DATA} id="client-location-modal-tabs">
              <Tab eventKey={CLIENT_LOCATIONS_TAB_ID_DATA} title={CLIENT_LOCATIONS_TAB_DATA}>
                <ClientLocationModalForm
                  loading={loading}
                  showPicker={showPicker}
                  billingType={client?.billingType ?? 'LOCATION'}
                  addressResult={addressResult}
                  addressName={addressName}
                  city={city}
                  colorSelectedFill={colorSelectedFill}
                  email={email}
                  housenumber={housenumber}
                  availablePOIIcons={availablePOIs}
                  poi={poi}
                  lat={lat}
                  lon={lon}
                  name={name}
                  number={number}
                  phone={phone}
                  postcode={postcode}
                  street={street}
                  planable={planable}
                  billingDefault={billingDefault}
                  changeAddressName={this.changeAddressName}
                  changeCity={this.changeCity}
                  changeColorSelectedFill={this.changeColorSelectedFill}
                  changeHousenumber={this.changeHousenumber}
                  changeClientLocationPOIIcon={this.changePOIIcon}
                  changeCoordinates={this.selectAddress}
                  changeName={this.changeName}
                  changeNumber={this.changeNumber}
                  changePostcode={this.changePostcode}
                  changeBillingDefault={this.changeBillingDefault}
                  changeStreet={this.changeStreet}
                  changePhone={this.changePhone}
                  changeEmail={this.changeEmail}
                  showColorPicker={this.showColorPicker}
                  getAddressCoordinates={this.getAddressCoordinates}
                  changePlanable={this.changePlanable}
                  changeLon={this.changeLon}
                  changeLat={this.changeLat}
                />
              </Tab>
              <Tab
                eventKey={CLIENT_LOCATIONS_TAB_ID_OPENING_HOURS}
                title={CLIENT_LOCATIONS_TAB_OPENING_HOURS}
              >
                <ClientLocationModalOpeningHours
                  ref={this.clientLocationOpeningHoursRef}
                  clientLocation={clientLocation}
                />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" form="client-location-form">
            {BUTTON_APPLY}
          </Button>
          <Button onClick={this.onHide}>{BUTTON_ABORT}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  show: state.ui.modals.clientLocation.show,
  clientLocation: state.ui.modals.clientLocation.clientLocation,
  client: state.entities.clients.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ClientLocationsAction | ClientLocationModalAction>
) => ({
  clientLocationCreate: (client: Client, clientLocation: ClientLocation) =>
    dispatch(createClientLocation(client, clientLocation)),
  clientLocationUpdate: (client: Client, clientLocation: ClientLocation) =>
    dispatch(updateClientLocation(client, clientLocation)),
  hideModal: (payload: string) => dispatch(hideClientLocationModal(payload))
});

const ClientLocationModalContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientLocationModal)
);

export default ClientLocationModalContainer;
