export const SERVICE_SECURITY = 'securityService/';
export const SERVICE_GEO_DATA = 'geoDataService/';
export const SERVICE_ADMINISTRATION = 'administrationService/';

export const PATH_LOGIN = `${SERVICE_ADMINISTRATION}login`;
export const PATH_CHANGE_PASSWORD = `${SERVICE_SECURITY}changePassword`;
export const PATH_USERCLIENTS = `${SERVICE_SECURITY}userclients`;

export const PATH_ALL_CLIENTS = `${SERVICE_ADMINISTRATION}clients`;
export const PATH_GET_CLIENT = (clientId: number) => `${SERVICE_ADMINISTRATION}client/${clientId}`;
export const PATH_CREATE_CLIENT = `${SERVICE_ADMINISTRATION}client/`;
export const PATH_UPDATE_CLIENT = `${SERVICE_ADMINISTRATION}client/`;
export const PATH_DELETE_CLIENT = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}`;

export const PATH_GET_CLIENT_USERS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/users`;
export const PATH_ADD_CLIENT_USER = (clientId: number, userId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/user/${userId}`;
export const PATH_DELETE_CLIENT_USER = (clientId: number, userId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/user/${userId}`;

export const PATH_GET_CLIENT_OFFER_ORDER = (clientId: number, order: boolean) =>
  `${SERVICE_GEO_DATA}client/${clientId}/${order ? 'offers' : 'orders'}`;
export const PATH_GET_CLIENT_OFFER_ORDER_ITEM = (
  clientId: number,
  order: boolean,
  offerOrderId: number
) => `${SERVICE_GEO_DATA}client/${clientId}/${order ? 'offer' : 'order'}/${offerOrderId}`;

export const PATH_GET_CLIENT_LAYERS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/arealayers`;
export const PATH_ADD_CLIENT_LAYER = (clientId: number, layerId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/arealayer/${layerId}`;
export const PATH_DELETE_CLIENT_LAYER = (clientId: number, layerId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/arealayer/${layerId}`;

export const PATH_ALL_PRODUCTS = `${SERVICE_ADMINISTRATION}products`;
export const PATH_GET_PRODUCT = (productId: number) =>
  `${SERVICE_ADMINISTRATION}product/${productId}`;
export const PATH_CREATE_PRODUCT = `${SERVICE_ADMINISTRATION}product/`;
export const PATH_UPDATE_PRODUCT = `${SERVICE_ADMINISTRATION}product/`;
export const PATH_DELETE_PRODUCT = (productId: number) =>
  `${SERVICE_ADMINISTRATION}product/${productId}`;

export const PATH_GET_CLIENT_PRODUCTS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/products`;
export const PATH_ADD_CLIENT_PRODUCT = (clientId: number, productId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/product/${productId}`;
export const PATH_DELETE_CLIENT_PRODUCT = (clientId: number, productId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/product/${productId}`;

export const PATH_GET_CLIENT_ADDITIONAL_OPTIONS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/additionalOptions`;
export const PATH_UPDATE_CLIENT_ADDITIONAL_OPTIONS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/additionalOption`;

export const PATH_CLIENTLOCATIONS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/locations`;
export const PATH_GET_CLIENTLOCATION = (clientId: number, clientLocationsId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/location/${clientLocationsId}`;
export const PATH_CREATE_CLIENTLOCATION = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/location`;
export const PATH_UPDATE_CLIENTLOCATION = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/location`;
export const PATH_DELETE_CLIENTLOCATION = (clientId: number, clientLocationsId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/location/${clientLocationsId}`;
export const PATH_DELETE_MULTIPLE_CLIENTLOCATIONS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/locations/`;
export const PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/locations/import`;
export const PATH_GET_POIS = `${SERVICE_ADMINISTRATION}pois`;

export const PATH_ALL_USERS = `${SERVICE_ADMINISTRATION}users`;
export const PATH_GET_USER = (userId: number) => `${SERVICE_ADMINISTRATION}user/${userId}`;
export const PATH_CREATE_USER = `${SERVICE_ADMINISTRATION}user/`;
export const PATH_UPDATE_USER = `${SERVICE_ADMINISTRATION}user/`;
export const PATH_DELETE_USER = (userId: number) => `${SERVICE_ADMINISTRATION}user/${userId}`;
export const PATH_USER_INVITE = (userId: number) =>
  `${SERVICE_ADMINISTRATION}user/${userId}/invite`;
export const PATH_USER_UPDATE_PASSWORD = (userId: number) =>
  `${SERVICE_ADMINISTRATION}user/${userId}/updatePassword`;

export const PATH_ALL_LAYERS = `${SERVICE_ADMINISTRATION}arealayers`;
export const PATH_GET_LAYER = (layerId: number) => `${SERVICE_ADMINISTRATION}arealayer/${layerId}`;

export const PATH_CLIENT_DISTRIBUTION_APPOINTMENTS = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/distributionAppointments`;
export const PATH_GET_CLIENT_DISTRIBUTION_APPOINTMENT = (
  clientId: number,
  distributionAppointmentssId: number
) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/distributionAppointment/${distributionAppointmentssId}`;
export const PATH_CREATE_DISTRIBUTION_APPOINTMENT = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/distributionAppointment`;
export const PATH_UPDATE_DISTRIBUTION_APPOINTMENT = (clientId: number) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/distributionAppointment`;
export const PATH_DELETE_DISTRIBUTION_APPOINTMENT = (
  clientId: number,
  distributionAppointmentssId: number
) =>
  `${SERVICE_ADMINISTRATION}client/${clientId}/distributionAppointment/${distributionAppointmentssId}`;
