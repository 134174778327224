import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchClientLayersAction,
  addClientLayerAction,
  deleteClientLayerAction
} from '../../actions/client/clientLayerActions';

import { parseLayers } from '../responseUtil/layerResponseUtil';

import { axiosClient, headers } from '../../constants/constants';
import {
  PATH_GET_CLIENT_LAYERS,
  PATH_ADD_CLIENT_LAYER,
  PATH_DELETE_CLIENT_LAYER
} from '../../constants/network';
import {
  FETCH_CLIENT_LAYERS,
  ADD_CLIENT_LAYER,
  DELETE_CLIENT_LAYER
} from '../../constants/actionNames/clients/clientActions';
import {
  fetchClientLayersErrorTitle,
  fetchClientLayersErrorContent,
  addClientLayerErrorTitle,
  addClientLayerErrorContent,
  deleteClientLayerErrorTitle,
  deleteClientLayerErrorContent
} from '../../constants/errorMessages';

import { Layer, Client } from '../../@types/Common.d';

export const getClientLayers = (client: Client) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_CLIENT_LAYERS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_LAYERS(client.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_LAYERS));
    return dispatch(fetchClientLayersAction(parseLayers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_LAYERS, {
      title: fetchClientLayersErrorTitle,
      content: fetchClientLayersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const addClientLayer = (client: Client, layer: Layer) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(ADD_CLIENT_LAYER));

  const res = await axiosClient
    .put(PATH_ADD_CLIENT_LAYER(client.id, layer.id), {}, { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(ADD_CLIENT_LAYER));
    return dispatch(addClientLayerAction(layer));
  }

  return dispatch(
    requestFailure(ADD_CLIENT_LAYER, {
      title: addClientLayerErrorTitle,
      content: addClientLayerErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientLayer = (client: Client, layer: Layer) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(DELETE_CLIENT_LAYER));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT_LAYER(client.id, layer.id), { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_LAYER));
    return dispatch(deleteClientLayerAction(layer));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_LAYER, {
      title: deleteClientLayerErrorTitle,
      content: deleteClientLayerErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
