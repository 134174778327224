import * as React from 'react';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Col, Row } from 'react-bootstrap';
import { map } from 'lodash';
import { withRouter } from 'react-router-dom';

import ListSearch from '../../../Common/ListSearch';
import ClientLocationTable from './ClientLocationTable';

import { showClientLocationModal, showLoading, hideLoading } from '../../../../actions/uiActions';

import {
  getClientLocations,
  deleteClientLocation,
  importClientLocationFile,
  deleteMultipleClientLocations,
  updateClientLocation
} from '../../../../util/api/clientLocationApi';
import { exportClientLocationsCSV } from '../../../../util/utils';

import {
  BUTTON_ADD,
  BUTTON_DOWNLOAD_MODEL,
  LIST_SEARCH_HINT_CLIENT_LOCATIONS,
  BUTTON_IMPORT,
  BUTTON_EXPORT_CSV,
  BUTTON_DELETE_SELECTION
} from '../../../../constants/labels';

import {
  ClientsDetailsClientLocationsProps,
  ClientsDetailsClientLocationsState
} from '../../../../@types/Clients.d';
import { ClientLocationsAction } from '../../../../@types/Actions/Client/ClientLocation.d';
import { RequestAction, ClientLocationModalAction } from '../../../../@types/Actions/UI.d';
import { GlobalState, Client, ClientLocation } from '../../../../@types/Common.d';

const clientLocationModelFile = require('../../../../resources/files/import_template.xlsx');

class ClientsDetailsClientLocations extends React.Component<
  ClientsDetailsClientLocationsProps,
  ClientsDetailsClientLocationsState
> {
  fileInput = React.createRef<HTMLInputElement>();

  constructor(props: ClientsDetailsClientLocationsProps) {
    super(props);

    const { client } = this.props;

    const filteredClientLocations = client?.clientLocations.allIds.map(
      (id: number) => client?.clientLocations.byId[id]
    ) as ClientLocation[];

    this.state = {
      filteredClientLocations,
      filterString: '',
      multiSelect: []
    };

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onClickExportClientLocations = this.onClickExportClientLocations.bind(this);
    this.onClickImport = this.onClickImport.bind(this);

    this.filterList = this.filterList.bind(this);

    this.addToMultiSelect = this.addToMultiSelect.bind(this);
    this.addAllToMultiSelect = this.addAllToMultiSelect.bind(this);
    this.removeFromMultiSelect = this.removeFromMultiSelect.bind(this);
    this.removeAllFromMultiSelect = this.removeAllFromMultiSelect.bind(this);
    this.changeBillingDefault = this.changeBillingDefault.bind(this);

    this.deleteMultiple = this.deleteMultiple.bind(this);
  }

  componentDidMount() {
    const { client, clientLocationsFetch } = this.props;

    if (client) clientLocationsFetch(client);
  }

  componentDidUpdate(prevProps: ClientsDetailsClientLocationsProps) {
    const { client, clientLocationsFetch } = this.props;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) clientLocationsFetch(client);
    if (
      JSON.stringify(client?.clientLocations.allIds) !==
      JSON.stringify(prevProps.client?.clientLocations.allIds)
    ) {
      const filteredClientLocations = client?.clientLocations.allIds.map(
        (id: number) => client?.clientLocations.byId[id]
      ) as ClientLocation[];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filteredClientLocations,
        filterString: ''
      });
    }
  }

  onClickAdd(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const { clientLocationModalShow } = this.props;
    clientLocationModalShow();
  }

  onClickExportClientLocations(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const { client } = this.props;
    if (client?.clientLocations) {
      const { clientLocations } = client;
      exportClientLocationsCSV(
        clientLocations.allIds.map(id => clientLocations.byId[id]),
        client.name
      );
    }
  }

  onClickImport() {
    const { current } = this.fileInput;

    if (current === null) return;

    current.click();
  }

  onFileChange(file: File | null) {
    const { loadingShow, loadingHide, clientLocationsImport, client } = this.props;
    const fileReader = new FileReader();

    if (file === null) return;

    loadingShow('show');

    const clientLocationsFile = file;

    fileReader.addEventListener('error', () => {
      loadingHide('hide');
    });

    fileReader.addEventListener('load', () => {
      loadingHide('hide');
      clientLocationsImport(client, fileReader.result as string);

      const { current } = this.fileInput;
      if (current !== null) current.value = '';
    });

    fileReader.readAsDataURL(clientLocationsFile);
  }

  deleteMultiple(event: React.MouseEvent) {
    const { client, clientLocationDeleteMultiple } = this.props;
    const { multiSelect } = this.state;

    event.preventDefault();
    event.stopPropagation();

    clientLocationDeleteMultiple(client, multiSelect);

    this.setState({ multiSelect: [] });
  }

  addToMultiSelect(id: number) {
    const { multiSelect } = this.state;

    this.setState({ multiSelect: [...multiSelect, ...[id]] });
  }

  removeFromMultiSelect(id: number) {
    const { multiSelect } = this.state;

    const index = multiSelect.indexOf(id);

    if (id >= 0)
      this.setState({
        multiSelect: [...multiSelect.slice(0, index), ...multiSelect.slice(index + 1)]
      });
  }

  addAllToMultiSelect() {
    const { client } = this.props;

    if (client) {
      this.setState({ multiSelect: client.clientLocations.allIds });
    }
  }

  removeAllFromMultiSelect() {
    this.setState({ multiSelect: [] });
  }

  filterList(pFilterString: string) {
    this.setState(
      {
        filterString: pFilterString
      },
      () => {
        const { client } = this.props;
        const { filterString } = this.state;

        if (!client) return;

        const clientLocations = client?.clientLocations.allIds.map(
          (id: number) => client?.clientLocations.byId[id]
        );

        let filteredClientLocations;
        if (filterString.length <= 0) filteredClientLocations = clientLocations;
        else {
          const searchStringRegex = map(filterString.split(''), s => `[${s}]\\w*`).join('');
          const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
          filteredClientLocations = clientLocations.filter(
            ({ addressName, name }) => addressName.match(regex) || name.match(regex)
          );
        }

        this.setState({ filteredClientLocations });
      }
    );
  }

  changeBillingDefault(clientLocation: ClientLocation) {
    const { client, clientLocationUpdate } = this.props;

    if (!client) return;

    const { clientLocations } = client;

    const selectedClientLocations = clientLocations.allIds
      .map((id: number) => clientLocations.byId[id])
      .filter(location => location.billingDefault);

    selectedClientLocations.forEach(location =>
      clientLocationUpdate(client, { ...location, ...{ billingDefault: false } })
    );
    clientLocationUpdate(client, { ...clientLocation, ...{ billingDefault: true } });
  }

  render() {
    const { client } = this.props;
    const { filteredClientLocations, filterString, multiSelect } = this.state;

    return (
      <Row className="no-gutters h-100">
        <Col className="p-0 client-location-table-parent-container">
          <Row className="no-gutters">
            <Col sm={12} xl={6}>
              <Row className="no-gutters">
                <Col className="p-1">
                  <Button onClick={this.onClickAdd} variant="success" block>
                    {BUTTON_ADD}
                  </Button>
                </Col>
                <Col className="p-1">
                  <Button variant="success" onClick={this.onClickImport} block>
                    {BUTTON_IMPORT}
                  </Button>
                  <input
                    ref={this.fileInput}
                    type="file"
                    id="client-location-import-file"
                    onChange={event => {
                      if (event.target.files !== null) this.onFileChange(event.target.files[0]);
                      event.stopPropagation();
                    }}
                    hidden
                  />
                </Col>
                <Col className="p-1">
                  <Button
                    as="a"
                    href={clientLocationModelFile}
                    download={`${client?.name ?? 'Filial'}_import_beispiel.xlsx`.replace(/ /g, '_')}
                    block
                  >
                    {BUTTON_DOWNLOAD_MODEL}
                  </Button>
                </Col>
                <Col className="p-1">
                  <Button variant="warning" onClick={this.onClickExportClientLocations} block>
                    {BUTTON_EXPORT_CSV}
                  </Button>
                </Col>
                <Col className="p-1">
                  <ListSearch
                    searchHint={LIST_SEARCH_HINT_CLIENT_LOCATIONS}
                    filterList={this.filterList}
                    searchString={filterString}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="no-gutters client-location-table-container">
            <Col className="h-100 overflow-auto">
              {client?.clientLocations && (
                <ClientLocationTable
                  client={client}
                  clientLocations={filteredClientLocations}
                  multiSelect={multiSelect}
                  changeBillingDefault={this.changeBillingDefault}
                  addToMultiSelect={this.addToMultiSelect}
                  removeFromMultiSelect={this.removeFromMultiSelect}
                  addAllToMultiSelect={this.addAllToMultiSelect}
                  removeAllFromMultiSelect={this.removeAllFromMultiSelect}
                />
              )}
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col sm={12} xl={6}>
              {multiSelect.length > 0 && (
                <Col sm={6} className="p-1">
                  <Button variant="danger" onClick={this.deleteMultiple} block>
                    {BUTTON_DELETE_SELECTION}
                  </Button>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    GlobalState,
    void,
    RequestAction | ClientLocationsAction | ClientLocationModalAction
  >
) => ({
  loadingShow: (payload: string) => dispatch(showLoading(payload)),
  loadingHide: (payload: string) => dispatch(hideLoading(payload)),
  clientLocationsImport: (client: Client, clientLocationsFile: string) =>
    dispatch(importClientLocationFile(client, clientLocationsFile)),
  clientLocationsFetch: (client: Client) => dispatch(getClientLocations(client)),
  clientLocationDelete: (client: Client, clientLocation: ClientLocation) =>
    dispatch(deleteClientLocation(client, clientLocation)),
  clientLocationDeleteMultiple: (client: Client, clientLocationIds: number[]) =>
    dispatch(deleteMultipleClientLocations(client, clientLocationIds)),
  clientLocationModalShow: () => dispatch(showClientLocationModal(undefined)),
  clientLocationUpdate: (client: Client, clientLocation: ClientLocation) =>
    dispatch(updateClientLocation(client, clientLocation))
});

const ClientsDetailsClientLocationsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsClientLocations)
);
export default ClientsDetailsClientLocationsContainer;
