import * as React from 'react';

import { Col, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import {
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_RANGE,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_DURATION,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_BUDGET,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_TARGET_GROUP,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_RANGE,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_DURATION,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_BUDGET,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_TARGET_GROUP
} from '../../../../../constants/labels';
import { selectPickerTheme } from '../../../../../constants/constants';

import { AdditionalOptionsListItemSocialMediaPresetProps } from '../../../../../@types/Clients.d';
import { SocialMediaTargetGroup } from '../../../../../@types/Common.d';

const AdditionalOptionItemSocialMediaPreset: React.FC<AdditionalOptionsListItemSocialMediaPresetProps> = (
  props: AdditionalOptionsListItemSocialMediaPresetProps
) => {
  const { additionalOption, avaiableTargetGroups, changeAdditionalOption } = props;

  const {
    presetSelectionDurations,
    presetSelectionRanges,
    presetSelectionBudgets,
    presetValuesDurations,
    presetValuesRanges,
    presetValuesBudgets,
    presetSelectionTargetGroups
  } = additionalOption;

  const onChangeRange = (ranges: any) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionRanges: (ranges?.map(({ value }: any) => +value) ?? ([] as number[])).sort()
      }
    });
  };

  const onCreateRange = (range: any) => {
    if (Number.isNaN(+range)) return;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetValuesRanges: ([...presetValuesRanges, +range] as number[]).sort(),
        presetSelectionRanges: ([...presetSelectionRanges, +range] as number[]).sort()
      }
    });
  };

  const onChangeDuration = (durations: any) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionDurations: (
          durations?.map(({ value }: any) => +value) ?? ([] as number[])
        ).sort()
      }
    });
  };

  const onCreateDuration = (duration: any) => {
    if (Number.isNaN(+duration)) return;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetValuesDurations: ([...presetValuesDurations, +duration] as number[]).sort(),
        presetSelectionDurations: ([...presetSelectionDurations, +duration] as number[]).sort()
      }
    });
  };

  const onChangeBudgets = (budgets: any) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionBudgets: (
          budgets?.map(({ value }: any) => +value) ?? ([] as number[])
        ).sort()
      }
    });
  };

  const onCreateBudgets = (budget: any) => {
    if (Number.isNaN(+budget)) return;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetValuesBudgets: ([...presetValuesBudgets, +budget] as number[]).sort(),
        presetSelectionBudgets: ([...presetSelectionBudgets, +budget] as number[]).sort()
      }
    });
  };

  const onChangeTargetGroups = (targetGroups: any) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionTargetGroups: (
          targetGroups?.map(({ data }: any) => data as SocialMediaTargetGroup) ??
          ([] as SocialMediaTargetGroup[])
        ).sort()
      }
    });
  };

  const durations = presetValuesDurations.map(dur => ({ value: dur, label: dur }));
  const ranges = presetValuesRanges.map(range => ({ value: range, label: range }));
  const budgets = presetValuesBudgets.map(budget => ({ value: budget, label: budget }));
  const groups = avaiableTargetGroups.map(group => ({
    value: group.id,
    label: group.name,
    data: group
  }));

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE}</Form.Label>
            <CreatableSelect
              closeMenuOnSelect={false}
              isMulti
              isClearable
              menuPosition="absolute"
              isSearchable
              value={ranges.filter(r => presetSelectionRanges.find(sr => sr === r.value))}
              options={ranges}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_RANGE}
              onChange={onChangeRange}
              onCreateOption={onCreateRange}
              theme={(theme: any) => selectPickerTheme(theme)}
              menuPortalTarget={document.body}
            />
            <Form.Control
              value={presetSelectionRanges.join(',')}
              hidden
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              {CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_RANGE}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION}</Form.Label>
            <CreatableSelect
              closeMenuOnSelect={false}
              isMulti
              isClearable
              menuPosition="absolute"
              isSearchable
              value={durations.filter(d => presetSelectionDurations.find(dr => dr === d.value))}
              options={durations}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_DURATION}
              onChange={onChangeDuration}
              onCreateOption={onCreateDuration}
              theme={(theme: any) => selectPickerTheme(theme)}
              menuPortalTarget={document.body}
            />
          </Form.Group>
          <Form.Control
            value={presetSelectionDurations.join(',')}
            hidden
            onChange={() => {}}
            required
          />
          <Form.Control.Feedback type="invalid">
            {CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_DURATION}
          </Form.Control.Feedback>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET}</Form.Label>
            <CreatableSelect
              closeMenuOnSelect={false}
              isMulti
              isClearable
              menuPosition="absolute"
              isSearchable
              value={budgets.filter(b => presetSelectionBudgets.find(bu => bu === b.value))}
              options={budgets}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_BUDGET}
              onChange={onChangeBudgets}
              onCreateOption={onCreateBudgets}
              theme={(theme: any) => selectPickerTheme(theme)}
              menuPortalTarget={document.body}
            />
            <Form.Control
              value={presetSelectionBudgets.join(',')}
              hidden
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              {CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_BUDGET}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP}</Form.Label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              isClearable
              menuPosition="absolute"
              isSearchable
              value={groups.filter(g => presetSelectionTargetGroups.find(tg => tg.id === g.value))}
              options={groups}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_TARGET_GROUP}
              onChange={onChangeTargetGroups}
              theme={(theme: any) => selectPickerTheme(theme)}
              menuPortalTarget={document.body}
            />
            <Form.Control
              value={presetSelectionTargetGroups.join(',')}
              hidden
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              {CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_INVALID_TARGET_GROUP}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default AdditionalOptionItemSocialMediaPreset;
