import {
  FETCH_ALL_CLIENT_MODULES,
  UPDATE_CLIENT_MODULE
} from '../../constants/actionNames/clients/clientActions';

import {
  FetchClientModulesAction,
  UpdateClientModuleAction
} from '../../@types/Actions/Client/ClientModules.d';
import { Module } from '../../@types/Common.d';

export const fetchClientModulesAction = (modules: Module[]): FetchClientModulesAction => ({
  type: FETCH_ALL_CLIENT_MODULES,
  modules
});

export const updateClientModuleAction = (module: Module): UpdateClientModuleAction => ({
  type: UPDATE_CLIENT_MODULE,
  module
});
