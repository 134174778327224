import initialState from '../../../constants/initialState';
import {
  HIDE_CLIENT_LOCATION_MODAL,
  SHOW_CLIENT_LOCATION_MODAL
} from '../../../constants/actionNames/ui';

import { Action } from '../../../@types/Common.d';
import { ShowClientLocationModal } from '../../../@types/Actions/UI.d';

export default function clientLocationModalReducer(
  state = initialState.ui.modals.clientLocation,
  action: Action
) {
  let payload;

  switch (action.type) {
    case SHOW_CLIENT_LOCATION_MODAL:
      payload = (action as ShowClientLocationModal).clientLocation;

      return {
        ...state,
        ...{
          show: true,
          clientLocation: payload
        }
      };
    case HIDE_CLIENT_LOCATION_MODAL:
      return { ...state, ...{ show: false, clientLocation: undefined } };
    default:
      return state;
  }
}
