import * as React from 'react';

import { Form, FormControl, Col } from 'react-bootstrap';
import Select from 'react-select';

import {
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_RANGE,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_DURATION,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_BUDGET,
  CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_TARGET_GROUP
} from '../../../../../constants/labels';
import { selectPickerTheme } from '../../../../../constants/constants';

import { AdditionalOptionsListItemSocialMediaDefaultProps } from '../../../../../@types/Clients.d';
import { SocialMediaTargetGroup } from '../../../../../@types/Common.d';

const AdditionalOptionItemSocialMediaDefault: React.FC<AdditionalOptionsListItemSocialMediaDefaultProps> = (
  props: AdditionalOptionsListItemSocialMediaDefaultProps
) => {
  const { additionalOption, avaiableTargetGroups, changeAdditionalOption } = props;
  const { defaultBudget, defaultDuration, defaultRange, defaultTargetGroups } = additionalOption;

  const onChangeRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultRange: +event.currentTarget.value }
    });
  };

  const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultDuration: +event.currentTarget.value }
    });
  };

  const onChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultBudget: +event.currentTarget.value }
    });
  };

  const onChangeTargetGroup = (values: any) => {
    changeAdditionalOption({
      ...additionalOption,
      ...{ defaultTargetGroups: values?.map(({ data }: any) => data) as SocialMediaTargetGroup[] }
    });
  };

  const options = avaiableTargetGroups.map(elem => ({
    value: elem.id,
    label: elem.name,
    data: elem
  }));

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_RANGE}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="number"
              value={defaultRange}
              onChange={onChangeRange}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_RANGE}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_DURATION}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="number"
              value={defaultDuration}
              onChange={onChangeDuration}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_DURATION}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_BUDGET}</Form.Label>
            <FormControl
              as="input"
              autoComplete="new-password"
              type="number"
              value={defaultBudget}
              onChange={onChangeBudget}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_BUDGET}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_TITLE_TARGET_GROUP}</Form.Label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              menuPosition="absolute"
              isSearchable
              value={options.filter(option =>
                defaultTargetGroups?.find(elem => elem.id === option.value)
              )}
              options={options}
              placeholder={CLIENT_ADDITIONAL_OPTION_SOCIAL_MEDIA_PLACEHOLDER_TARGET_GROUP}
              onChange={onChangeTargetGroup}
              theme={(theme: any) => selectPickerTheme(theme)}
              menuPortalTarget={document.body}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default AdditionalOptionItemSocialMediaDefault;
