import * as React from 'react';

import { Row, Form, Col, Button, Spinner, ListGroup, Tabs, Tab } from 'react-bootstrap';
import { map, find } from 'lodash';
import md5 from 'md5';
import Select from 'react-select';

import ColorPicker from './ColorPicker';

import {
  CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_TITLE,
  CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_INVALID,
  CLIENT_LOCATIONS_MODAL_CITY_TITLE,
  CLIENT_LOCATIONS_MODAL_CITY_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_CITY_INVALID,
  CLIENT_LOCATIONS_MODAL_HOUSENUMBER_TITLE,
  CLIENT_LOCATIONS_MODAL_HOUSENUMBER_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_HOUSENUMBER_INVALID,
  CLIENT_LOCATIONS_MODAL_NAME_TITLE,
  CLIENT_LOCATIONS_MODAL_NAME_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_NAME_INVALID,
  CLIENT_LOCATIONS_MODAL_NUMBER_TITLE,
  CLIENT_LOCATIONS_MODAL_NUMBER_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_NUMBER_INVALID,
  CLIENT_LOCATIONS_MODAL_POSTCODE_TITLE,
  CLIENT_LOCATIONS_MODAL_POSTCODE_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_POSTCODE_INVALID,
  CLIENT_LOCATIONS_MODAL_STREET_TITLE,
  CLIENT_LOCATIONS_MODAL_STREET_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_STREET_INVALID,
  CLIENT_LOCATIONS_MODAL_COLOR,
  CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_TITLE,
  CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION,
  CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED,
  BUTTON_LOCATE,
  CLIENT_LOCATIONS_MODAL_POI_ICON_TITLE,
  CLIENT_LOCATIONS_MODAL_POI_ICON_INVALID,
  CLIENT_LOCATIONS_MODAL_POI_ICON_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_PLANABLE_LABEL,
  CLIENT_LOCATIONS_MODAL_PLANABLE_TITLE,
  CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_LABEL,
  CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_TITLE,
  CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_EMPTY,
  CLIENT_LOCATIONS_TAB_ADDRESS,
  CLIENT_LOCATIONS_TAB_COORD,
  CLIENT_LOCATIONS_MODAL_COORD_LON_TITLE,
  CLIENT_LOCATIONS_MODAL_COORD_LON_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_COORD_LON_INVALID,
  CLIENT_LOCATIONS_MODAL_COORD_LAT_TITLE,
  CLIENT_LOCATIONS_MODAL_COORD_LAT_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_COORD_LAT_INVALID,
  CLIENT_LOCATIONS_MODAL_EMAIL_TITLE,
  CLIENT_LOCATIONS_MODAL_EMAIL_PLACEHOLDER,
  CLIENT_LOCATIONS_MODAL_PHONE_TITLE,
  CLIENT_LOCATIONS_MODAL_PHONE_PLACEHOLDER
} from '../../../constants/labels';
import {
  selectPickerTheme,
  CLIENT_LOCATIONS_TAB_ID_ADDRESS,
  CLIENT_LOCATIONS_TAB_ID_COORD
} from '../../../constants/constants';

import { rgbTorgba, rgbTorgbObject } from '../../../util/utils';

import { ClientLocationModalFormProps } from '../../../@types/Modal.d';
import { AddressResult, POI } from '../../../@types/Common.d';

import { IconDropDownOption } from '../../../@types/App.d';

const poiIconSelectOption: React.FC<IconDropDownOption> = (props: IconDropDownOption) => {
  const { data, label } = props;
  return (
    <div className="icon-dropdown-option">
      <div>
        <img src={data.active} alt={`${label}`} />
      </div>
      <div>{`${label}`}</div>
    </div>
  );
};

const ClientLocationModalForm: React.FC<ClientLocationModalFormProps> = (
  props: ClientLocationModalFormProps
) => {
  const {
    billingType,
    addressName,
    city,
    colorSelectedFill,
    housenumber,
    // TODO img,
    lat,
    lon,
    name,
    number,
    email,
    phone,
    postcode,
    street,
    availablePOIIcons,
    poi,
    changeAddressName,
    changeCity,
    changeColorSelectedFill,
    changeEmail,
    changeHousenumber,
    changeCoordinates,
    changeName,
    changeNumber,
    changePhone,
    changePostcode,
    changeStreet,
    changeClientLocationPOIIcon,
    changePlanable,
    changeBillingDefault,
    changeLat,
    changeLon,
    showColorPicker,
    getAddressCoordinates,
    addressResult,
    loading,
    showPicker,
    billingDefault,
    planable
  } = props;

  const coordCheckValue = lat ? `${lat}` : '';

  const onClickShowColorPicker = () => {
    showColorPicker();
  };

  const onChangeAddressName = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAddressName(event.currentTarget.value ?? '');
  };

  const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeCity(event.currentTarget.value ?? '');
  };

  const onChangeHousenumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHousenumber(event.currentTarget.value ?? '');
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeName(event.currentTarget.value ?? '');
  };

  const onChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeNumber(event.currentTarget.value ?? '');
  };

  const onChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePostcode(event.currentTarget.value ?? '');
  };

  const onChangeStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeStreet(event.currentTarget.value ?? '');
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeEmail(event.currentTarget.value ?? '');
  };

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePhone(event.currentTarget.value ?? '');
  };

  const onClickLocate = (event: React.MouseEvent) => {
    getAddressCoordinates();
  };

  const onChangeClientPOIIcon = (selectedClietPOIIcon: any) => {
    if (selectedClietPOIIcon && selectedClietPOIIcon !== null)
      changeClientLocationPOIIcon(selectedClietPOIIcon.data);
  };

  const onChangePlanable = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePlanable(event.currentTarget.checked);
  };

  const onChangeBillingDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillingDefault(event.currentTarget.checked);
  };

  const onChangeLon = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeLon(event.currentTarget.value);
  };

  const onChangeLat = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeLat(event.currentTarget.value);
  };

  const poiIconOptions = availablePOIIcons.map((pPoiIcon: POI) => ({
    label: pPoiIcon?.name ?? '',
    value: pPoiIcon.id ?? '',
    data: pPoiIcon
  })) as IconDropDownOption[];

  return (
    <Row className="no-gutters">
      <Col>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="organization"
                type="text"
                value={addressName}
                onChange={onChangeAddressName}
                placeholder={CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {CLIENT_LOCATIONS_MODAL_ADDRESS_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_NUMBER_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="new-password"
                type="text"
                value={number}
                onChange={onChangeNumber}
                placeholder={CLIENT_LOCATIONS_MODAL_NUMBER_PLACEHOLDER}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENT_LOCATIONS_MODAL_NUMBER_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_NAME_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="new-password"
                type="text"
                value={name}
                onChange={onChangeName}
                placeholder={CLIENT_LOCATIONS_MODAL_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {CLIENT_LOCATIONS_MODAL_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_EMAIL_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="organization"
                type="email"
                value={email}
                onChange={onChangeEmail}
                placeholder={CLIENT_LOCATIONS_MODAL_EMAIL_PLACEHOLDER}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_PHONE_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="tel"
                type="text"
                value={phone}
                onChange={onChangePhone}
                placeholder={CLIENT_LOCATIONS_MODAL_PHONE_PLACEHOLDER}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_POI_ICON_TITLE}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable
                options={poiIconOptions}
                value={find(poiIconOptions, ({ value }) => value === poi.id) as IconDropDownOption}
                placeholder={CLIENT_LOCATIONS_MODAL_POI_ICON_PLACEHOLDER}
                onChange={onChangeClientPOIIcon}
                theme={(theme: any) => selectPickerTheme(theme)}
                formatOptionLabel={poiIconSelectOption}
              />
              <Form.Control value={poi.id ?? ''} hidden onChange={() => {}} />
              <Form.Control.Feedback type="invalid">
                {CLIENT_LOCATIONS_MODAL_POI_ICON_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="color">
              <Form.Label>{CLIENT_LOCATIONS_MODAL_COLOR}</Form.Label>
              <Form.Control
                as="div"
                className="color-picker-intput"
                style={{
                  background: rgbTorgba(colorSelectedFill, 1)
                }}
                onClick={onClickShowColorPicker}
              />
              {showPicker && (
                <ColorPicker
                  id="color-picker"
                  color={rgbTorgbObject(colorSelectedFill, 1)}
                  accept={changeColorSelectedFill}
                  abort={showColorPicker}
                />
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{CLIENT_LOCATIONS_MODAL_PLANABLE_TITLE}</Form.Label>
              <Form.Check
                custom
                type="checkbox"
                checked={planable}
                id="clientlocation-modal-planable-check"
                label={CLIENT_LOCATIONS_MODAL_PLANABLE_LABEL}
                onChange={onChangePlanable}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            {billingType !== 'CLIENT' && (
              <Form.Group>
                <Form.Label>{CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_TITLE}</Form.Label>
                <Form.Check
                  custom
                  type="checkbox"
                  checked={billingDefault}
                  id="clientlocation-modal-billing-default-check"
                  label={CLIENT_LOCATIONS_MODAL_BILLING_DEFAULT_LABEL}
                  onChange={onChangeBillingDefault}
                />
              </Form.Group>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Tabs
              defaultActiveKey={CLIENT_LOCATIONS_TAB_ID_ADDRESS}
              id="address-tabs"
              className="py-2"
              mountOnEnter
              unmountOnExit
            >
              <Tab eventKey={CLIENT_LOCATIONS_TAB_ID_ADDRESS} title={CLIENT_LOCATIONS_TAB_ADDRESS}>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_STREET_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="address-line1"
                        type="text"
                        value={street}
                        onChange={onChangeStreet}
                        placeholder={CLIENT_LOCATIONS_MODAL_STREET_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_STREET_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_HOUSENUMBER_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="address-line2"
                        type="text"
                        value={housenumber}
                        onChange={onChangeHousenumber}
                        placeholder={CLIENT_LOCATIONS_MODAL_HOUSENUMBER_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_HOUSENUMBER_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_POSTCODE_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="postal-code"
                        type="text"
                        value={postcode}
                        onChange={onChangePostcode}
                        placeholder={CLIENT_LOCATIONS_MODAL_POSTCODE_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_POSTCODE_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_CITY_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="address-level2"
                        type="text"
                        value={city}
                        onChange={onChangeCity}
                        placeholder={CLIENT_LOCATIONS_MODAL_CITY_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_CITY_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Tab>
              <Tab eventKey={CLIENT_LOCATIONS_TAB_ID_COORD} title={CLIENT_LOCATIONS_TAB_COORD}>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_COORD_LON_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="new-password"
                        type="number"
                        value={`${lon}`}
                        onChange={onChangeLon}
                        placeholder={CLIENT_LOCATIONS_MODAL_COORD_LON_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_COORD_LON_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_COORD_LAT_TITLE}</Form.Label>
                      <Form.Control
                        as="input"
                        autoComplete="new-password"
                        type="number"
                        value={`${lat}`}
                        onChange={onChangeLat}
                        placeholder={CLIENT_LOCATIONS_MODAL_COORD_LAT_PLACEHOLDER}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {CLIENT_LOCATIONS_MODAL_COORD_LAT_INVALID}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Tab>
            </Tabs>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Button onClick={onClickLocate}>
              {BUTTON_LOCATE}
              {loading && (
                <Spinner
                  as="span"
                  className="ml-1"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
        </Form.Row>
        <Form.Row className="mt-1">
          <Col>
            <Form.Group>
              {addressResult && (
                <div className="mt-1">
                  {addressResult.length > 0 ? (
                    <>
                      <Form.Label>{CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_TITLE}</Form.Label>
                      <ListGroup className="w-100">
                        {map(addressResult, (address: AddressResult) => (
                          <ListGroup.Item
                            key={md5(JSON.stringify(address.coordinates))}
                            onClick={() => changeCoordinates(address)}
                            active={address.selected}
                          >
                            {address.name}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </>
                  ) : (
                    <ListGroup.Item className="custom-list-item">
                      {CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_EMPTY}
                    </ListGroup.Item>
                  )}
                </div>
              )}
              <Form.Control value={coordCheckValue} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {addressResult
                  ? CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION
                  : CLIENT_LOCATIONS_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default ClientLocationModalForm;
