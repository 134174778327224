import { map } from 'lodash';

import { COUNTRY_CODE_MATCHER } from '../../constants/constants';

import { Layer } from '../../@types/Common.d';

export const parseLayer = (layerData: any) => {
  const { countryCode, description, id, state, type } = layerData;
  const title = `${COUNTRY_CODE_MATCHER[countryCode]} ${state}`;
  return {
    countryCode,
    description,
    id,
    state,
    type,
    title,
    sortProperty: title
  } as Layer;
};

export const parseLayers = (layersData: any) =>
  map(layersData, layerData => parseLayer(layerData)).sort((a: Layer, b: Layer) => {
    if (`${a.title}${a.title}` > `${b.title}${b.title}`) return 1;
    if (`${a.title}${a.title}` < `${b.title}${b.title}`) return -1;
    return 0;
  });
