import {
  FetchClientLocationAction,
  CreateClientLocationAction,
  UpdateClientLocationAction,
  DeleteClientLocationAction,
  SelectClientLocation,
  ImportClientLocationAction,
  DeleteMultipleClientLocationsAction
} from '../../@types/Actions/Client/ClientLocation.d';
import { ClientLocation } from '../../@types/Common.d';
import {
  FETCH_CLIENT_LOCATIONS,
  UPDATE_CLIENT_LOCATION,
  CREATE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION,
  SELECT_CLIENT_LOCATION,
  IMPORT_CLIENT_LOCATIONS,
  DELETE_MULTIPLE_CLIENT_LOCATIONS
} from '../../constants/actionNames/clients/clientLocationActions';

export const fetchClientLocationsAction = (
  clientLocations: ClientLocation[]
): FetchClientLocationAction => ({
  type: FETCH_CLIENT_LOCATIONS,
  clientLocations
});

export const createClientLocationAction = (
  clientLocation: ClientLocation
): CreateClientLocationAction => ({
  type: CREATE_CLIENT_LOCATION,
  clientLocation
});

export const updateClientLocationAction = (
  clientLocation: ClientLocation
): UpdateClientLocationAction => ({
  type: UPDATE_CLIENT_LOCATION,
  clientLocation
});

export const deleteClientLocationAction = (
  clientLocationId: number
): DeleteClientLocationAction => ({
  type: DELETE_CLIENT_LOCATION,
  clientLocationId
});

export const deleteMultipleClientLocationAction = (
  clientLocationIds: number[]
): DeleteMultipleClientLocationsAction => ({
  type: DELETE_MULTIPLE_CLIENT_LOCATIONS,
  clientLocationIds
});

export const selectClientLocation = (clientLocation: ClientLocation): SelectClientLocation => ({
  type: SELECT_CLIENT_LOCATION,
  clientLocation
});

export const importClientLocations = (
  clientLocations: ClientLocation[]
): ImportClientLocationAction => ({
  type: IMPORT_CLIENT_LOCATIONS,
  clientLocations
});
