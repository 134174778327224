import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchClientUsersAction,
  addClientUserAction,
  deleteClientUserAction
} from '../../actions/client/clientUserActions';

import { parseUsers } from '../responseUtil/userResponseUtil';

import { axiosClient, headers } from '../../constants/constants';
import {
  PATH_GET_CLIENT_USERS,
  PATH_ADD_CLIENT_USER,
  PATH_DELETE_CLIENT_USER
} from '../../constants/network';
import {
  fetchClientUsersErrorTitle,
  fetchClientUsersErrorContent,
  addClientUserErrorTitle,
  addClientUserErrorContent,
  deleteClientUserErrorTitle,
  deleteClientUserErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_CLIENT_USERS,
  ADD_CLIENT_USER,
  DELETE_CLIENT_USER
} from '../../constants/actionNames/clients/clientActions';

import { User, Client } from '../../@types/Common.d';

export const getClientUsers = (client: Client) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_CLIENT_USERS));

  const res = await axiosClient
    .get(PATH_GET_CLIENT_USERS(client.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_USERS));
    return dispatch(fetchClientUsersAction(parseUsers(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_USERS, {
      title: fetchClientUsersErrorTitle,
      content: fetchClientUsersErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const addClientUser = (client: Client, user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(ADD_CLIENT_USER));

  const res = await axiosClient
    .put(PATH_ADD_CLIENT_USER(client.id, user.id), {}, { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(ADD_CLIENT_USER));
    return dispatch(addClientUserAction(user));
  }

  return dispatch(
    requestFailure(ADD_CLIENT_USER, {
      title: addClientUserErrorTitle,
      content: addClientUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientUser = (client: Client, user: User) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(DELETE_CLIENT_USER));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENT_USER(client.id, user.id), { headers })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_USER));
    return dispatch(deleteClientUserAction(user));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_USER, {
      title: deleteClientUserErrorTitle,
      content: deleteClientUserErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
