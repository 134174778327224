import * as React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import AdditionalOptionList from './AdditionalOptionList';

import { getAllClientAdditionalItems } from '../../../../util/api/clientsApi';

import {
  ClientDetailAdditionalOptionsProps,
  ClientDetailAdditionalOptionsState
} from '../../../../@types/Clients.d';
import { GlobalState, Client } from '../../../../@types/Common.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ClientAdditionalOptionsAction } from '../../../../@types/Actions/Client/ClientAdditionalOptions.d';

export class ClientAdditionalOptions extends React.Component<
  ClientDetailAdditionalOptionsProps,
  ClientDetailAdditionalOptionsState
> {
  constructor(props: ClientDetailAdditionalOptionsProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { client, additionalOptionsFetchAll } = this.props;

    if (!client) return;

    additionalOptionsFetchAll(client);
  }

  componentDidUpdate(prevProps: ClientDetailAdditionalOptionsProps) {
    const { client, additionalOptionsFetchAll } = this.props;

    if (!client) return;

    if (client.id !== prevProps.client?.id) additionalOptionsFetchAll(client);
  }

  render() {
    const { client } = this.props;
    return (
      <div className="client-additional-options-container">
        <AdditionalOptionList
          additionalOptions={
            client?.additionalOptions.allIds.map(id => client.additionalOptions.byId[id]) ?? []
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ClientAdditionalOptionsAction>
) => ({
  additionalOptionsFetchAll: (client: Client) => dispatch(getAllClientAdditionalItems(client))
});

const ClientAdditionalOptionsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientAdditionalOptions)
);

export default ClientAdditionalOptionsContainer;
