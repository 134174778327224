import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import ProductDetailsForm from './ProductDetailsForm';

import {
  requestStarted,
  requestSuccess,
  requestFailure,
  showConfirmationModal
} from '../../../actions/uiActions';

import { parseProduct } from '../../../util/responseUtil/productResponseUtil';
import {
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct
} from '../../../util/api/productApi';

import {
  BUTTON_APPLY,
  BUTTON_CREATE,
  BUTTON_DELETE,
  DELETE_CONFIRMATION_MODAL_TITLE,
  DELETE_CONFIRMATION_MODAL_CONTENT
} from '../../../constants/labels';
import { fetchProductErrorTitle, fetchProductErrorContent } from '../../../constants/errorMessages';
import { FETCH_PRODUCT } from '../../../constants/actionNames/products';

import { ProductsDetailsProps, ProductsDetailsState } from '../../../@types/Products.d';
import { GlobalState, Product, ErrorMessage, ConfirmationMessage } from '../../../@types/Common.d';
import { ProductAction } from '../../../@types/Actions/Product.d';
import { UIAction } from '../../../@types/Actions/UI.d';

class ProductsDetails extends React.Component<ProductsDetailsProps, ProductsDetailsState> {
  constructor(props: ProductsDetailsProps) {
    super(props);

    const { product } = this.props;

    this.state = {
      validated: false,
      id: product?.id,
      name: product?.name ?? '',
      description: product?.description ?? '',
      printDocRequired: product?.printDocRequired ?? false
    };

    this.onSubmit = this.onSubmit.bind(this);

    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changePrintDocRequired = this.changePrintDocRequired.bind(this);

    this.fetchProduct = this.fetchProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.deleteProductCallback = this.deleteProductCallback.bind(this);
  }

  componentDidMount() {
    const { product } = this.props;

    this.fetchProduct(product?.id);
  }

  componentDidUpdate(prevProps: ProductsDetailsProps) {
    const { product } = this.props;
    if (prevProps.product?.id !== product?.id) {
      // eslint-disable-next-line
      this.setState({
        validated: false,
        id: product?.id,
        name: product?.name ?? '',
        description: product?.description ?? '',
        printDocRequired: product?.printDocRequired ?? false
      });

      this.fetchProduct(product.id);
    }
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { product, productUpdate, productCreate } = this.props;
        const { id, name, description, printDocRequired } = this.state;

        const productNew = {
          id,
          name,
          description,
          printDocRequired
        } as Product;

        if (product) {
          productUpdate(productNew);
        } else {
          productCreate(productNew);
        }
      }
    });
  }

  changeName(name: string) {
    this.setState({ name });
  }

  changeDescription(description: string) {
    this.setState({ description });
  }

  changePrintDocRequired(printDocRequired: boolean) {
    this.setState({ printDocRequired });
  }

  deleteProduct(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();

    const { showConfirmation } = this.props;

    showConfirmation({
      title: DELETE_CONFIRMATION_MODAL_TITLE,
      content: DELETE_CONFIRMATION_MODAL_CONTENT,
      confirmationCallback: this.deleteProductCallback
    });
  }

  deleteProductCallback() {
    const { productDelete, product } = this.props;

    productDelete(product);
  }

  async fetchProduct(productId: number | undefined) {
    if (!productId) return;

    const { reqStarted, reqSuccess, reqFailure } = this.props;
    reqStarted(FETCH_PRODUCT);
    const res = await getProduct(productId);

    if (res.status < 300) {
      const product = parseProduct(res.data);
      this.setState(
        {
          validated: false,
          id: product?.id,
          name: product?.name ?? '',
          description: product?.description ?? '',
          printDocRequired: product?.printDocRequired ?? false
        },
        () => reqSuccess(FETCH_PRODUCT)
      );
      return;
    }

    reqFailure(FETCH_PRODUCT, {
      title: fetchProductErrorTitle,
      content: fetchProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    });
  }

  render() {
    const { validated, name, description, printDocRequired } = this.state;
    const { product } = this.props;

    return (
      <Form
        id="product-detail-form"
        className="product-detail-form"
        onSubmit={this.onSubmit}
        noValidate
        validated={validated}
      >
        <ProductDetailsForm
          name={name}
          descritpion={description}
          printDocRequired={printDocRequired}
          changeName={this.changeName}
          changeDescription={this.changeDescription}
          changePrintDocRequired={this.changePrintDocRequired}
        />
        <Row className="no-gutters">
          <Col sm={12} xl={4}>
            <Row className="no-gutters">
              <Col sm={4} className="p-1">
                <Button type="submit" variant="success" block>
                  {product ? BUTTON_APPLY : BUTTON_CREATE}
                </Button>
              </Col>
              {product && (
                <Col sm={4} className="p-1">
                  <Button variant="danger" className="ml-1" block onClick={this.deleteProduct}>
                    {BUTTON_DELETE}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  product: state.entities.products.selectedItem
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, UIAction | ProductAction>
) => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  productUpdate: (product: Product) => dispatch(updateProduct(product)),
  productCreate: (product: Product) => dispatch(createProduct(product)),
  productDelete: (product: Product) => dispatch(deleteProduct(product)),
  showConfirmation: (confirmationMessage: ConfirmationMessage) =>
    dispatch(showConfirmationModal(confirmationMessage))
});

const ProductsDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductsDetails)
);
export default ProductsDetailsContainer;
