import * as React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import ProductsDetailsContainer from './ProductsDetails';

import {
  PRODUCTS_DETAILS_TAB_ID_PRODUCT,
  PRODUCTS_DETAILS_TAB_ID_PRODUCT_PRICING
} from '../../../constants/constants';
import {
  PRODUCTS_DETAILS_TAB_PRODUCT,
  PRODUCTS_DETAILS_TAB_PRODUCT_PRICING
} from '../../../constants/labels';

import { ProductsDetailsTabsProps } from '../../../@types/Products.d';

const ProductsDetailsTabs: React.FC<ProductsDetailsTabsProps> = () => {
  return (
    <Tabs
      defaultActiveKey={PRODUCTS_DETAILS_TAB_ID_PRODUCT}
      id="products-details-tabs"
      mountOnEnter
      unmountOnExit
    >
      <Tab
        eventKey={PRODUCTS_DETAILS_TAB_ID_PRODUCT}
        className="h-100"
        title={PRODUCTS_DETAILS_TAB_PRODUCT}
      >
        <div className="tab-container">
          <ProductsDetailsContainer />
        </div>
      </Tab>
      <Tab
        eventKey={PRODUCTS_DETAILS_TAB_ID_PRODUCT_PRICING}
        title={PRODUCTS_DETAILS_TAB_PRODUCT_PRICING}
        className="h-100"
      >
        <div className="tab-container">Preise</div>
      </Tab>
    </Tabs>
  );
};

export default ProductsDetailsTabs;
