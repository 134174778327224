import '../styles/App.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { ThunkDispatch } from 'redux-thunk';

import LoginContainer from './Login/LoginContainer';
import LoggedInContainer from './LoggedInContainer';
import LoadingOverlay from './LoadingOverlay';
import RequestErrorModalContainer from './Modal/RequestErrorModal';

import history from '../util/history';

import { loginSuccessAction, logoutSuccessAction } from '../actions/userActions';
import { COOKIE_USER } from '../constants/constants';
import { LOGIN } from '../constants/actionNames/user';

import { UserAction } from '../@types/Actions/User.d';
import { AppProps, AppState } from '../@types/App.d';
import { GlobalState, User } from '../@types/Common.d';

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { cookies, loginUserFromCookies } = this.props;

    if (cookies.get(COOKIE_USER)) loginUserFromCookies(LOGIN, cookies.get(COOKIE_USER));
  }

  render() {
    const { availableFunctionality, loggedIn, loading, logoutUser } = this.props;

    return (
      <div className="w-100 h-100">
        {loading && <LoadingOverlay />}
        <Router history={history}>
          <RequestErrorModalContainer />
          <Switch>
            {loggedIn ? (
              <Route
                path="/"
                render={() => (
                  <LoggedInContainer
                    availableFunctionality={availableFunctionality}
                    logout={logoutUser}
                  />
                )}
              />
            ) : (
              <Route path="/" component={LoginContainer} />
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  loggedIn: state.ui.basic.loggedIn,
  loading: state.ui.basic.loading,
  availableFunctionality: state.ui.basic.availableFunctionality
});

const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, UserAction>) => ({
  loginUserFromCookies: (payload: string, user: User) =>
    dispatch(loginSuccessAction(payload, user)),
  logoutUser: () => dispatch(logoutSuccessAction('logout')) // TODO
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(withCookies(App));
export default AppContainer;
