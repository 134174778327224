import * as React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import { USERS_DETAILS_TAB_ID_USER } from '../../../constants/constants';
import { USERS_DETAILS_TAB_USER } from '../../../constants/labels';

import { UsersDetailsTabsProps } from '../../../@types/Users.d';
import UsersDetailsUserContainer from './UsersDetailsUser';

const UsersDetailsTabs: React.FC<UsersDetailsTabsProps> = () => {
  return (
    <Tabs
      defaultActiveKey={USERS_DETAILS_TAB_ID_USER}
      id="users-details-tabs"
      mountOnEnter
      unmountOnExit
    >
      <Tab eventKey={USERS_DETAILS_TAB_ID_USER} className="h-100" title={USERS_DETAILS_TAB_USER}>
        <div className="tab-container">
          <UsersDetailsUserContainer />
        </div>
      </Tab>
    </Tabs>
  );
};

export default UsersDetailsTabs;
