import * as React from 'react';
import { Button, Col, Form } from 'react-bootstrap';

import { BUTTON_APPLY, CLIENT_ADDITIONAL_OPTION_PRESET_LABEL } from '../../../../constants/labels';

import {
  AdditionalOptionsListItemProps,
  AdditionalOptionsListItemState
} from '../../../../@types/Clients.d';

export default class AdditionalOptionItem extends React.Component<
  AdditionalOptionsListItemProps,
  AdditionalOptionsListItemState
> {
  constructor(props: AdditionalOptionsListItemProps) {
    super(props);

    this.state = { validated: false };

    this.onChangeEnabled = this.onChangeEnabled.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeEnabled(event: React.ChangeEvent<HTMLInputElement>) {
    const { changeAdditionalOptionEnabled, additionalOption } = this.props;

    changeAdditionalOptionEnabled({
      ...additionalOption,
      ...{ enabled: event.currentTarget.checked }
    });
  }

  onChangeMode(event: React.ChangeEvent<HTMLInputElement>) {
    const { changeAdditionalOption, additionalOption } = this.props;

    changeAdditionalOption({
      ...additionalOption,
      ...{ mode: event.currentTarget.checked ? 'PRESET' : 'DEFAULT' }
    });
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    const formValid = event.currentTarget.checkValidity();

    event.stopPropagation();
    event.preventDefault();

    this.setState({ validated: true }, () => {
      const { submitAdditionalOption, additionalOption } = this.props;
      if (formValid) submitAdditionalOption(additionalOption);
    });
  }

  render() {
    const {
      additionalOption,
      label,
      maxHeight,
      defaultValueComponent,
      presetComponent
    } = this.props;
    const { validated } = this.state;
    const { enabled, mode } = additionalOption;

    const formId = `additional-options-${additionalOption.id}-form`;

    return (
      <div className="client-additional-options-list-item">
        <div className="client-additional-option-header">
          <div className="switch">
            <Form.Check
              type="switch"
              id={`additional-option-${additionalOption?.id ?? -1}`}
              label=""
              onChange={this.onChangeEnabled}
              checked={enabled}
            />
          </div>
          <div className="subtitle">{label ?? ''}</div>
        </div>
        <div
          className={`client-additional-option-content collapse-content height ${
            enabled ? 'shown' : 'hidden'
          }`}
          style={{ maxHeight }}
        >
          <div className="client-additional-option-header preset">
            <div className="switch">
              <Form.Check
                type="switch"
                id={`additional-option-${additionalOption?.id ?? -1}-mode`}
                label=""
                onChange={this.onChangeMode}
                checked={mode === 'PRESET'}
              />
            </div>
            <div>{CLIENT_ADDITIONAL_OPTION_PRESET_LABEL}</div>
          </div>
          <Form id={formId} onSubmit={this.onSubmit} noValidate validated={validated}>
            {mode === 'DEFAULT'
              ? React.Children.map(defaultValueComponent, child => child)
              : React.Children.map(presetComponent, child => child)}
            <Form.Row>
              <Col>
                <Button type="submit" form={formId} variant="success">
                  {BUTTON_APPLY}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </div>
    );
  }
}
