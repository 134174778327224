import * as React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import ClientDetailsOfferOrderDetailsContainer from './ClientOfferOrderDetails/ClientDetailsOfferOrderDetails';
import ClientDetailsOfferOrderDistributionContainer from './ClientOfferOrderDistribution/ClientDetailsOfferOrderDistribution';

import {
  CLIENTS_OFFER_ORDER_TAB_ID_DATA,
  CLIENTS_OFFER_ORDER_TAB_ID_DISTRIBUTION,
  TRANSMISSIONTYPE_OFFER
} from '../../../../constants/constants';
import {
  CLIENTS_OFFER_ORDER_TAB_DATA,
  CLIENTS_OFFER_ORDER_TAB_DISTRIBUTION
} from '../../../../constants/labels';

import { ClientOfferOrderTabsProps } from '../../../../@types/Clients.d';

const ClientDetailsOfferOrderTabs: React.FC<ClientOfferOrderTabsProps> = (
  props: ClientOfferOrderTabsProps
) => {
  const { client } = props;

  return (
    <Tabs
      defaultActiveKey={CLIENTS_OFFER_ORDER_TAB_ID_DATA}
      id="clients-details-tabs"
      className="pl-2"
      mountOnEnter
      unmountOnExit
    >
      <Tab
        eventKey={CLIENTS_OFFER_ORDER_TAB_ID_DATA}
        className="h-100"
        title={CLIENTS_OFFER_ORDER_TAB_DATA(client?.transmissionType === TRANSMISSIONTYPE_OFFER)}
      >
        <div className="tab-container">
          <ClientDetailsOfferOrderDetailsContainer />
        </div>
      </Tab>
      <Tab
        eventKey={CLIENTS_OFFER_ORDER_TAB_ID_DISTRIBUTION}
        className="h-100"
        title={CLIENTS_OFFER_ORDER_TAB_DISTRIBUTION}
      >
        <div className="tab-container">
          <ClientDetailsOfferOrderDistributionContainer />
        </div>
      </Tab>
    </Tabs>
  );
};

export default ClientDetailsOfferOrderTabs;
