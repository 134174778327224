import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import { selectClientOfferOrderAction } from '../../../../actions/client/clientOfferOrderActions';

import SearchableList from '../../../Common/SearchableList/SearchableList';
import ClientDetailsOfferOrderTabs from './ClientDetailsOfferOrderTabs';

import { getClientOfferOrder } from '../../../../util/api/clientsApi';

import { TRANSMISSIONTYPE_OFFER } from '../../../../constants/constants';
import {
  CLIENT_OFFER_ORDER_EMPTY_LIST,
  LIST_SEARCH_HINT_OFFER_ORDER
} from '../../../../constants/labels';

import { GlobalState, Client, ListItem, Offer, Order } from '../../../../@types/Common.d';
import { ClientAction } from '../../../../@types/Actions/Client/Client.d';
import { ClientOfferOrderProps, ClientOfferOrderState } from '../../../../@types/Clients.d';

class ClientsDetailsOfferOrder extends React.Component<
  ClientOfferOrderProps,
  ClientOfferOrderState
> {
  constructor(props: ClientOfferOrderProps) {
    super(props);

    this.state = {};

    this.onClickOfferOrderItem = this.onClickOfferOrderItem.bind(this);
  }

  componentDidMount() {
    const { client, offerOrderFetchAll } = this.props;

    if (!client) return;

    offerOrderFetchAll(client);
  }

  componentDidUpdate(prevProps: ClientOfferOrderProps) {
    const { offerOrderFetchAll, client } = this.props;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) {
      offerOrderFetchAll(client);
    }
  }

  onClickOfferOrderItem(offerOrderId: number) {
    const { offerOrderSelect, client } = this.props;

    offerOrderSelect(client?.offerOrder.byId[offerOrderId]);
  }

  render() {
    const { client } = this.props;

    return (
      <Row className="no-gutters h-100">
        <Col xs={5} lg={3} className="custom-list-container">
          <SearchableList
            items={
              client?.offerOrder.allIds.map(offerOrderId => {
                const { id, actionName, distributionDay } = client?.offerOrder.byId[offerOrderId];
                return { id, title: actionName, subtitle: distributionDay } as ListItem;
              }) ?? []
            }
            selectedItem={
              client
                ? ({
                    id: client?.offerOrder.selectedItem?.id,
                    title: client?.offerOrder.selectedItem?.actionName,
                    subtitle: client?.offerOrder.selectedItem?.distributionDay
                  } as ListItem)
                : undefined
            }
            itemClick={this.onClickOfferOrderItem}
            emptyListString={CLIENT_OFFER_ORDER_EMPTY_LIST(
              client?.transmissionType === TRANSMISSIONTYPE_OFFER
            )}
            searchHint={LIST_SEARCH_HINT_OFFER_ORDER(
              client?.transmissionType === TRANSMISSIONTYPE_OFFER
            )}
          />
        </Col>
        {client?.offerOrder.selectedItem && (
          <Col xs={7} lg={9} className="custom-detail-container">
            <ClientDetailsOfferOrderTabs client={client} />
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem
});
const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, ClientAction>) => ({
  offerOrderFetchAll: (client: Client) =>
    dispatch(getClientOfferOrder(client, client.transmissionType === TRANSMISSIONTYPE_OFFER)),
  offerOrderSelect: (offerOrder: Offer | Order) =>
    dispatch(selectClientOfferOrderAction(offerOrder))
});

const ClientsDetailsOfferOrderContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsOfferOrder)
);
export default ClientsDetailsOfferOrderContainer;
