import * as React from 'react';

import { Form, Row, Col, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { find } from 'lodash';

import {
  USERS_DETAILS_FORM_USER_SECTION,
  USERS_DETAILS_EMAIL_LABEL,
  USERS_DETAILS_EMAIL_PLACEHOLDER,
  USERS_DETAILS_EMAIL_INVALID,
  USERS_DETAILS_PRENAME_LABEL,
  USERS_DETAILS_PRENAME_PLACEHOLDER,
  USERS_DETAILS_PRENAME_INVALID,
  USERS_DETAILS_LASTNAME_LABEL,
  USERS_DETAILS_LASTNAME_PLACEHOLDER,
  USERS_DETAILS_LASTNAME_INVALID,
  USERS_DETAILS_SALUTATION_LABEL,
  USERS_DETAILS_SALUTATION_PLACEHOLDER,
  USERS_DETAILS_SALUTATION_INVALID,
  USERS_DETAILS_ADMIN_LABEL,
  USERS_DETAILS_INTERNAL_LABEL
} from '../../../constants/labels';

import { UserDetailsUserFormProps } from '../../../@types/Users.d';
import { SALUTATION_ARRAY, selectPickerTheme } from '../../../constants/constants';

const UserDetailsUserForm: React.FC<UserDetailsUserFormProps> = (
  props: UserDetailsUserFormProps
) => {
  const {
    email,
    prename,
    lastname,
    salutation,
    internal,
    admin,
    changeEmail,
    changePrename,
    changeLastname,
    changeSalutation,
    changeAdmin,
    changeInternal
  } = props;

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeEmail(event.currentTarget.value ?? '');
  };

  const onChangePrename = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePrename(event.currentTarget.value ?? '');
  };

  const onChangeLastname = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeLastname(event.currentTarget.value ?? '');
  };

  const onChangeSalutation = (salutationSelection: any) => {
    if (salutationSelection && salutationSelection !== null)
      changeSalutation(salutationSelection.value);
  };

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeInternal(event.currentTarget.checked);
  };

  const onChangeAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeAdmin(event.currentTarget.checked);
  };

  return (
    <Row className="no-gutters">
      <Col sm={12} xl={4}>
        <Form.Row>
          <Col className="section-header">{USERS_DETAILS_FORM_USER_SECTION}</Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{USERS_DETAILS_SALUTATION_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={SALUTATION_ARRAY}
                value={find(SALUTATION_ARRAY, { value: salutation })}
                placeholder={USERS_DETAILS_SALUTATION_PLACEHOLDER}
                onChange={onChangeSalutation}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={salutation} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {USERS_DETAILS_SALUTATION_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{USERS_DETAILS_PRENAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={prename}
                onChange={onChangePrename}
                placeholder={USERS_DETAILS_PRENAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {USERS_DETAILS_PRENAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{USERS_DETAILS_LASTNAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={lastname}
                onChange={onChangeLastname}
                placeholder={USERS_DETAILS_LASTNAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {USERS_DETAILS_LASTNAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{USERS_DETAILS_EMAIL_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                value={email}
                onChange={onChangeEmail}
                placeholder={USERS_DETAILS_EMAIL_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {USERS_DETAILS_EMAIL_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                checked={internal}
                id="internal-check"
                label={USERS_DETAILS_INTERNAL_LABEL}
                onChange={onChangeInternal}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                checked={admin}
                id="admin-check"
                label={USERS_DETAILS_ADMIN_LABEL}
                onChange={onChangeAdmin}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default UserDetailsUserForm;
