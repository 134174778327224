import { getIndicies, getDict, insertSorted, insertSortedArray } from '../../util/utils';

import initialState from '../../constants/initialState';
import {
  FETCH_ALL_CLIENTS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SELECT_CLIENT
} from '../../constants/actionNames/clients/clients';
import {
  FETCH_ALL_CLIENT_OFFER_ORDER,
  SELECT_CLIENT_OFFER_ORDER
} from '../../constants/actionNames/clients/clientOfferOrderActions';
import {
  FETCH_CLIENT_LOCATIONS,
  CREATE_CLIENT_LOCATION,
  UPDATE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION,
  SELECT_CLIENT_LOCATION,
  IMPORT_CLIENT_LOCATIONS,
  DELETE_MULTIPLE_CLIENT_LOCATIONS
} from '../../constants/actionNames/clients/clientLocationActions';
import {
  FETCH_CLIENT_USERS,
  ADD_CLIENT_USER,
  DELETE_CLIENT_USER,
  FETCH_CLIENT_PRODUCTS,
  ADD_CLIENT_PRODUCT,
  DELETE_CLIENT_PRODUCT,
  FETCH_CLIENT_LAYERS,
  ADD_CLIENT_LAYER,
  DELETE_CLIENT_LAYER,
  FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS,
  UPDATE_CLIENT_ADDITIONAL_OPTION,
  FETCH_ALL_CLIENT_MODULES,
  UPDATE_CLIENT_MODULE
} from '../../constants/actionNames/clients/clientActions';

import {
  ClientAction,
  FetchAllClientsAction,
  CreateClientsAction,
  SelectClient,
  UpdateClientsAction,
  DeleteClientsAction
} from '../../@types/Actions/Client/Client.d';
import {
  FetchAllClientOfferOrderAction,
  SelectClientClientOfferOrder
} from '../../@types/Actions/Client/ClientOfferOrder.d';
import {
  Client,
  DictState,
  ClientLocation,
  Dict,
  User,
  Product,
  Layer,
  AdditionalOption,
  DistributionAppointment
} from '../../@types/Common.d';
import {
  ClientLocationsAction,
  FetchClientLocationAction,
  CreateClientLocationAction,
  DeleteClientLocationAction,
  ImportClientLocationAction,
  DeleteMultipleClientLocationsAction
} from '../../@types/Actions/Client/ClientLocation.d';
import {
  FetchClientUsersAction,
  ClientUserAction,
  AddClientUserAction,
  DeleteClientUserAction
} from '../../@types/Actions/Client/ClientUser.d';
import {
  FetchClientProductsAction,
  ClientProductAction,
  AddClientProductAction,
  DeleteClientProductAction
} from '../../@types/Actions/Client/ClientProduct.d';
import {
  FetchClientLayersAction,
  AddClientLayerAction,
  DeleteClientLayerAction
} from '../../@types/Actions/Client/ClientLayer.d';
import {
  FetchAllClientAdditionalOptionsAction,
  UpdateClientAdditionalOptionAction
} from '../../@types/Actions/Client/ClientAdditionalOptions.d';
import {
  CREATE_CLIENT_DISTRIBUTION_ACTION,
  DELETE_CLIENT_DISTRIBUTION_ACTION,
  FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS,
  FETCH_CLIENT_DISTRIBUTION_ACTION,
  SELECT_CLIENT_DISTRIBUTION_ACTION,
  UPDATE_CLIENT_DISTRIBUTION_ACTION
} from '../../constants/actionNames/clients/clientDistributionAppointments';
import {
  AddClientDistributionAppointmentAction,
  DeleteClientDistributionAppointmentAction,
  FetchAllClientDistributionAppointmentsAction,
  FetchClientDistributionAppointmentAction,
  SelectClientDistributionAppointmentAction,
  UpdateClientDistributionAppointmentAction
} from '../../@types/Actions/Client/ClientDistributionAppointments.d';
import {
  FetchClientModulesAction,
  UpdateClientModuleAction
} from '../../@types/Actions/Client/ClientModules';

export default function clientsReducer(
  state = initialState.entities.clients,
  action: ClientAction | ClientLocationsAction | ClientUserAction | ClientProductAction
) {
  const { type } = action;

  if (type === FETCH_ALL_CLIENTS) {
    const castedAction = action as FetchAllClientsAction;

    const byId = getDict<Client>(castedAction.clients);
    const allIds = getIndicies<Client>(byId);

    return {
      ...state,
      ...{
        allIds,
        byId,
        selectedItem: byId[allIds[0]]
      }
    };
  }
  if (type === CREATE_CLIENT) {
    const castedAction = action as CreateClientsAction;
    const { allIds } = state;

    const byId = {
      ...state.byId,
      ...{ [castedAction.client.id]: castedAction.client }
    };

    return {
      ...state,
      ...{
        allIds: insertSorted<Client>(byId, [...allIds], castedAction.client),
        byId,
        selectedItem: byId[castedAction.client.id]
      }
    } as DictState<Client>;
  }
  if (type === UPDATE_CLIENT) {
    const castedAction = action as UpdateClientsAction;

    const byId = {
      ...state.byId,
      ...{ [castedAction.client.id]: castedAction.client }
    };
    const allIds = getIndicies<Client>(byId);

    return {
      ...state,
      ...{ allIds, byId }
    } as DictState<Client>;
  }
  if (type === DELETE_CLIENT) {
    const castedAction = action as DeleteClientsAction;
    const { byId } = state;

    const selectedIndex = state.allIds.indexOf(castedAction.client.id);

    if (selectedIndex < 0) return state;

    const allIds = [
      ...state.allIds.slice(0, selectedIndex),
      ...state.allIds.slice(selectedIndex + 1)
    ];

    return {
      ...state,
      ...{ allIds, selectedItem: byId[allIds[selectedIndex]] }
    } as DictState<Client>;
  }
  if (type === SELECT_CLIENT) {
    const castedAction = action as SelectClient;
    const { byId } = state;

    return { ...state, ...{ selectedItem: byId[castedAction.client?.id] } } as DictState<Client>;
  }
  if (type === FETCH_CLIENT_LOCATIONS) {
    const castedAction = action as FetchClientLocationAction;

    const byId = getDict(castedAction.clientLocations);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ clientLocations: { byId, allIds } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === CREATE_CLIENT_LOCATION) {
    const castedAction = action as CreateClientLocationAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { clientLocations } = selectedItem;
    const byId = {
      ...clientLocations.byId,
      ...{ [castedAction.clientLocation.id]: castedAction.clientLocation }
    } as Dict<ClientLocation>;

    if (!clientLocations) return state;

    const allIds = insertSorted(byId, [...clientLocations.allIds], castedAction.clientLocation);

    const clientLocationsNew = {
      ...clientLocations,
      ...{ allIds, byId }
    } as DictState<ClientLocation>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ clientLocations: clientLocationsNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === UPDATE_CLIENT_LOCATION) {
    const castedAction = action as CreateClientLocationAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { clientLocations } = selectedItem;

      const byId = {
        ...clientLocations.byId,
        ...{ [castedAction.clientLocation.id]: castedAction.clientLocation }
      };
      const allIds = getIndicies<ClientLocation>(byId);

      const selectedItemNew = {
        ...selectedItem,
        ...{
          clientLocations: {
            byId,
            allIds
          }
        }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === DELETE_CLIENT_LOCATION) {
    const castedAction = action as DeleteClientLocationAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { clientLocations } = selectedItem;
      const selectedIndex = clientLocations.allIds.indexOf(castedAction.clientLocationId) ?? -1;

      if (selectedIndex < 0 || !clientLocations) return state;

      const allIds = [
        ...clientLocations.allIds.slice(0, selectedIndex),
        ...clientLocations.allIds.slice(selectedIndex + 1)
      ];

      const clientLocstionsNew = {
        ...clientLocations,
        ...{ allIds }
      };

      const selectedItemNew = { ...selectedItem, ...{ clientLocations: clientLocstionsNew } };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === DELETE_MULTIPLE_CLIENT_LOCATIONS) {
    const castedAction = action as DeleteMultipleClientLocationsAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { clientLocations } = selectedItem;

      const allIds = clientLocations.allIds.filter(
        id => !castedAction.clientLocationIds.includes(id)
      );

      const clientLocstionsNew = {
        ...clientLocations,
        ...{ allIds }
      };

      const selectedItemNew = { ...selectedItem, ...{ clientLocations: clientLocstionsNew } };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === IMPORT_CLIENT_LOCATIONS) {
    const castedAction = action as ImportClientLocationAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { clientLocations } = selectedItem;

    const newClientLocations = getDict(castedAction.clientLocations);

    const byId = {
      ...clientLocations.byId,
      ...newClientLocations
    } as Dict<ClientLocation>;

    if (!clientLocations) return state;

    const allIds = insertSortedArray(
      byId,
      [...clientLocations.allIds],
      castedAction.clientLocations
    );

    const clientLocationsNew = {
      ...clientLocations,
      ...{ allIds, byId }
    } as DictState<ClientLocation>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ clientLocations: clientLocationsNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === SELECT_CLIENT_LOCATION) {
    return state;
  }
  if (type === FETCH_CLIENT_USERS) {
    const castedAction = action as FetchClientUsersAction;

    const byId = getDict(castedAction.users);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ users: { byId, allIds } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === ADD_CLIENT_USER) {
    const castedAction = action as AddClientUserAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { users } = selectedItem;
    const byId = {
      ...users.byId,
      ...{ [castedAction.user.id]: castedAction.user }
    } as Dict<User>;

    if (!users) return state;

    const allIds = insertSorted(byId, [...users.allIds], castedAction.user);

    const usersNew = {
      ...users,
      ...{ allIds, byId }
    } as DictState<User>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ users: usersNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === DELETE_CLIENT_USER) {
    const castedAction = action as DeleteClientUserAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { users } = selectedItem;
      const selectedIndex = users.allIds.indexOf(castedAction.user.id) ?? -1;

      if (selectedIndex < 0 || !users) return state;

      const allIds = [
        ...users.allIds.slice(0, selectedIndex),
        ...users.allIds.slice(selectedIndex + 1)
      ];

      const usersNew = {
        ...users,
        ...{ allIds }
      };

      const selectedItemNew = { ...selectedItem, ...{ users: usersNew } };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === FETCH_CLIENT_PRODUCTS) {
    const castedAction = action as FetchClientProductsAction;

    const byId = getDict(castedAction.products);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ products: { byId, allIds } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === ADD_CLIENT_PRODUCT) {
    const castedAction = action as AddClientProductAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { products } = selectedItem;
    const byId = {
      ...products.byId,
      ...{ [castedAction.product.id]: castedAction.product }
    } as Dict<Product>;

    if (!products) return state;

    const allIds = insertSorted(byId, [...products.allIds], castedAction.product);

    const productsNew = {
      ...products,
      ...{ allIds, byId }
    } as DictState<Product>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ products: productsNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === DELETE_CLIENT_PRODUCT) {
    const castedAction = action as DeleteClientProductAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { products } = selectedItem;
      const selectedIndex = products.allIds.indexOf(castedAction.product.id) ?? -1;

      if (selectedIndex < 0 || !products) return state;

      const allIds = [
        ...products.allIds.slice(0, selectedIndex),
        ...products.allIds.slice(selectedIndex + 1)
      ];

      const productsNew = {
        ...products,
        ...{ allIds }
      };

      const selectedItemNew = { ...selectedItem, ...{ products: productsNew } };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === FETCH_CLIENT_LAYERS) {
    const castedAction = action as FetchClientLayersAction;

    const byId = getDict(castedAction.layers);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ layers: { byId, allIds } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === ADD_CLIENT_LAYER) {
    const castedAction = action as AddClientLayerAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { layers } = selectedItem;
    const byId = {
      ...layers.byId,
      ...{ [castedAction.layer.id]: castedAction.layer }
    } as Dict<Layer>;

    if (!layers) return state;

    const allIds = insertSorted(byId, [...layers.allIds], castedAction.layer);

    const layersNew = {
      ...layers,
      ...{ allIds, byId }
    } as DictState<Layer>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ layers: layersNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === DELETE_CLIENT_LAYER) {
    const castedAction = action as DeleteClientLayerAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { layers } = selectedItem;
      const selectedIndex = layers.allIds.indexOf(castedAction.layer.id) ?? -1;

      if (selectedIndex < 0 || !layers) return state;

      const allIds = [
        ...layers.allIds.slice(0, selectedIndex),
        ...layers.allIds.slice(selectedIndex + 1)
      ];

      const layersNew = {
        ...layers,
        ...{ allIds }
      };

      const selectedItemNew = { ...selectedItem, ...{ layers: layersNew } };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === FETCH_ALL_CLIENT_OFFER_ORDER) {
    const castedAction = action as FetchAllClientOfferOrderAction;

    const byId = getDict(castedAction.offerOrder);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ offerOrder: { byId, allIds } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === SELECT_CLIENT_OFFER_ORDER) {
    const castedAction = action as SelectClientClientOfferOrder;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { offerOrder } = selectedItem;
    const { byId } = offerOrder;

    const nOfferOrder = { ...offerOrder, ...{ selectedItem: byId[castedAction.offerOrder.id] } };

    const nSelectedItem = {
      ...state.selectedItem,
      ...{ offerOrder: nOfferOrder }
    };

    return {
      ...state,
      ...{
        selectedItem: nSelectedItem
      }
    } as DictState<Client>;
  }
  if (type === FETCH_ALL_CLIENT_ADDITIONAL_OPTIONS) {
    const castedAction = action as FetchAllClientAdditionalOptionsAction;

    const byId = getDict(castedAction.additionalOptions);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ additionalOptions: { byId, allIds } }
    };

    return { ...state, ...{ selectedItem } } as DictState<Client>;
  }
  if (type === UPDATE_CLIENT_ADDITIONAL_OPTION) {
    const castedAction = action as UpdateClientAdditionalOptionAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { additionalOptions } = selectedItem;

      const byId = {
        ...additionalOptions.byId,
        ...{ [castedAction.additionalOption.id]: castedAction.additionalOption }
      };
      const allIds = getIndicies<AdditionalOption>(byId);

      const selectedItemNew = {
        ...selectedItem,
        ...{
          additionalOptions: {
            byId,
            allIds
          }
        }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === FETCH_ALL_CLIENT_DISTRIBUTION_ACTIONS) {
    const castedAction = action as FetchAllClientDistributionAppointmentsAction;

    const byId = getDict(castedAction.distributionAppointments);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ distributionAppointments: { byId, allIds, selectedItem: byId[allIds[0]] } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === CREATE_CLIENT_DISTRIBUTION_ACTION) {
    const castedAction = action as AddClientDistributionAppointmentAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { distributionAppointments } = selectedItem;
    const byId = {
      ...distributionAppointments.byId,
      ...{ [castedAction.distributionAppointment.id]: castedAction.distributionAppointment }
    } as Dict<DistributionAppointment>;

    if (!distributionAppointments) return state;

    const allIds = insertSorted(
      byId,
      [...distributionAppointments.allIds],
      castedAction.distributionAppointment
    );

    const distributionAppointmentsNew = {
      ...distributionAppointments,
      ...{ allIds, byId, selectedItem: byId[castedAction.distributionAppointment.id] }
    } as DictState<DistributionAppointment>;

    const selectedItemNew = {
      ...selectedItem,
      ...{ distributionAppointments: distributionAppointmentsNew }
    };

    return {
      ...state,
      ...{
        selectedItem: selectedItemNew
      }
    } as DictState<Client>;
  }
  if (type === FETCH_CLIENT_DISTRIBUTION_ACTION) {
    const castedAction = action as FetchClientDistributionAppointmentAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { distributionAppointments } = selectedItem;

      const byId = {
        ...distributionAppointments.byId,
        ...{ [castedAction.distributionAppointment.id]: castedAction.distributionAppointment }
      };
      const allIds = getIndicies<DistributionAppointment>(byId);
      const nDistributionAppointments = {
        ...distributionAppointments,
        ...{ allIds, byId }
      };

      const selectedItemNew = {
        ...selectedItem,
        ...{
          distributionAppointments: nDistributionAppointments
        }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === UPDATE_CLIENT_DISTRIBUTION_ACTION) {
    const castedAction = action as UpdateClientDistributionAppointmentAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { distributionAppointments } = selectedItem;

      const byId = {
        ...distributionAppointments.byId,
        ...{ [castedAction.distributionAppointment.id]: castedAction.distributionAppointment }
      };
      const allIds = getIndicies<DistributionAppointment>(byId);
      const nDistributionAppointments = {
        ...distributionAppointments,
        ...{ allIds, byId }
      };

      const selectedItemNew = {
        ...selectedItem,
        ...{
          distributionAppointments: nDistributionAppointments
        }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === DELETE_CLIENT_DISTRIBUTION_ACTION) {
    const castedAction = action as DeleteClientDistributionAppointmentAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { distributionAppointments } = selectedItem;
      const selectedIndex =
        distributionAppointments.allIds.indexOf(castedAction.distributionAppointmentId) ?? -1;

      if (selectedIndex < 0 || !distributionAppointments) return state;

      const allIds = [
        ...distributionAppointments.allIds.slice(0, selectedIndex),
        ...distributionAppointments.allIds.slice(selectedIndex + 1)
      ];

      const distributionAppointmentsNew = {
        ...distributionAppointments,
        ...{ allIds }
      };

      const selectedItemNew = {
        ...selectedItem,
        ...{ distributionAppointments: distributionAppointmentsNew }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }
  if (type === SELECT_CLIENT_DISTRIBUTION_ACTION) {
    const castedAction = action as SelectClientDistributionAppointmentAction;
    const { selectedItem } = state;

    if (!selectedItem) return state;

    const { distributionAppointments } = selectedItem;
    const { byId } = distributionAppointments;

    const nDistributionAppointments = {
      ...distributionAppointments,
      ...{ selectedItem: byId[castedAction.distributionAppointment?.id] }
    };

    const nSelectedItem = {
      ...state.selectedItem,
      ...{ distributionAppointments: nDistributionAppointments }
    };

    return {
      ...state,
      ...{
        selectedItem: nSelectedItem
      }
    } as DictState<Client>;
  }
  if (type === FETCH_ALL_CLIENT_MODULES) {
    const castedAction = action as FetchClientModulesAction;

    const byId = getDict(castedAction.modules);
    const allIds = getIndicies(byId);

    const selectedItem = {
      ...state.selectedItem,
      ...{ modules: { byId, allIds, selectedItem: byId[allIds[0]] } }
    };

    return {
      ...state,
      ...{
        selectedItem
      }
    } as DictState<Client>;
  }
  if (type === UPDATE_CLIENT_MODULE) {
    const castedAction = action as UpdateClientModuleAction;
    const { selectedItem } = state;

    if (selectedItem) {
      const { modules } = selectedItem;

      const byId = {
        ...modules.byId,
        ...{ [castedAction.module.id]: castedAction.module }
      };
      const allIds = getIndicies<DistributionAppointment>(byId);
      const nModules = {
        ...modules,
        ...{ allIds, byId }
      };

      const selectedItemNew = {
        ...selectedItem,
        ...{
          modules: nModules
        }
      };

      return {
        ...state,
        ...{ selectedItem: selectedItemNew }
      } as DictState<Client>;
    }

    return state;
  }

  return state;
}
