import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { getAreaCirculation } from '../../../../../util/utils';

import { ClientDetailOfferOrderAreaListItemProps } from '../../../../../@types/Clients.d';

const ClientDetailsOfferOrderAreaListItem: React.FC<ClientDetailOfferOrderAreaListItemProps> = (
  props: ClientDetailOfferOrderAreaListItemProps
) => {
  const { area, showLocalitiesModal } = props;

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();

    showLocalitiesModal(true, area);
  };

  return (
    <ListGroup.Item className="distribution-area-list-item">
      <Row className="no-gutters">
        <Col sm={2} className="area-title text-eliptize">
          {area.areaKey}
        </Col>
        <Col sm={5} className="area-title text-eliptize">
          {area.name}
        </Col>
        <Col sm={3} className="area-circulation">{`${getAreaCirculation(area)} Stk.`}</Col>
        <Col sm={2} className="area-icons">
          <FontAwesomeIcon className="hover-icon" icon={faInfoCircle} onClick={onClick} />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ClientDetailsOfferOrderAreaListItem;
