import { Dispatch } from 'redux';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';
import {
  fetchAllProductsAction,
  createProductAction,
  updateProductAction,
  deleteProductAction
} from '../../actions/productActions';

import { parseProducts, parseProduct } from '../responseUtil/productResponseUtil';

import { axiosClient, headers } from '../../constants/constants';
import {
  PATH_ALL_PRODUCTS,
  PATH_GET_PRODUCT,
  PATH_CREATE_PRODUCT,
  PATH_UPDATE_PRODUCT,
  PATH_DELETE_PRODUCT
} from '../../constants/network';
import {
  fetchProductsErrorTitle,
  fetchProductsErrorContent,
  createProductErrorTitle,
  createProductErrorContent,
  updateProductErrorTitle,
  updateProductErrorContent,
  deleteProductErrorTitle,
  deleteProductErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_ALL_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from '../../constants/actionNames/products';

import { Product } from '../../@types/Common.d';

export const getAllProducts = () => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_ALL_PRODUCTS));

  const res = await axiosClient
    .get(PATH_ALL_PRODUCTS, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_ALL_PRODUCTS));
    return dispatch(fetchAllProductsAction(parseProducts(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_ALL_PRODUCTS, {
      title: fetchProductsErrorTitle,
      content: fetchProductsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getProduct = (productId: number) =>
  axiosClient
    .get(PATH_GET_PRODUCT(productId), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .then(
      response => response,
      error => error
    )
    .catch(error => error);

export const createProduct = (product: Product) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(CREATE_PRODUCT));

  const res = await axiosClient
    .post(PATH_CREATE_PRODUCT, product, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_PRODUCT));
    return dispatch(createProductAction(parseProduct(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_PRODUCT, {
      title: createProductErrorTitle,
      content: createProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateProduct = (product: Product) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(UPDATE_PRODUCT));

  const res = await axiosClient
    .put(PATH_UPDATE_PRODUCT, product, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_PRODUCT));
    return dispatch(updateProductAction(parseProduct(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_PRODUCT, {
      title: updateProductErrorTitle,
      content: updateProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteProduct = (product: Product) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(DELETE_PRODUCT));

  const res = await axiosClient
    .delete(PATH_DELETE_PRODUCT(product.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_PRODUCT));
    return dispatch(deleteProductAction(product));
  }

  return dispatch(
    requestFailure(DELETE_PRODUCT, {
      title: deleteProductErrorTitle,
      content: deleteProductErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};
