import { Dispatch } from 'redux';

import {
  fetchClientLocationsAction,
  createClientLocationAction,
  updateClientLocationAction,
  deleteClientLocationAction,
  importClientLocations,
  deleteMultipleClientLocationAction
} from '../../actions/client/clientLocationActions';

import {
  parseClientLocations,
  parseClientLocation
} from '../responseUtil/clientLocationResponseUtil';

import { requestStarted, requestSuccess, requestFailure } from '../../actions/uiActions';

import { axiosClient, headers, COUNTRY_CODE_DE } from '../../constants/constants';
import {
  PATH_CLIENTLOCATIONS,
  PATH_CREATE_CLIENTLOCATION,
  PATH_UPDATE_CLIENTLOCATION,
  PATH_DELETE_CLIENTLOCATION,
  PATH_GET_CLIENTLOCATION,
  PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE,
  PATH_GET_POIS,
  PATH_DELETE_MULTIPLE_CLIENTLOCATIONS
} from '../../constants/network';
import {
  deleteClientLocationErrorTitle,
  deleteClientLocationErrorContent,
  updateClientLocationErrorTitle,
  updateClientLocationErrorContent,
  createClientLocationErrorTitle,
  createClientLocationErrorContent,
  fetchClientLocationsErrorTitle,
  fetchClientLocationsErrorContent
} from '../../constants/errorMessages';
import {
  FETCH_CLIENT_LOCATIONS,
  CREATE_CLIENT_LOCATION,
  UPDATE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION,
  DELETE_MULTIPLE_CLIENT_LOCATIONS,
  IMPORT_CLIENT_LOCATIONS
} from '../../constants/actionNames/clients/clientLocationActions';

import { Client, ClientLocation } from '../../@types/Common.d';
import { getOpeningHoursSend } from '../utils';

export const getClientLocations = (client: Client) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(FETCH_CLIENT_LOCATIONS));

  const res = await axiosClient
    .get(PATH_CLIENTLOCATIONS(client.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(FETCH_CLIENT_LOCATIONS));
    return dispatch(fetchClientLocationsAction(parseClientLocations(res.data)));
  }

  return dispatch(
    requestFailure(FETCH_CLIENT_LOCATIONS, {
      title: fetchClientLocationsErrorTitle,
      content: fetchClientLocationsErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const createClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(CREATE_CLIENT_LOCATION));

  const { poi, openingHours, ...clientLocationNoPoi } = clientLocation;

  const res = await axiosClient
    .post(
      PATH_CREATE_CLIENTLOCATION(client.id),
      {
        ...clientLocationNoPoi,
        ...{ poiId: poi.id, openingHours: getOpeningHoursSend(openingHours) }
      },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(CREATE_CLIENT_LOCATION));
    return dispatch(createClientLocationAction(parseClientLocation(res.data)));
  }

  return dispatch(
    requestFailure(CREATE_CLIENT_LOCATION, {
      title: createClientLocationErrorTitle,
      content: createClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const updateClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(UPDATE_CLIENT_LOCATION));

  const { poi, openingHours, ...clientLocationNoPoi } = clientLocation;

  const res = await axiosClient
    .put(
      PATH_UPDATE_CLIENTLOCATION(client.id),
      {
        ...clientLocationNoPoi,
        ...{ poiId: poi.id, openingHours: getOpeningHoursSend(openingHours) }
      },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(UPDATE_CLIENT_LOCATION));
    return dispatch(updateClientLocationAction(parseClientLocation(res.data)));
  }

  return dispatch(
    requestFailure(UPDATE_CLIENT_LOCATION, {
      title: updateClientLocationErrorTitle,
      content: updateClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteClientLocation = (client: Client, clientLocation: ClientLocation) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(DELETE_CLIENT_LOCATION));

  const res = await axiosClient
    .delete(PATH_DELETE_CLIENTLOCATION(client.id, clientLocation.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_CLIENT_LOCATION));
    return dispatch(deleteClientLocationAction(clientLocation.id));
  }

  return dispatch(
    requestFailure(DELETE_CLIENT_LOCATION, {
      title: deleteClientLocationErrorTitle,
      content: deleteClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const deleteMultipleClientLocations = (
  client: Client,
  clientLocationIds: number[]
) => async (dispatch: Dispatch) => {
  dispatch(requestStarted(DELETE_MULTIPLE_CLIENT_LOCATIONS));

  const res = await axiosClient
    .delete(PATH_DELETE_MULTIPLE_CLIENTLOCATIONS(client.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      },
      data: clientLocationIds
    })
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(DELETE_MULTIPLE_CLIENT_LOCATIONS));
    return dispatch(deleteMultipleClientLocationAction(clientLocationIds));
  }

  return dispatch(
    requestFailure(DELETE_MULTIPLE_CLIENT_LOCATIONS, {
      title: deleteClientLocationErrorTitle,
      content: deleteClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getClientLocation = (client: Client, clientLocation: ClientLocation) =>
  axiosClient
    .get(PATH_GET_CLIENTLOCATION(client.id, clientLocation.id), {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .then(
      response => parseClientLocation(response.data),
      error => error
    )
    .catch(error => error);

export const getClientLocationCoordinates = (
  postcode: string,
  city: string,
  street: string,
  housenumber: string
) =>
  axiosClient
    .get(process.env.REACT_APP_URL_NOMINATIM ?? '', {
      params: {
        q: `${postcode} ${city} ${street} ${housenumber}`,
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()}}`
      }
    })
    .then(
      response => response,
      error => error
    )
    .catch(error => error);

export const getClientLocationAddress = (lat: string, lon: string) =>
  axiosClient
    .get(process.env.REACT_APP_URL_NOMINATIM ?? '', {
      params: {
        q: `${lat},${lon}`,
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
        language: COUNTRY_CODE_DE.toLowerCase(),
        no_annotations: 1,
        min_confidence: 5,
        countrycode: `${COUNTRY_CODE_DE.toLowerCase()}}`
      }
    })
    .then(
      response => response,
      error => error
    )
    .catch(error => error);

export const importClientLocationFile = (client: Client, clientLocationsFile: string) => async (
  dispatch: Dispatch
) => {
  dispatch(requestStarted(IMPORT_CLIENT_LOCATIONS));

  const res = await axiosClient
    .post(
      PARH_CREATE_CLIENT_LOCATIONS_FROM_FILE(client.id),
      { data: clientLocationsFile },
      {
        headers,
        validateStatus: (status: number) => {
          return status < 300;
        }
      }
    )
    .catch(error => error);

  if (res.status < 300) {
    dispatch(requestSuccess(IMPORT_CLIENT_LOCATIONS));
    return dispatch(importClientLocations(parseClientLocations(res.data)));
  }

  return dispatch(
    requestFailure(IMPORT_CLIENT_LOCATIONS, {
      title: createClientLocationErrorTitle,
      content: createClientLocationErrorContent(res.response?.status ?? res.request?.status ?? 404)
    })
  );
};

export const getPOIs = () =>
  axiosClient
    .get(PATH_GET_POIS, {
      headers,
      validateStatus: (status: number) => {
        return status < 300;
      }
    })
    .then(
      response =>
        (response.data as []).map(poiItem => {
          const { active, inactive, id, name } = poiItem;
          return { active, inactive, id, name };
        }),
      error => error
    )
    .catch(error => error);
