import * as React from 'react';

import { ListGroup, Row, Col, Form } from 'react-bootstrap';

import { LocalitiesListItemProps } from '../../../@types/Modal.d';

const LocalitiesListItem: React.FC<LocalitiesListItemProps> = (props: LocalitiesListItemProps) => {
  const { locality } = props;

  return (
    <ListGroup.Item className="localities-list-item">
      <Row className="no-gutters">
        <Col sm={2} className="locality-selected">
          <Form.Check
            custom
            type="checkbox"
            checked={locality.selected}
            id={`locality-${locality.localityKey}-check`}
            label=""
            onChange={() => {}}
          />
        </Col>
        <Col sm={7} className="locality-name text-eliptize">
          {locality.name}
        </Col>
        <Col sm={3} className="locality-circulation">
          {`${locality.circulation} Stk.`}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default LocalitiesListItem;
