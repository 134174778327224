import { map } from 'lodash';
import moment from 'moment';

import { resolveModuleName } from 'typescript';
import { parseProduct } from './productResponseUtil';

import { TRANSMISSIONTYPE_OFFER } from '../../constants/constants';

import {
  Client,
  Dict,
  DictState,
  ClientLocation,
  User,
  Product,
  Layer,
  DistributionTemplateLocation,
  Offer,
  Order,
  AdditionalOptionSocialMedia,
  AdditionalOptionKaufDa,
  AdditionalOption,
  SocialMediaTargetGroup,
  DistributionAppointment,
  OfferSheetTemplate,
  Module
} from '../../@types/Common.d';

export function extractOfferSheetTemplate(responseOfferSheetTemplate: any) {
  const { id, name } = responseOfferSheetTemplate;

  return { id, name } as OfferSheetTemplate;
}

export function extractOfferSheetTemplates(responseOfferSheetTemplates: any[]) {
  return responseOfferSheetTemplates.map(responseOfferSheetTemplate =>
    extractOfferSheetTemplate(responseOfferSheetTemplate)
  );
}

export const parseClient = (clientData: any) => {
  const {
    id,
    emailContactPerson,
    freyMedia,
    generateOffer,
    name,
    transmissionType,
    billingType,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    weekparts,
    showPrice,
    internalClient,
    planningRestriction,
    distributionDateType,
    offerSheetTemplate
  } = clientData;

  // TODO client locations
  return {
    id,
    emailContactPerson,
    freyMedia,
    generateOffer,
    name,
    transmissionType,
    billingType,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    clientLocations: { allIds: [] as number[], byId: {} as Dict<ClientLocation> } as DictState<
      ClientLocation
    >,
    users: { allIds: [] as number[], byId: {} as Dict<User> } as DictState<User>,
    products: { allIds: [] as number[], byId: {} as Dict<Product> } as DictState<Product>,
    layers: { allIds: [] as number[], byId: {} as Dict<Layer> } as DictState<Layer>,
    offerOrder:
      transmissionType === TRANSMISSIONTYPE_OFFER
        ? ({ allIds: [] as number[], byId: {} as Dict<Offer> } as DictState<Offer>)
        : ({ allIds: [] as number[], byId: {} as Dict<Order> } as DictState<Order>),
    additionalOptions: { allIds: [] as number[], byId: {} as Dict<AdditionalOption> } as DictState<
      AdditionalOption
    >,
    modules: { allIds: [] as number[], byId: {} as Dict<Module> } as DictState<Module>,
    distributionAppointments: {
      allIds: [] as number[],
      byId: {} as Dict<DistributionAppointment>
    } as DictState<DistributionAppointment>,
    sortProperty: name,
    weekparts,
    planningRestriction,
    distributionDateType,
    internalClient,
    showPrice,
    offerSheetTemplate: offerSheetTemplate
      ? extractOfferSheetTemplate(offerSheetTemplate)
      : undefined
  } as Client;
};

export const parseClients = (clientsData: any) =>
  map(clientsData, clientData => parseClient(clientData)).sort((a: Client, b: Client) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export function extractDistributionTemplateLocation(responseDistributionTemplateLocation: any) {
  const { areas, locationId, locationSrcId, id, name } = responseDistributionTemplateLocation;
  return {
    locationId: locationId ?? locationSrcId ?? id,
    name,
    areas: map(areas, area => ({
      areaKey: area.areaKey,
      name: area.name ?? area.areaKey,
      localities: map(area.localities, locality => ({
        id: locality.id,
        name: locality.name,
        localityKey: locality.localityKey,
        selected: locality.selected,
        circulation: locality.circulation ?? 0
      }))
    }))
  } as DistributionTemplateLocation;
}

export function extractDistributionTemplateLocations(responseDistributionTemplateLocations: any) {
  return map(responseDistributionTemplateLocations, responseDistributionTemplateLocation =>
    extractDistributionTemplateLocation(responseDistributionTemplateLocation)
  );
}

export function extractOrderTemplate(responseOrderTemplate: any) {
  const {
    id,
    actionName,
    distributionDay,
    locations,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    product,
    surename
  } = responseOrderTemplate;
  const sDistributionDay = moment(distributionDay).format('DD.MM.YYYY');

  return {
    id,
    actionName: actionName.length > 0 ? actionName : `Bestellung vom ${sDistributionDay}`,
    distributionDay: sDistributionDay,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    prename: forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    lastname: surename,
    locations: extractDistributionTemplateLocations(locations),
    product: parseProduct(product ?? []),
    sortProperty: distributionDay
  } as Order;
}

export function extractOrderTemplates(responseOrderTemplates: any) {
  return map(responseOrderTemplates, responseOrderTemplate =>
    extractOrderTemplate(responseOrderTemplate)
  );
}

export function extractOfferTemplate(responseOrderTemplate: any) {
  const {
    id,
    actionName,
    locations,
    weekpart,
    copiesToSubsidiary,
    city,
    distributionDay,
    company,
    email,
    forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    surename,
    product
  } = responseOrderTemplate;
  const sDistributionDay = moment(distributionDay).format('DD.MM.YYYY');
  return {
    id,
    actionName: actionName.length > 0 ? actionName : `Bestellung vom ${sDistributionDay}`,
    distributionDay: sDistributionDay,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    prename: forname,
    housenumber,
    phone,
    postcode,
    salutation,
    street,
    lastname: surename,
    locations: extractDistributionTemplateLocations(locations),
    product: parseProduct(product ?? []),
    sortProperty: distributionDay
  } as Offer;
}

export function extractOfferTemplates(responseOfferTemplates: any) {
  return map(responseOfferTemplates, responseOfferTemplate =>
    extractOfferTemplate(responseOfferTemplate)
  );
}

export function extractAdditionalOption(responseAdditionalOption: any) {
  const { data, enabled, id, platform, mode, price, type } = responseAdditionalOption;

  let optionsData;

  try {
    optionsData = JSON.parse(data);
  } catch (e) {
    optionsData = {};
  }

  if (type === 'KAUFDA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultHomepage: optionsData.homepage ?? optionsData.defaultHomepage,
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionHomepages: optionsData.presetSelectionHomepages ?? [],
      presetValuesRanges: optionsData.presetValuesRanges ?? [],
      presetValuesHomepages: optionsData.presetValuesHomepages ?? [],
      sortProperty: 'type'
    } as AdditionalOptionKaufDa;
  if (type === 'SOCIALMEDIA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      platform,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultBudget: optionsData.budget ?? optionsData.defaultBudget,
      defaultTargetGroups: (optionsData.defaultTargetGroups?.map(
        (group: any) => ({ id: group.id, name: group.name } as SocialMediaTargetGroup)
      ) ?? []) as SocialMediaTargetGroup[],
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionBudgets: optionsData.presetSelectionBudgets ?? [],
      presetSelectionTargetGroups: optionsData.presetSelectionTargetGroups ?? [],
      presetValuesRanges: optionsData.presetValuesRanges ?? [],
      presetValuesDurations: optionsData.presetValuesDurations ?? [],
      presetValuesBudgets: optionsData.presetValuesBudgets ?? [],
      presetValuesTargetGroups: optionsData.presetValuesTargetGroups ?? [],
      sortProperty: 'platform'
    } as AdditionalOptionSocialMedia;

  return { id, type } as AdditionalOption;
}

export function extractAdditionalOptions(responseAdditionalOptions: any[]) {
  return responseAdditionalOptions.map(responseAdditionalOption =>
    extractAdditionalOption(responseAdditionalOption)
  );
}

export function extractDistributionAppointment(responseDistributionAppointment: any) {
  const { id, date, name, type } = responseDistributionAppointment;
  const cDate = moment(date);

  return {
    id,
    date: cDate,
    name,
    type,
    sortProperty: `${cDate.unix()}`
  } as DistributionAppointment;
}

export function extractDistributionAppointments(responseDistributionAppointments: any[]) {
  return responseDistributionAppointments.map((responseDistributionAppointment: any) =>
    extractDistributionAppointment(responseDistributionAppointment)
  );
}

export function extractClientModule(responseModule: any) {
  const { title, enabled, id } = responseModule;

  return { id, title, enabled, sortProperty: title } as Module;
}

export function extreactClientModules(responseModules: any) {
  return responseModules.map((responseModule: any) => extractClientModule(responseModule));
}
