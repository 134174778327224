import '../../styles/Users.scss';

import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { map } from 'lodash';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import AddListItem from '../Common/AddListItem';
import SearchableList from '../Common/SearchableList/SearchableList';
import UsersDetailsTabs from './UsersDetails/UsersDetailsTabs';
import UsersNew from './UsersNew';

import { selectUser } from '../../actions/userActions';

import { getAllUsers } from '../../util/api/userApi';

import { ROUTE_NEW } from '../../constants/constants';
import {
  USERS_ADD_LIST_ITEM,
  USERS_EMPTY_LIST,
  LIST_SEARCH_HINT_USERS
} from '../../constants/labels';

import { UserAction } from '../../@types/Actions/User.d';
import { UsersProps, UsersState } from '../../@types/Users.d';
import { GlobalState, User, ListItem } from '../../@types/Common.d';

class Users extends React.Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props);

    this.state = {};

    this.clickAddUser = this.clickAddUser.bind(this);
    this.clickUser = this.clickUser.bind(this);
  }

  componentDidMount() {
    const { fetchAllUsers } = this.props;

    fetchAllUsers();
  }

  clickAddUser() {
    const { userSelect } = this.props;
    userSelect(undefined);
  }

  clickUser(userId: number) {
    const { userSelect, users } = this.props;

    userSelect(users.byId[userId]);
  }

  render() {
    const { users, match } = this.props;

    return (
      <Row className="users-container">
        <Col xs={5} lg={3} className="custom-list-container">
          <SearchableList
            items={map(users.allIds, (userId: number) => {
              const { id, prename, lastname, email } = users.byId[userId];
              return { id, title: `${lastname}, ${prename}`, subtitle: email } as ListItem;
            })}
            selectedItem={
              {
                id: users?.selectedItem?.id,
                title: `${users?.selectedItem?.lastname}, ${users?.selectedItem?.prename}`,
                subtitle: users?.selectedItem?.email
              } as ListItem
            }
            itemClick={this.clickUser}
            emptyListString={USERS_EMPTY_LIST}
            searchHint={LIST_SEARCH_HINT_USERS}
          />
          <AddListItem clickAddItem={this.clickAddUser} itemText={USERS_ADD_LIST_ITEM} />
        </Col>
        <Col xs={7} lg={9} className="custom-detail-container">
          <Switch>
            <Route exact path={`${match.path}`}>
              {users.selectedItem ? (
                <UsersDetailsTabs />
              ) : (
                <Redirect to={`${match.path}${ROUTE_NEW}`} />
              )}
            </Route>
            <Route path={`${match.path}${ROUTE_NEW}`}>
              {users.selectedItem ? <Redirect to={`${match.url}`} /> : <UsersNew />}
            </Route>
          </Switch>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  user: state.entities.user,
  users: state.entities.users
});
const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, UserAction>) => ({
  fetchAllUsers: () => dispatch(getAllUsers()),
  userSelect: (user: User) => dispatch(selectUser(user))
});

const UsersContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
export default UsersContainer;
