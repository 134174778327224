import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import ClientDetailsOfferOrderAreaListItem from './ClientDetailsOfferOrderAreaListItem';

import { CLIENT_OFFER_ORDER_AREA_EMPTY_LIST } from '../../../../../constants/labels';

import { ClientDetailOfferOrderAreaListProps } from '../../../../../@types/Clients.d';

const ClientDetailsOfferOrderAreaList: React.FC<ClientDetailOfferOrderAreaListProps> = (
  props: ClientDetailOfferOrderAreaListProps
) => {
  const { areas, showLocalitiesModal } = props;

  return (
    <ListGroup className="distribution-area-list">
      {areas.length > 0 ? (
        areas.map(area => (
          <ClientDetailsOfferOrderAreaListItem
            key={area.areaKey}
            area={area}
            showLocalitiesModal={showLocalitiesModal}
          />
        ))
      ) : (
        <ListGroup.Item className="distribution-area-list-item">
          {CLIENT_OFFER_ORDER_AREA_EMPTY_LIST}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ClientDetailsOfferOrderAreaList;
