import { getDict, getIndicies, insertSorted } from '../../util/utils';

import initialState from '../../constants/initialState';
import {
  FETCH_ALL_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  SELECT_PRODUCT
} from '../../constants/actionNames/products';

import {
  ProductAction,
  FetchAllProductsAction,
  SelectProduct,
  CreateProductAction,
  UpdateProductAction,
  DeleteProductAction
} from '../../@types/Actions/Product.d';
import { Product, DictState } from '../../@types/Common.d';

export default function productsReducer(
  state = initialState.entities.products,
  action: ProductAction
) {
  const { type } = action;

  if (type === FETCH_ALL_PRODUCTS) {
    const castedAction = action as FetchAllProductsAction;

    const byId = getDict<Product>(castedAction.products);
    const allIds = getIndicies<Product>(byId);

    return { ...state, ...{ allIds, byId, selectedItem: byId[allIds[0]] } };
  }
  if (type === CREATE_PRODUCT) {
    const castedAction = action as CreateProductAction;
    const { allIds } = state;

    const byId = {
      ...state.byId,
      ...{ [castedAction.product.id]: castedAction.product }
    };

    return {
      ...state,
      ...{
        allIds: insertSorted<Product>(byId, [...allIds], castedAction.product),
        byId,
        selectedItem: byId[castedAction.product.id]
      }
    } as DictState<Product>;
  }
  if (type === UPDATE_PRODUCT) {
    const castedAction = action as UpdateProductAction;

    const byId = {
      ...state.byId,
      ...{ [castedAction.product.id]: castedAction.product }
    };
    const allIds = getIndicies<Product>(byId);

    return {
      ...state,
      ...{ allIds, byId }
    } as DictState<Product>;
  }
  if (type === DELETE_PRODUCT) {
    const castedAction = action as DeleteProductAction;
    const { byId } = state;

    const selectedIndex = state.allIds.indexOf(castedAction.product.id);

    if (selectedIndex < 0) return state;

    const allIds = [
      ...state.allIds.slice(0, selectedIndex),
      ...state.allIds.slice(selectedIndex + 1)
    ];

    return {
      ...state,
      ...{ allIds, selectedItem: byId[allIds[selectedIndex]] }
    } as DictState<Product>;
  }
  if (type === SELECT_PRODUCT) {
    const castedAction = action as SelectProduct;
    const { byId } = state;

    return { ...state, ...{ selectedItem: byId[castedAction.product?.id] } };
  }

  return state;
}
