import { Offer, Order } from '../../@types/Common.d';
import {
  FetchAllClientOfferOrderAction,
  SelectClientClientOfferOrder
} from '../../@types/Actions/Client/ClientOfferOrder.d';

import {
  FETCH_ALL_CLIENT_OFFER_ORDER,
  SELECT_CLIENT_OFFER_ORDER
} from '../../constants/actionNames/clients/clientOfferOrderActions';

export const fetchAllClientOfferOrderAction = (
  offerOrder: Offer[] | Order[]
): FetchAllClientOfferOrderAction => ({
  type: FETCH_ALL_CLIENT_OFFER_ORDER,
  offerOrder
});

export const selectClientOfferOrderAction = (
  offerOrder: Offer | Order
): SelectClientClientOfferOrder => ({
  type: SELECT_CLIENT_OFFER_ORDER,
  offerOrder
});
