import * as React from 'react';

import { Col, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import KaufDaDurationPriceInputList from './KaufDaDurationPriceInputList';

import {
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_RANGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_RANGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_HOMEPAGE
} from '../../../../../constants/labels';
import { selectPickerTheme } from '../../../../../constants/constants';

import {
  AdditionalOptionsListItemKaufDaPresetProps,
  AdditionalOptionsListItemKaufDaPresetState
} from '../../../../../@types/Clients.d';
import { KaufDaDurationPrice } from '../../../../../@types/Common.d';

export default class AdditionalOptionItemKaufDaPreset extends React.Component<
  AdditionalOptionsListItemKaufDaPresetProps,
  AdditionalOptionsListItemKaufDaPresetState
> {
  rangeRow = React.createRef<HTMLDivElement>();

  homepageRow = React.createRef<HTMLDivElement>();

  constructor(props: AdditionalOptionsListItemKaufDaPresetProps) {
    super(props);

    this.state = { durationPriceHeight: 0 };

    this.onChangeHomepage = this.onChangeHomepage.bind(this);
    this.onChangeRange = this.onChangeRange.bind(this);

    this.onCreateHomepage = this.onCreateHomepage.bind(this);
    this.onCreateRange = this.onCreateRange.bind(this);

    this.getDurationPriceHeight = this.getDurationPriceHeight.bind(this);
    this.changeDurationPrice = this.changeDurationPrice.bind(this);
    this.createDurationPrice = this.createDurationPrice.bind(this);
    this.removeDurationPrice = this.removeDurationPrice.bind(this);
  }

  componentDidMount() {
    this.setState({ durationPriceHeight: this.getDurationPriceHeight() });
  }

  componentDidUpdate(prevProps: AdditionalOptionsListItemKaufDaPresetProps) {
    const { additionalOption } = this.props;

    if (JSON.stringify(prevProps.additionalOption) !== JSON.stringify(additionalOption)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ durationPriceHeight: this.getDurationPriceHeight() });
    }
  }

  onChangeRange(ranges: any) {
    const { additionalOption, changeAdditionalOption } = this.props;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionRanges: (ranges?.map(({ value }: any) => +value) ?? ([] as number[])).sort()
      }
    });
  }

  onCreateRange(range: any) {
    if (Number.isNaN(+range)) return;

    const { additionalOption, changeAdditionalOption } = this.props;
    const { presetValuesRanges, presetSelectionRanges } = additionalOption;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetValuesRanges: ([...presetValuesRanges, +range] as number[]).sort(),
        presetSelectionRanges: ([...presetSelectionRanges, +range] as number[]).sort()
      }
    });
  }

  onChangeHomepage(homepage: any) {
    const { changeAdditionalOption, additionalOption } = this.props;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionHomepages: (
          homepage?.map(({ value }: any) => value as string) ?? ([] as string[])
        ).sort()
      }
    });
  }

  onCreateHomepage(homepage: any) {
    const { changeAdditionalOption, additionalOption } = this.props;
    const { presetValuesHomepages, presetSelectionHomepages } = additionalOption;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetValuesHomepages: ([...presetValuesHomepages, homepage as string] as string[]).sort(),
        presetSelectionHomepages: ([
          ...presetSelectionHomepages,
          homepage as string
        ] as string[]).sort()
      }
    });
  }

  getDurationPriceHeight() {
    let rangeHeight = 0;
    let homepageHeight = 0;

    if (this.rangeRow.current !== null) rangeHeight = this.rangeRow.current.clientHeight;
    if (this.homepageRow.current !== null) homepageHeight = this.homepageRow.current.clientHeight;

    return rangeHeight + homepageHeight;
  }

  changeDurationPrice(duration: KaufDaDurationPrice) {
    const { changeAdditionalOption, additionalOption } = this.props;
    const { presetSelectionDurations } = additionalOption;

    const durationIndex = presetSelectionDurations.findIndex(
      pDuration => pDuration.id === duration.id
    );

    if (durationIndex < 0) return;

    presetSelectionDurations[durationIndex].duration = duration.duration;
    presetSelectionDurations[durationIndex].price = duration.price;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionDurations
      }
    });
  }

  createDurationPrice() {
    const { changeAdditionalOption, additionalOption } = this.props;
    const { presetSelectionDurations } = additionalOption;

    const id = presetSelectionDurations
      .map(duration => duration.id)
      .reduce((a, b) => Math.max(a, b) + 1, 1);

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionDurations: ([
          ...presetSelectionDurations,
          ...[{ duration: 0, price: 0, id } as KaufDaDurationPrice]
        ] as KaufDaDurationPrice[]).sort((a, b) => a.duration - b.duration)
      }
    });
  }

  removeDurationPrice(duration: KaufDaDurationPrice) {
    const { changeAdditionalOption, additionalOption } = this.props;
    const { presetSelectionDurations } = additionalOption;

    const durationIndex = presetSelectionDurations.findIndex(
      pDuration => pDuration.id === duration.id
    );

    if (durationIndex < 0) return;

    changeAdditionalOption({
      ...additionalOption,
      ...{
        presetSelectionDurations: [
          ...presetSelectionDurations.slice(0, durationIndex),
          ...presetSelectionDurations.slice(durationIndex + 1)
        ]
      }
    });
  }

  render() {
    const { additionalOption } = this.props;
    const { durationPriceHeight } = this.state;

    const {
      presetSelectionDurations,
      presetSelectionRanges,
      presetSelectionHomepages,
      presetValuesRanges,
      presetValuesHomepages
    } = additionalOption;

    const ranges = presetValuesRanges.map(range => ({ value: range, label: range }));
    const homepages = presetValuesHomepages.map(hp => ({ value: hp, label: hp }));

    return (
      <>
        <Form.Row>
          <Col xs={12} xl={6}>
            <div ref={this.rangeRow}>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_RANGE}</Form.Label>
                    <CreatableSelect
                      closeMenuOnSelect={false}
                      isMulti
                      isClearable
                      menuPosition="absolute"
                      isSearchable
                      value={ranges.filter(r => presetSelectionRanges.find(sr => sr === r.value))}
                      options={ranges}
                      placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_RANGE}
                      onChange={this.onChangeRange}
                      onCreateOption={this.onCreateRange}
                      theme={(theme: any) => selectPickerTheme(theme)}
                      menuPortalTarget={document.body}
                    />
                    <Form.Control
                      value={presetSelectionRanges.join(',')}
                      hidden
                      onChange={() => {}}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_RANGE}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
            <div ref={this.homepageRow}>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_HOMEPAGE}</Form.Label>
                    <CreatableSelect
                      closeMenuOnSelect={false}
                      isMulti
                      isClearable
                      menuPosition="absolute"
                      isSearchable
                      value={homepages.filter(h =>
                        presetSelectionHomepages.find(hr => hr === h.value)
                      )}
                      options={homepages}
                      placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_HOMEPAGE}
                      onChange={this.onChangeHomepage}
                      onCreateOption={this.onCreateHomepage}
                      theme={(theme: any) => selectPickerTheme(theme)}
                      menuPortalTarget={document.body}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          </Col>
          <Col
            xs={12}
            xl={6}
            style={{
              height: durationPriceHeight
            }}
          >
            <Form.Row className="h-100">
              <Col className="h-100">
                <Form.Group className="duration-price-container">
                  <Form.Label>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_TITLE_DURATION}</Form.Label>
                  <KaufDaDurationPriceInputList
                    durationPrices={presetSelectionDurations}
                    changeDurationPrice={this.changeDurationPrice}
                    createDurationPrice={this.createDurationPrice}
                    removeDurationPrice={this.removeDurationPrice}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
      </>
    );
  }
}
