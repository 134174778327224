import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';

import {
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_DURATION,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_DURATION,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_PRICE,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_PRICE,
  CURRENCY_SHORTER,
  WEEKS,
  CLIENT_ADDITIONAL_OPTION_KAUF_DA_ADD_PRICE_DURATION
} from '../../../../../constants/labels';

import {
  KaufDaDurationPriceInputListItemProps,
  KaufDaDurationPriceInputListProps
} from '../../../../../@types/Clients.d';

const KaufDaDurationPriceInputList: React.FC<KaufDaDurationPriceInputListProps> = (
  props: KaufDaDurationPriceInputListProps
) => {
  const { durationPrices, changeDurationPrice, createDurationPrice, removeDurationPrice } = props;

  const onClickAddItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    createDurationPrice();
  };

  return (
    <>
      <Row className="no-gutters duration-price-list">
        <Col className="p-0">
          {durationPrices.map(durationPrice => (
            <KaufDaDurationPriceInputListItem
              durationPrice={durationPrice}
              changeDurationPrice={changeDurationPrice}
              removeDurationPrice={removeDurationPrice}
            />
          ))}
        </Col>
      </Row>
      <Row className="no-gutters duration-price-list-item add-item" onClick={onClickAddItem}>
        <Col xs={1}>
          <FontAwesomeIcon icon={faPlus} />
        </Col>
        <Col>{CLIENT_ADDITIONAL_OPTION_KAUF_DA_ADD_PRICE_DURATION}</Col>
      </Row>
    </>
  );
};

export default KaufDaDurationPriceInputList;

const KaufDaDurationPriceInputListItem: React.FC<KaufDaDurationPriceInputListItemProps> = (
  props: KaufDaDurationPriceInputListItemProps
) => {
  const { durationPrice, changeDurationPrice, removeDurationPrice } = props;
  const { price, duration } = durationPrice;

  const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    changeDurationPrice({ ...durationPrice, ...{ duration: +event.currentTarget.value } });
  };

  const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    changeDurationPrice({ ...durationPrice, ...{ price: +event.currentTarget.value } });
  };

  const onRemoveDurationPrice = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    removeDurationPrice(durationPrice);
  };

  return (
    <Row className="no-gutters duration-price-list-item">
      <Col className="pr-1">
        <Form.Group>
          <InputGroup className="duration-price-form-item">
            <FormControl
              as="input"
              auto-complete="new-password"
              type="number"
              value={duration}
              onChange={onChangeDuration}
              placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_DURATION}
              required
            />
            <div className="unit-label">{WEEKS}</div>
            <Form.Control.Feedback type="invalid">
              {CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_DURATION}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col className="pl-1">
        <Form.Group>
          <InputGroup className="duration-price-form-item">
            <FormControl
              as="input"
              auto-complete="new-password"
              type="number"
              value={price}
              onChange={onChangePrice}
              placeholder={CLIENT_ADDITIONAL_OPTION_KAUF_DA_PLACEHOLDER_PRICE}
              required
            />
            <div className="unit-label">{CURRENCY_SHORTER}</div>
            <Form.Control.Feedback type="invalid">
              {CLIENT_ADDITIONAL_OPTION_KAUF_DA_INVALID_PRICE}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col xs={1} className="duration-price-form-item">
        <div className="remove-container">
          <FontAwesomeIcon
            icon={faTrash}
            className="hover-icon remove"
            onClick={onRemoveDurationPrice}
          />
        </div>
      </Col>
    </Row>
  );
};
