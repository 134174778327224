import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import AppContainer from './components/App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers/rootReducer';
import initialState from './constants/initialState';

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <AppContainer />
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
