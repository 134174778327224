import * as React from 'react';

import 'react-dates/initialize';
import 'moment/locale/de';

import { Row, Col, Form, FormControl } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import { find, map } from 'lodash';
import { Moment } from 'moment';
import Select from 'react-select';

import {
  CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_COPIES_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_COPIES_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_CITY_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_CITY_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_CITY_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_COMPANY_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_COMPANY_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_COMPANY_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_EMAIL_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_EMAIL_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_EMAIL_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_PRENAME_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_PRENAME_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_PRENAME_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_PHONE_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_PHONE_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_PHONE_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_STREET_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_STREET_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_STREET_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_LABEL,
  CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_PLACEHOLDER,
  CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_INVALID,
  CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_OFFER_ORDER_DATA,
  CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_USER_DATA,
  CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_ADDRESS_DATA
} from '../../../../../constants/labels';
import {
  selectPickerTheme,
  SALUTATION_ARRAY,
  WEEKPART_MATCHER,
  WEEKPART_ARRAY,
  COPIES_TO_SUBSIDIARY_ARRAY
} from '../../../../../constants/constants';

import { ClientOfferOrderDetailsFormProps } from '../../../../../@types/Clients.d';

const ClientDetailsOfferOrderDetailsForm: React.FC<ClientOfferOrderDetailsFormProps> = (
  props: ClientOfferOrderDetailsFormProps
) => {
  const formDisabled = true;

  const {
    client,
    actionName,
    weekpart,
    copiesToSubsidiary,
    city,
    company,
    email,
    prename,
    housenumber,
    phone,
    postcode,
    product,
    salutation,
    street,
    lastname,
    datePickerFocused,
    distributionDay,
    changeActionName,
    changeWeekpart,
    changeCopiesToSubsidiary,
    onDatePickerFocusChange,
    changeDistributionDay,
    changeProduct,
    changeCity,
    changeCompany,
    changeEmail,
    changePrename,
    changeHousenumber,
    changePhone,
    changePostcode,
    changeSalutation,
    changeStreet,
    changeLastname,
    isDayBlocked
  } = props;

  const weekpartItemArray = map(client?.weekparts ?? WEEKPART_ARRAY, (pWeekpart: string) => ({
    value: pWeekpart,
    label: WEEKPART_MATCHER[pWeekpart]
  }));

  // TODO get client products
  // const productArray = client
  //   ? map(client?.products.allIds, pProductId => client?.products.byId[pProductId])
  //   : DEFAULT_PRODUCTS;
  // const productItemArray = map(
  //   productArray.length === 0 ? DEFAULT_PRODUCTS : client,
  //   (pProduct: Product) => ({
  //     value: pProduct.id,
  //     label: pProduct.name
  //   })
  // );
  const productItemArray = [{ label: product.name, value: product.id }];

  const copiesToSubsidiaryItemArray = COPIES_TO_SUBSIDIARY_ARRAY.map((copies: number) => ({
    label: `${copies} Stk.`,
    value: copies
  }));

  const onChangeActionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeActionName(event.currentTarget.value);
  };

  const onChangeWeekpart = (weekpartSelection: any) => {
    if (!formDisabled) {
      if (weekpartSelection && weekpartSelection !== null) changeWeekpart(weekpartSelection.value);
    }
  };

  const onChangeDistributionDay = (day: Moment | null) => {
    if (!formDisabled) changeDistributionDay(day);
  };

  const onChangeCopiesToSubsidiary = (copiesSelection: any) => {
    if (!formDisabled) {
      if (copiesSelection && copiesSelection !== null)
        changeCopiesToSubsidiary(copiesSelection.value);
    }
  };

  const onChangeProduct = (productSelection: any) => {
    if (!formDisabled) {
      if (productSelection && productSelection !== null)
        changeProduct(find(client?.products, { id: productSelection.value }));
    }
  };

  const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeCity(event.currentTarget.value);
  };

  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeCompany(event.currentTarget.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeEmail(event.currentTarget.value);
  };

  const onChangePrename = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changePrename(event.currentTarget.value);
  };

  const onChangeHousenumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeHousenumber(event.currentTarget.value);
  };

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changePhone(event.currentTarget.value);
  };

  const onChangePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changePostcode(event.currentTarget.value);
  };

  const onChangeSalutation = (salutationSelection: any) => {
    if (!formDisabled) {
      if (salutationSelection && salutationSelection !== null)
        changeSalutation(salutationSelection.value);
    }
  };

  const onChangeStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeStreet(event.currentTarget.value);
  };

  const onChangeLastname = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formDisabled) changeLastname(event.currentTarget.value);
  };

  return (
    <Row className="no-gutters h-100">
      <Col>
        <Form.Row>
          <Col className="section-header">
            {CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_OFFER_ORDER_DATA}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={actionName}
                onChange={onChangeActionName}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_ACTION_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_LABEL}</Form.Label>
              <SingleDatePicker
                date={distributionDay}
                onDateChange={onChangeDistributionDay}
                focused={datePickerFocused}
                onFocusChange={({ focused }) => onDatePickerFocusChange(focused || false)}
                id="distribution-date-picker"
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_PLACEHOLDER}
                showClearDate
                isDayBlocked={(day: any) => isDayBlocked(day)}
                numberOfMonths={1}
                displayFormat="dddd, DD.MM.YYYY"
                block
                disabled={formDisabled}
              />
              <Form.Control
                value={distributionDay !== null ? distributionDay.format('dd.MM.yyyy') : ''}
                onChange={() => {}}
                hidden
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_DISTRIBUTION_DAY_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={weekpartItemArray}
                value={find(weekpartItemArray, { value: weekpart })}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_PLACEHOLDER}
                onChange={onChangeWeekpart}
                theme={(theme: any) => selectPickerTheme(theme)}
                isDisabled={formDisabled}
              />
              <Form.Control value={weekpart} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_WEEKPART_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={productItemArray}
                value={find(
                  productItemArray,
                  { value: product.id } || { value: product.id, label: product.name }
                )}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_PLACEHOLDER}
                onChange={onChangeProduct}
                theme={(theme: any) => selectPickerTheme(theme)}
                isDisabled={formDisabled}
              />
              <Form.Control value={weekpart} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_PRODUCT_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_COPIES_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={copiesToSubsidiaryItemArray}
                value={find(copiesToSubsidiaryItemArray, { value: copiesToSubsidiary })}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_COPIES_PLACEHOLDER}
                onChange={onChangeCopiesToSubsidiary}
                theme={(theme: any) => selectPickerTheme(theme)}
                isDisabled={formDisabled}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className="section-header">
            {CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_USER_DATA}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_COMPANY_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={company}
                onChange={onChangeCompany}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_COMPANY_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_COMPANY_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={SALUTATION_ARRAY}
                value={find(SALUTATION_ARRAY, { value: salutation })}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_PLACEHOLDER}
                onChange={onChangeSalutation}
                theme={(theme: any) => selectPickerTheme(theme)}
                isDisabled={formDisabled}
              />
              <Form.Control value={salutation} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_SALUTATION_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_PRENAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={prename}
                onChange={onChangePrename}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_PRENAME_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_PRENAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={lastname}
                onChange={onChangeLastname}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_LASTNAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_EMAIL_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                value={email}
                onChange={onChangeEmail}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_EMAIL_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_EMAIL_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_PHONE_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="phone"
                value={phone}
                onChange={onChangePhone}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_PHONE_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_PHONE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className="section-header">
            {CLIENTS_OFFER_ORDER_DETAILS_SECTION_HEADER_ADDRESS_DATA}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_STREET_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={street}
                onChange={onChangeStreet}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_STREET_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_STREET_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={housenumber}
                onChange={onChangeHousenumber}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_HOUSENUMBER_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={postcode}
                onChange={onChangePostcode}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_POSTCODE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={4}>
            <Form.Group>
              <Form.Label>{CLIENTS_OFFER_ORDER_DETAILS_CITY_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={city}
                onChange={onChangeCity}
                placeholder={CLIENTS_OFFER_ORDER_DETAILS_CITY_PLACEHOLDER}
                required
                disabled={formDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_OFFER_ORDER_DETAILS_CITY_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default ClientDetailsOfferOrderDetailsForm;
