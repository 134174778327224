import * as React from 'react';

import { Form, Row, Col, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { find } from 'lodash';

import {
  CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_LABEL,
  CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_PLACEHOLDER,
  CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_INVALID,
  CLIENTS_DETAILS_NAME_LABEL,
  CLIENTS_DETAILS_NAME_PLACEHOLDER,
  CLIENTS_DETAILS_NAME_INVALID,
  CLIENTS_DETAILS_TRANSMISSION_TYPE_LABEL,
  CLIENTS_DETAILS_TRANSMISSION_TYPE_PLACEHOLDER,
  CLIENTS_DETAILS_TRANSMISSION_TYPE_INVALID,
  CLIENTS_DETAILS_BILL_CITY_LABEL,
  CLIENTS_DETAILS_BILL_CITY_PLACEHOLDER,
  CLIENTS_DETAILS_BILL_CITY_INVALID,
  CLIENTS_DETAILS_BILL_HOUSENUMBER_LABEL,
  CLIENTS_DETAILS_BILL_HOUSENUMBER_PLACEHOLDER,
  CLIENTS_DETAILS_BILL_HOUSENUMBER_INVALID,
  CLIENTS_DETAILS_BILL_NAME_LABEL,
  CLIENTS_DETAILS_BILL_NAME_PLACEHOLDER,
  CLIENTS_DETAILS_BILL_NAME_INVALID,
  CLIENTS_DETAILS_BILL_POSTCODE_LABEL,
  CLIENTS_DETAILS_BILL_POSTCODE_PLACEHOLDER,
  CLIENTS_DETAILS_BILL_POSTCODE_INVALID,
  CLIENTS_DETAILS_BILL_STREET_LABEL,
  CLIENTS_DETAILS_BILL_STREET_PLACEHOLDER,
  CLIENTS_DETAILS_BILL_STREET_INVALID,
  CLIENTS_DETAILS_GENERATE_OFFER_LABEL,
  CLIENTS_DETAILS_FORM_CLIENT_SECTION,
  CLIENTS_DETAILS_FORM_BILLINIG_SECTION,
  CLIENTS_DETAILS_WEEKPART_INVALID,
  CLIENTS_DETAILS_WEEKPART_PLACEHOLDER,
  CLIENTS_DETAILS_WEEKPART_TITLE,
  CLIENTS_DETAILS_SHOW_PRICE_LABEL,
  CLIENTS_DETAILS_PLANNING_RESTRICTION_LABEL,
  CLIENTS_DETAILS_PLANNING_RESTRICTION_PLACEHOLDER,
  CLIENTS_DETAILS_PLANNING_RESTRICTION_INVALID,
  CLIENTS_DETAILS_INTERNAL_CLIENT_LABEL,
  CLIENTS_DETAILS_INTERNAL_CLIENT_PLACEHOLDER,
  CLIENTS_DETAILS_INTERNAL_CLIENT_INVALID,
  CLIENTS_DETAILS_BILLING_TYPE_LABEL,
  CLIENTS_DETAILS_BILLING_TYPE_PLACEHOLDER,
  CLIENTS_DETAILS_BILLING_TYPE_INVALID,
  CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_LABEL,
  CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_PLACEHOLDER,
  CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_INVALID,
  CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_TITLE,
  CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_PLACEHOLDER,
  CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_INVALID
} from '../../../constants/labels';
import {
  selectPickerTheme,
  TRANSMISSION_TYPE_ARRAY,
  PLANNING_RESTRICTION_ARRAY,
  INTERNAL_CLIENT_ARRAY,
  WEEKPART_SELECTION_ARRAY,
  BILLING_TYPE_ARRAY,
  DISTRIBUTION_DAY_TYPE_ARRAY
} from '../../../constants/constants';

import { ClientDetailsClientFormProps } from '../../../@types/Clients.d';

const ClientDetailsClientForm: React.FC<ClientDetailsClientFormProps> = (
  props: ClientDetailsClientFormProps
) => {
  const {
    emailContactPerson,
    internalClient,
    generateOffer,
    name,
    transmissionType,
    showPrice,
    billingType,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    planningRestriction,
    distributionDateType,
    weekparts,
    offerSheetTemplate,
    offerSheetTemplates,
    changeEmailContactPerson,
    changeInternalClient,
    changeGenerateOffer,
    changeName,
    changeTransmissionType,
    changeBillingType,
    changeBillCity,
    changeBillHousenumber,
    changeBillName,
    changeBillPostcode,
    changeBillStreet,
    changeWeekparts,
    changePlanningRestrictions,
    changeDistributionDayType,
    changeShowPrice,
    changeOfferSheetTemplate
  } = props;

  const onChangeEmailContactPerson = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeEmailContactPerson(event.currentTarget.value ?? '');
  };

  const onChangeInternalClient = (internalClientSelection: any) => {
    if (internalClientSelection && internalClientSelection !== null)
      changeInternalClient(internalClientSelection.value);
  };

  const onChangeShowPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeShowPrice(event.currentTarget.checked);
  };

  const onChangeGenerateOffer = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeGenerateOffer(event.currentTarget.checked);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeName(event.currentTarget.value ?? '');
  };

  const onChangeTransmissionType = (transmissionTypeSelection: any) => {
    if (transmissionTypeSelection && transmissionTypeSelection !== null)
      changeTransmissionType(transmissionTypeSelection.value);
  };

  const onChangePlanningRestriction = (planingTypeSelection: any) => {
    if (planingTypeSelection && planingTypeSelection !== null)
      changePlanningRestrictions(planingTypeSelection.value);
  };

  const onChangeDistributionDayType = (distributionDayType: any) => {
    if (distributionDayType && distributionDayType !== null)
      changeDistributionDayType(distributionDayType.value);
  };

  const onChangeWeekparts = (weekpartSelection: any) => {
    if (weekpartSelection && weekpartSelection !== null)
      changeWeekparts(weekpartSelection.map((weekpart: any) => weekpart.value));
  };

  const onChangeBillingType = (billingTypeSelection: any) => {
    if (billingTypeSelection && billingTypeSelection !== null)
      changeBillingType(billingTypeSelection.value);
  };

  const onChangeOfferSheetTemplate = (offerSheetTemplate: any) => {
    if (offerSheetTemplate && offerSheetTemplate !== null)
      changeOfferSheetTemplate(offerSheetTemplate.data);
  };

  const onChangeBillCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillCity(event.currentTarget.value ?? '');
  };

  const onChangeBillHousenumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillHousenumber(event.currentTarget.value ?? '');
  };

  const onChangeBillName = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillName(event.currentTarget.value ?? '');
  };

  const onChangeBillPostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillPostcode(event.currentTarget.value ?? '');
  };

  const onChangeBillStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBillStreet(event.currentTarget.value ?? '');
  };

  const offerSheetTemplateOptions = offerSheetTemplates.map(offerSheet => ({
    label: offerSheet.name,
    value: offerSheet.id,
    data: offerSheet
  }));

  return (
    <Row className="no-gutters">
      <Col sm={12} xl={4}>
        <Form.Row>
          <Col className="section-header">{CLIENTS_DETAILS_FORM_CLIENT_SECTION}</Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_NAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="text"
                value={name}
                onChange={onChangeName}
                placeholder={CLIENTS_DETAILS_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="new-password"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                value={emailContactPerson}
                onChange={onChangeEmailContactPerson}
                placeholder={CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_FORM_EMAIL_CONTACT_PERSON_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILLING_TYPE_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={BILLING_TYPE_ARRAY}
                value={find(BILLING_TYPE_ARRAY, { value: billingType })}
                placeholder={CLIENTS_DETAILS_BILLING_TYPE_PLACEHOLDER}
                onChange={onChangeBillingType}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={billingType} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILLING_TYPE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_INTERNAL_CLIENT_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={INTERNAL_CLIENT_ARRAY}
                value={find(INTERNAL_CLIENT_ARRAY, { value: internalClient })}
                placeholder={CLIENTS_DETAILS_INTERNAL_CLIENT_PLACEHOLDER}
                onChange={onChangeInternalClient}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={internalClient} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_INTERNAL_CLIENT_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_WEEKPART_TITLE}</Form.Label>
              <Select
                closeMenuOnSelect={false}
                isMulti
                menuPosition="absolute"
                isSearchable
                value={WEEKPART_SELECTION_ARRAY.filter(weekpart =>
                  weekparts?.find(elem => elem === weekpart.value)
                )}
                options={WEEKPART_SELECTION_ARRAY}
                placeholder={CLIENTS_DETAILS_WEEKPART_PLACEHOLDER}
                onChange={onChangeWeekparts}
                theme={(theme: any) => selectPickerTheme(theme)}
                menuPortalTarget={document.body}
                isDisabled={distributionDateType !== 'FREE'}
              />
              <Form.Control value={weekparts.join('')} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_WEEKPART_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={6} className="check-box-container">
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                checked={showPrice}
                id="show-price-check"
                label={CLIENTS_DETAILS_SHOW_PRICE_LABEL}
                onChange={onChangeShowPrice}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={DISTRIBUTION_DAY_TYPE_ARRAY}
                value={find(DISTRIBUTION_DAY_TYPE_ARRAY, { value: distributionDateType })}
                placeholder={CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_PLACEHOLDER}
                onChange={onChangeDistributionDayType}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={distributionDateType} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_DISTRIBUTION_DAY_TYPE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_PLANNING_RESTRICTION_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={PLANNING_RESTRICTION_ARRAY}
                value={find(PLANNING_RESTRICTION_ARRAY, { value: planningRestriction })}
                placeholder={CLIENTS_DETAILS_PLANNING_RESTRICTION_PLACEHOLDER}
                onChange={onChangePlanningRestriction}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={planningRestriction} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_PLANNING_RESTRICTION_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_TRANSMISSION_TYPE_LABEL}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={TRANSMISSION_TYPE_ARRAY}
                value={find(TRANSMISSION_TYPE_ARRAY, { value: transmissionType })}
                placeholder={CLIENTS_DETAILS_TRANSMISSION_TYPE_PLACEHOLDER}
                onChange={onChangeTransmissionType}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control value={transmissionType} hidden onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_TRANSMISSION_TYPE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xl={6} className="check-box-container">
            <Form.Group>
              <Form.Check
                custom
                disabled={transmissionType !== 'OFFER'}
                type="checkbox"
                checked={generateOffer}
                id="generate-offer-check"
                label={CLIENTS_DETAILS_GENERATE_OFFER_LABEL}
                onChange={onChangeGenerateOffer}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        {/* <Form.Row>
          <Col sm={12} xl={6}>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_TITLE}</Form.Label>
              <Select
                isDisabled={transmissionType !== 'OFFER' || !generateOffer}
                isClearable
                blurInputOnSelect
                isSearchable={false}
                options={offerSheetTemplateOptions}
                value={find(offerSheetTemplateOptions, { value: offerSheetTemplate?.id })}
                placeholder={CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_PLACEHOLDER}
                onChange={onChangeOfferSheetTemplate}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
              <Form.Control
                value={offerSheetTemplate?.name}
                hidden
                onChange={() => {}}
                required={transmissionType === 'OFFER' && generateOffer}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_OFFER_SHEET_TEMPLATE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row> */}
        <Form.Row />
      </Col>
      <Col sm={12} xl={4}>
        <Form.Row>
          <Col className="section-header">{CLIENTS_DETAILS_FORM_BILLINIG_SECTION}</Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILL_NAME_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="organization"
                type="text"
                value={billName}
                onChange={onChangeBillName}
                placeholder={CLIENTS_DETAILS_BILL_NAME_PLACEHOLDER}
                disabled={billingType !== 'CLIENT'}
                required={billingType === 'CLIENT'}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILL_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILL_STREET_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="address-line1"
                type="text"
                value={billStreet}
                onChange={onChangeBillStreet}
                placeholder={CLIENTS_DETAILS_BILL_STREET_PLACEHOLDER}
                disabled={billingType !== 'CLIENT'}
                required={billingType === 'CLIENT'}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILL_STREET_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILL_HOUSENUMBER_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="address-line2"
                type="text"
                value={billHousenumber}
                onChange={onChangeBillHousenumber}
                placeholder={CLIENTS_DETAILS_BILL_HOUSENUMBER_PLACEHOLDER}
                disabled={billingType !== 'CLIENT'}
                required={billingType === 'CLIENT'}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILL_HOUSENUMBER_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILL_POSTCODE_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="postal-code"
                type="text"
                value={billPostcode}
                onChange={onChangeBillPostcode}
                placeholder={CLIENTS_DETAILS_BILL_POSTCODE_PLACEHOLDER}
                disabled={billingType !== 'CLIENT'}
                required={billingType === 'CLIENT'}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILL_POSTCODE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{CLIENTS_DETAILS_BILL_CITY_LABEL}</Form.Label>
              <FormControl
                as="input"
                autoComplete="address-level2"
                type="text"
                value={billCity}
                onChange={onChangeBillCity}
                placeholder={CLIENTS_DETAILS_BILL_CITY_PLACEHOLDER}
                disabled={billingType !== 'CLIENT'}
                required={billingType === 'CLIENT'}
              />
              <Form.Control.Feedback type="invalid">
                {CLIENTS_DETAILS_BILL_CITY_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default ClientDetailsClientForm;
