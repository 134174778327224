import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import AddListItem from '../../../Common/AddListItem';
import SearchableList from '../../../Common/SearchableList/SearchableList';
import ClientDetailsDistributionAppointmentsDetailsContainer from './ClientDetailsDistributionAppointmentsDetails';

import { getAllClientDistributionAppointments } from '../../../../util/api/clientDistributionAppointmentsApi';
import { selectClientDistributionAppointmentAction } from '../../../../actions/client/clientDistributionAppointments';

import {
  CALENDAR_WEEK,
  CLIENT_DISTRIBUTION_ACTIONS_EMTPY_LIST,
  LIST_SEARCH_HINT_DISTRIBUTION_ACTIONS,
  CLIENTS_ADD_DISTRIBUTION_ACTION
} from '../../../../constants/labels';

import {
  ClientDetailsDistributionAppointmentsProps,
  ClientDetailsDistributionAppointmentsState
} from '../../../../@types/Clients.d';
import {
  Client,
  DistributionAppointment,
  GlobalState,
  ListItem
} from '../../../../@types/Common.d';
import { ClientDistributionAppointmentActions } from '../../../../@types/Actions/Client/ClientDistributionAppointments.d';

class ClientDetailsDistributionAppointments extends React.Component<
  ClientDetailsDistributionAppointmentsProps,
  ClientDetailsDistributionAppointmentsState
> {
  constructor(props: ClientDetailsDistributionAppointmentsProps) {
    super(props);

    this.state = {};

    this.onClickDistributionAppointmentItem = this.onClickDistributionAppointmentItem.bind(this);
    this.onClickAddDistributionAppointment = this.onClickAddDistributionAppointment.bind(this);
  }

  componentDidMount() {
    const { client, distributionAppointmentsFetchAll } = this.props;

    if (!client) return;

    distributionAppointmentsFetchAll(client);
  }

  componentDidUpdate(prevProps: ClientDetailsDistributionAppointmentsProps) {
    const { distributionAppointmentsFetchAll, client } = this.props;

    if (!client) return;

    if (client.id !== prevProps.client?.id) distributionAppointmentsFetchAll(client);
  }

  onClickDistributionAppointmentItem(distributionAppointmentId: number) {
    const { distributionAppointmentSelect, client } = this.props;

    distributionAppointmentSelect(client?.distributionAppointments.byId[distributionAppointmentId]);
  }

  onClickAddDistributionAppointment() {
    const { distributionAppointmentSelect } = this.props;

    distributionAppointmentSelect(undefined);
  }

  render() {
    const { client } = this.props;

    return (
      <Row className="no-gutters h-100">
        <Col xs={5} lg={3} className="custom-list-container">
          <SearchableList
            items={
              client?.distributionAppointments.allIds.map(distributionAppointmentId => {
                const { id, name, date, type } = client.distributionAppointments.byId[
                  distributionAppointmentId
                ];
                return {
                  id,
                  title: name,
                  subtitle: `${
                    type === 'WEEK'
                      ? `${date.format('WW.')} ${CALENDAR_WEEK} ${date.format('YYYY')}`
                      : date.format('DD.MM.YYYY')
                  }`
                };
              }) ?? []
            }
            selectedItem={
              client
                ? ({
                    id: client.distributionAppointments.selectedItem?.id,
                    title: client.distributionAppointments.selectedItem?.name,
                    subtitle: `${
                      client.distributionAppointments.selectedItem?.type === 'WEEK'
                        ? `${client.distributionAppointments.selectedItem?.date.format(
                            'WW.'
                          )} ${CALENDAR_WEEK} ${client.distributionAppointments.selectedItem?.date.format(
                            'YYYY'
                          )}`
                        : client.distributionAppointments.selectedItem?.date.format('DD.MM.YYYY')
                    }`
                  } as ListItem)
                : undefined
            }
            itemClick={this.onClickDistributionAppointmentItem}
            emptyListString={CLIENT_DISTRIBUTION_ACTIONS_EMTPY_LIST}
            searchHint={LIST_SEARCH_HINT_DISTRIBUTION_ACTIONS}
          />
          <AddListItem
            clickAddItem={this.onClickAddDistributionAppointment}
            itemText={CLIENTS_ADD_DISTRIBUTION_ACTION}
          />
        </Col>
        <Col className="px-3">
          <ClientDetailsDistributionAppointmentsDetailsContainer />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, ClientDistributionAppointmentActions>
) => ({
  distributionAppointmentsFetchAll: (client: Client) =>
    dispatch(getAllClientDistributionAppointments(client)),
  distributionAppointmentSelect: (distributionAppointment: DistributionAppointment) =>
    dispatch(selectClientDistributionAppointmentAction(distributionAppointment))
});

const ClientDetailsDistributionAppointmentsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailsDistributionAppointments)
);
export default ClientDetailsDistributionAppointmentsContainer;
