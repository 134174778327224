import * as React from 'react';

import { Spinner, Row, Col } from 'react-bootstrap';

import { LoadingOverlayProps } from '../@types/App.d';

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props: LoadingOverlayProps) => {
  const { loadingTitle, loadingSubtitle } = props;

  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <Row>
          <Col>
            <div className="loading-label text-center">{loadingTitle}</div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Spinner animation="border" className="ci-spinner" />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="loading-label text-center">{loadingSubtitle}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoadingOverlay;
