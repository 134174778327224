import { combineReducers } from 'redux';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import clientsReducer from './clientsReducer';
import productsReducer from './productsReducer';
import layersReducer from './layersReducer';

const entitiesReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  clients: clientsReducer,
  products: productsReducer,
  layers: layersReducer
});

export default entitiesReducer;
