import * as React from 'react';

import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';
import { difference, map } from 'lodash';

import AddRemoveList from '../../../Common/AddRemoveList/AddRemoveList';

import { requestStarted, requestSuccess, requestFailure } from '../../../../actions/uiActions';
import {
  CLIENT_LAYERS_ASSIGNED_LAYERS,
  CLIENT_LAYERS_NOT_ASSIGNED_LAYERS,
  LIST_SEARCH_HINT_LAYERS
} from '../../../../constants/labels';
import { getAllLayers } from '../../../../util/api/layerApi';
import {
  addClientLayer,
  getClientLayers,
  deleteClientLayer
} from '../../../../util/api/clientLayerApi';

import { ClientsDetailsLayersProps, ClientsDetailsLayersState } from '../../../../@types/Clients.d';
import { GlobalState, ErrorMessage, Layer, ListItem, Client } from '../../../../@types/Common.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';
import { ClientLayerAction } from '../../../../@types/Actions/Client/ClientLayer.d';

class ClientsDetailsLayers extends React.Component<
  ClientsDetailsLayersProps,
  ClientsDetailsLayersState
> {
  constructor(props: ClientsDetailsLayersProps) {
    super(props);

    this.state = {
      hasChanged: false,
      notAssignedLayers: [],
      init: true
    };

    this.filterLayers = this.filterLayers.bind(this);
    this.addClientLayer = this.addClientLayer.bind(this);
    this.deleteClientLayer = this.deleteClientLayer.bind(this);
  }

  componentDidMount() {
    const { layersFetch, clientLayersFetch, client } = this.props;

    if (!client) return;

    layersFetch(client);
    clientLayersFetch(client);
  }

  componentDidUpdate(prevProps: ClientsDetailsLayersProps) {
    const { layersFetch, clientLayersFetch, client, layers } = this.props;
    const { hasChanged, init } = this.state;

    if (!client) return;

    if (client?.id !== prevProps.client?.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ init: true }, () => {
        layersFetch(client);
        clientLayersFetch(client);
      });
    } else if (
      JSON.stringify(client?.layers.allIds) !== JSON.stringify(prevProps.client?.layers.allIds) ||
      JSON.stringify(layers.allIds) !== JSON.stringify(prevProps.layers.allIds) ||
      init
    ) {
      this.filterLayers();
    } else if (
      JSON.stringify(client?.layers.allIds) === JSON.stringify(prevProps.client?.layers.allIds) &&
      JSON.stringify(layers.allIds) === JSON.stringify(prevProps.layers.allIds) &&
      client?.id === prevProps.client?.id &&
      hasChanged
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasChanged: false, init: false });
    }
  }

  filterLayers() {
    const { client, layers } = this.props;

    if (!client?.layers) return;

    const notAssignedLayersIds = difference(layers.allIds, client.layers.allIds);
    const notAssignedLayers = map(notAssignedLayersIds, (id: number) => layers.byId[id]) as Layer[];

    this.setState({ notAssignedLayers, hasChanged: true, init: false });
  }

  addClientLayer(layerId: number) {
    const { client, layers, clientLayerAdd } = this.props;

    clientLayerAdd(client, layers.byId[layerId]);
  }

  deleteClientLayer(layerId: number) {
    const { client, layers, clientLayerDelete } = this.props;

    clientLayerDelete(client, layers.byId[layerId]);
  }

  render() {
    const { client } = this.props;
    const { notAssignedLayers, hasChanged } = this.state;

    return (
      <Row className="client-relations-container">
        {client ? (
          <>
            <Col sm={6} className="client-relation-list-col">
              <Row className="no-gutters">
                <Col className="section-header">{CLIENT_LAYERS_NOT_ASSIGNED_LAYERS}</Col>
              </Row>
              <Row className="client-relation-list-row">
                <Col className="p-0 h-100 d-flex flex-column">
                  <AddRemoveList
                    items={map(notAssignedLayers, layer => {
                      const { id, title, description } = layer;
                      return { id, title, subtitle: description } as ListItem;
                    })}
                    notAssigned
                    hasChanged={hasChanged}
                    searchHint={LIST_SEARCH_HINT_LAYERS}
                    itemAdd={this.addClientLayer}
                    itemDelete={this.deleteClientLayer}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6} className="client-relation-list-col">
              <Row className="no-gutters">
                <Col className="section-header">{CLIENT_LAYERS_ASSIGNED_LAYERS}</Col>
              </Row>
              <Row className="client-relation-list-row">
                <Col className="p-0 h-100 d-flex flex-column">
                  <AddRemoveList
                    items={map(client?.layers.allIds, (layerId: number) => {
                      const { id, title, description } = client?.layers.byId[layerId];
                      return { id, title, subtitle: description } as ListItem;
                    })}
                    hasChanged={hasChanged}
                    searchHint={LIST_SEARCH_HINT_LAYERS}
                    itemAdd={this.addClientLayer}
                    itemDelete={this.deleteClientLayer}
                  />
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col />
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  client: state.entities.clients.selectedItem,
  layers: state.entities.layers
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, RequestAction | ClientLayerAction>
) => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage)),
  layersFetch: () => dispatch(getAllLayers()),
  clientLayersFetch: (client: Client) => dispatch(getClientLayers(client)),
  clientLayerAdd: (client: Client, layer: Layer) => dispatch(addClientLayer(client, layer)),
  clientLayerDelete: (client: Client, layer: Layer) => dispatch(deleteClientLayer(client, layer))
});

const ClientsDetailsLayersContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsLayers)
);
export default ClientsDetailsLayersContainer;
