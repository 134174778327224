import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import LocalitiesListItem from './LocalitiesListItem';

import { LOCALITIES_EMPTY_LIST } from '../../../constants/labels';

import { LocalitiesListProps } from '../../../@types/Modal.d';

const LocalitiesList: React.FC<LocalitiesListProps> = (props: LocalitiesListProps) => {
  const { localities } = props;

  return (
    <ListGroup className="localities-list">
      {localities.length > 0 ? (
        localities.map(locality => <LocalitiesListItem locality={locality} />)
      ) : (
        <ListGroup.Item className="localities-list-item">{LOCALITIES_EMPTY_LIST}</ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default LocalitiesList;
