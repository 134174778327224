import initialState from '../../constants/initialState';
import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from '../../constants/actionNames/user';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
  SHOW_LOADING,
  HIDE_LOADING
} from '../../constants/actionNames/ui';

import { Action } from '../../@types/Common.d';
import { RequestStarted, RequestFailure, RequestSuccess } from '../../@types/Actions/UI.d';

export default function uiBasicReducer(state = initialState.ui.basic, action: Action) {
  const { type } = action;

  if (type === LOGIN_STARTED) {
    return { ...state, ...{ loading: true, loggedIn: false } };
  }
  if (type === LOGIN_SUCCESS) {
    return { ...state, ...{ loading: false, loggedIn: true } };
  }
  if (type === LOGIN_FAILURE) {
    return { ...state, ...{ loading: false, loggedIn: false } };
  }
  if (type === LOGOUT_STARTED) {
    return { ...state, ...{ loading: true, loggedIn: false } };
  }
  if (type === LOGOUT_SUCCESS) {
    return { ...state, ...{ loading: false, loggedIn: false } };
  }
  if (type === LOGOUT_FAILURE) {
    return { ...state, ...{ loading: false, loggedIn: false } };
  }
  if (type === REQUEST_STARTED) {
    const castedAction = action as RequestStarted;

    const runningRequests = [castedAction.payload, ...state.runningRequests];

    return {
      ...state,
      ...{ loading: runningRequests.length > 0, runningRequests }
    };
  }
  if (type === REQUEST_SUCCESS) {
    const castedAction = action as RequestSuccess;

    const selectedIndex = state.runningRequests.indexOf(castedAction.payload);

    let runningRequests;
    if (selectedIndex < 0) runningRequests = state.runningRequests;
    else
      runningRequests = [
        ...state.runningRequests.slice(0, selectedIndex),
        ...state.runningRequests.slice(selectedIndex + 1)
      ];

    return { ...state, ...{ loading: runningRequests.length > 0, runningRequests } };
  }
  if (type === REQUEST_FAILURE) {
    const castedAction = action as RequestFailure;

    const selectedIndex = state.runningRequests.indexOf(castedAction.payload);

    let runningRequests;
    if (selectedIndex < 0) runningRequests = state.runningRequests;
    else
      runningRequests = [
        ...state.runningRequests.slice(0, selectedIndex),
        ...state.runningRequests.slice(selectedIndex + 1)
      ];

    return { ...state, ...{ loading: runningRequests.length > 0, runningRequests } };
  }
  if (type === SHOW_LOADING) {
    return { ...state, ...{ loading: true } };
  }
  if (type === HIDE_LOADING) {
    return { ...state, ...{ loading: false } };
  }

  return state;
}
