import '../../styles/Clients.scss';

import * as React from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { map } from 'lodash';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { selectClientAction } from '../../actions/client/clientActions';

import ClientsDetailsTabs from './ClientsDetails/ClientsDetailsTabs';
import ClientsNew from './ClientsNew';
import AddListItem from '../Common/AddListItem';
import SearchableList from '../Common/SearchableList/SearchableList';

import { getAllClients } from '../../util/api/clientsApi';

import { ROUTE_NEW } from '../../constants/constants';
import {
  CLIENTS_ADD_LIST_ITEM,
  LIST_SEARCH_HINT_CLIENTS,
  CLIENTS_EMPTY_LIST
} from '../../constants/labels';

import { ClientAction } from '../../@types/Actions/Client/Client.d';
import { ClientsProps, ClientsState } from '../../@types/Clients.d';
import { GlobalState, Client, ListItem } from '../../@types/Common.d';

class Clients extends React.Component<ClientsProps, ClientsState> {
  constructor(props: ClientsProps) {
    super(props);

    this.state = {};

    this.clickAddClient = this.clickAddClient.bind(this);
    this.clickClient = this.clickClient.bind(this);
  }

  componentDidMount() {
    const { fetchAllClients, user } = this.props;

    fetchAllClients(user?.email);
  }

  clickAddClient() {
    const { clientSelect } = this.props;
    clientSelect(undefined);
  }

  clickClient(cliendId: number) {
    const { clientSelect, clients } = this.props;

    clientSelect(clients.byId[cliendId]);
  }

  render() {
    const { clients, match } = this.props;

    return (
      <Row className="clients-container">
        <Col xs={5} lg={3} className="custom-list-container">
          <SearchableList
            items={map(clients.allIds, clientId => {
              const { id, name, emailContactPerson } = clients.byId[clientId];
              return { id, title: name, subtitle: emailContactPerson } as ListItem;
            })}
            selectedItem={
              {
                id: clients?.selectedItem?.id,
                title: clients?.selectedItem?.name,
                subtitle: clients?.selectedItem?.emailContactPerson
              } as ListItem
            }
            itemClick={this.clickClient}
            emptyListString={CLIENTS_EMPTY_LIST}
            searchHint={LIST_SEARCH_HINT_CLIENTS}
          />
          <AddListItem clickAddItem={this.clickAddClient} itemText={CLIENTS_ADD_LIST_ITEM} />
        </Col>
        <Col xs={7} lg={9} className="custom-detail-container">
          <Switch>
            <Route exact path={`${match.path}`}>
              {clients.selectedItem ? (
                <ClientsDetailsTabs client={clients.selectedItem} />
              ) : (
                <Redirect to={`${match.path}${ROUTE_NEW}`} />
              )}
            </Route>
            <Route path={`${match.path}${ROUTE_NEW}`}>
              {clients.selectedItem ? <Redirect to={`${match.url}`} /> : <ClientsNew />}
            </Route>
          </Switch>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  user: state.entities.user,
  clients: state.entities.clients
});
const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, ClientAction>) => ({
  fetchAllClients: (user: string) => dispatch(getAllClients(user)),
  clientSelect: (client: Client) => dispatch(selectClientAction(client))
});

const ClientsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
export default ClientsContainer;
