import { combineReducers } from 'redux';

import uiReducer from './ui/uiReducer';

import entitiesReducer from './entities/entitiesReducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  entities: entitiesReducer
});

export default rootReducer;
