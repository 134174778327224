import * as React from 'react';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { requestStarted, requestSuccess, requestFailure } from '../../../../actions/uiActions';

import {
  ClientsDetailsDistributionTemplatesProps,
  ClientsDetailsDistributionTemplatesState
} from '../../../../@types/Clients.d';
import { GlobalState, ErrorMessage } from '../../../../@types/Common.d';
import { RequestAction } from '../../../../@types/Actions/UI.d';

class ClientsDetailsDistributionTemplates extends React.Component<
  ClientsDetailsDistributionTemplatesProps,
  ClientsDetailsDistributionTemplatesState
> {
  constructor(props: ClientsDetailsDistributionTemplatesProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Verteilvorlagen</h1>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, void, RequestAction>) => ({
  reqStarted: (payload: string) => dispatch(requestStarted(payload)),
  reqSuccess: (payload: string) => dispatch(requestSuccess(payload)),
  reqFailure: (payload: string, errorMessage: ErrorMessage) =>
    dispatch(requestFailure(payload, errorMessage))
});

const ClientsDetailsDistributionTemplatesContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientsDetailsDistributionTemplates)
);
export default ClientsDetailsDistributionTemplatesContainer;
